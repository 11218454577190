/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1528px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  -ms-flex-order: 1;
      order: 1;
}

.order-2 {
  -ms-flex-order: 2;
      order: 2;
}

.order-3 {
  -ms-flex-order: 3;
      order: 3;
}

.order-4 {
  -ms-flex-order: 4;
      order: 4;
}

.order-5 {
  -ms-flex-order: 5;
      order: 5;
}

.order-6 {
  -ms-flex-order: 6;
      order: 6;
}

.order-7 {
  -ms-flex-order: 7;
      order: 7;
}

.order-8 {
  -ms-flex-order: 8;
      order: 8;
}

.order-9 {
  -ms-flex-order: 9;
      order: 9;
}

.order-10 {
  -ms-flex-order: 10;
      order: 10;
}

.order-11 {
  -ms-flex-order: 11;
      order: 11;
}

.order-12 {
  -ms-flex-order: 12;
      order: 12;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-xxl-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-xxl-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-xxl-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-xxl-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-xxl-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-xxl-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-xxl-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-xxl-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-xxl-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-xxl-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-xxl-12 {
    -ms-flex-order: 12;
        order: 12;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e9ecef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e9ecef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.thead-inverse th {
  color: #fff;
  background-color: #212529;
}

.thead-default th {
  color: #495057;
  background-color: #e9ecef;
}

.table-inverse {
  color: #fff;
  background-color: #212529;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #32383e;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-inverse.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-inverse.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive.table-bordered {
    border: 0;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
}

.form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}

.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  display: inline-block;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.form-check-inline + .form-check-inline {
  margin-left: 0.75rem;
}

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .invalid-feedback,
.was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
.form-control.is-valid ~ .invalid-tooltip, .was-validated
.custom-select:valid ~ .invalid-feedback,
.was-validated
.custom-select:valid ~ .invalid-tooltip,
.custom-select.is-valid ~ .invalid-feedback,
.custom-select.is-valid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
}

.btn:focus, .btn:hover {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:active, .btn.active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #0069d9;
  background-image: none;
  border-color: #0062cc;
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #727b84;
  background-image: none;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #218838;
  background-image: none;
  border-color: #1e7e34;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  background-color: #138496;
  background-image: none;
  border-color: #117a8b;
}

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #111;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  background-color: #e0a800;
  background-image: none;
  border-color: #d39e00;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background-color: #c82333;
  background-image: none;
  border-color: #bd2130;
}

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #111;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  background-color: #e2e6ea;
  background-image: none;
  border-color: #dae0e5;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  background-color: #23272b;
  background-image: none;
  border-color: #1d2124;
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:active, .btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:active, .btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:active, .btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:active, .btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:active, .btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:active, .btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:active, .btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:active, .btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
  background-color: transparent;
}

.btn-link, .btn-link:focus, .btn-link:active {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus, .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link:disabled {
  color: #868e96;
}

.btn-link:disabled:focus, .btn-link:disabled:hover {
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.5s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.show > a {
  outline: 0;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  margin-bottom: 0;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn + .dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
  z-index: 3;
}

.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e9ecef;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-control:lang(en)::before {
  content: "Browse";
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #ddd;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.show > .nav-pills .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1599px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
      flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: white;
}

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: white;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .card-group .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb::after {
  display: block;
  clear: both;
  content: "";
}

.breadcrumb-item {
  float: left;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #868e96;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus, .page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #868e96;
}

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #6c757d;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #111;
  background-color: #ffc107;
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #111;
  background-color: #f8f9fa;
}

.badge-light[href]:focus, .badge-light[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2;
}

.alert-secondary hr {
  border-top-color: #cfd2d6;
}

.alert-secondary .alert-link {
  color: #2e3133;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
      flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085;
}

a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #004085;
  background-color: #9fcdff;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e;
}

a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #464a4e;
  background-color: #cfd2d6;
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #155724;
}

a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #155724;
  background-color: #b1dfbb;
}

a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460;
}

a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #0c5460;
  background-color: #abdde5;
}

a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404;
}

a.list-group-item-warning:focus, a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #856404;
  background-color: #ffe8a1;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24;
}

a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #818182;
}

a.list-group-item-light:focus, a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #818182;
  background-color: #ececf6;
}

a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21;
}

a.list-group-item-dark:focus, a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
          transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
}

.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
}

.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
}

.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip .arrow::before {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 5px;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}

.popover .arrow::before {
  content: "";
  border-width: 11px;
}

.popover .arrow::after {
  content: "";
  border-width: 11px;
}

.popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 10px;
}

.popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-bottom-width: 0;
}

.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: -11px;
  margin-left: -6px;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: -10px;
  margin-left: -6px;
  border-top-color: #fff;
}

.popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
  margin-left: 10px;
}

.popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
  left: 0;
}

.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  margin-top: -8px;
  border-left-width: 0;
}

.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: -11px;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: -10px;
  border-right-color: #fff;
}

.popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 10px;
}

.popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  margin-left: -7px;
  border-top-width: 0;
}

.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: -11px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: -10px;
  border-bottom-color: #fff;
}

.popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
  margin-right: 10px;
}

.popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
  right: 0;
}

.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  margin-top: -8px;
  border-right-width: 0;
}

.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: -11px;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: -10px;
  border-left-color: #fff;
}

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 9px 14px;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xxl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-xxl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-xxl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xxl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-xxl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0 {
    margin-left: 0 !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3 {
    margin-left: 1rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5 {
    margin-left: 3rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0 {
    padding-left: 0 !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3 {
    padding-left: 1rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5 {
    padding-left: 3rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto {
    margin-left: auto !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.w-0 {
  width: 0% !important;
}

.w-1 {
  width: 1% !important;
}

.w-2 {
  width: 2% !important;
}

.w-3 {
  width: 3% !important;
}

.w-4 {
  width: 4% !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-16 {
  width: 16% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-21 {
  width: 21% !important;
}

.w-22 {
  width: 22% !important;
}

.w-23 {
  width: 23% !important;
}

.w-24 {
  width: 24% !important;
}

.w-25 {
  width: 25% !important;
}

.w-26 {
  width: 26% !important;
}

.w-27 {
  width: 27% !important;
}

.w-28 {
  width: 28% !important;
}

.w-29 {
  width: 29% !important;
}

.w-30 {
  width: 30% !important;
}

.w-31 {
  width: 31% !important;
}

.w-32 {
  width: 32% !important;
}

.w-33 {
  width: 33% !important;
}

.w-34 {
  width: 34% !important;
}

.w-35 {
  width: 35% !important;
}

.w-36 {
  width: 36% !important;
}

.w-37 {
  width: 37% !important;
}

.w-38 {
  width: 38% !important;
}

.w-39 {
  width: 39% !important;
}

.w-40 {
  width: 40% !important;
}

.w-41 {
  width: 41% !important;
}

.w-42 {
  width: 42% !important;
}

.w-43 {
  width: 43% !important;
}

.w-44 {
  width: 44% !important;
}

.w-45 {
  width: 45% !important;
}

.w-46 {
  width: 46% !important;
}

.w-47 {
  width: 47% !important;
}

.w-48 {
  width: 48% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-51 {
  width: 51% !important;
}

.w-52 {
  width: 52% !important;
}

.w-53 {
  width: 53% !important;
}

.w-54 {
  width: 54% !important;
}

.w-55 {
  width: 55% !important;
}

.w-56 {
  width: 56% !important;
}

.w-57 {
  width: 57% !important;
}

.w-58 {
  width: 58% !important;
}

.w-59 {
  width: 59% !important;
}

.w-60 {
  width: 60% !important;
}

.w-61 {
  width: 61% !important;
}

.w-62 {
  width: 62% !important;
}

.w-63 {
  width: 63% !important;
}

.w-64 {
  width: 64% !important;
}

.w-65 {
  width: 65% !important;
}

.w-66 {
  width: 66% !important;
}

.w-67 {
  width: 67% !important;
}

.w-68 {
  width: 68% !important;
}

.w-69 {
  width: 69% !important;
}

.w-70 {
  width: 70% !important;
}

.w-71 {
  width: 71% !important;
}

.w-72 {
  width: 72% !important;
}

.w-73 {
  width: 73% !important;
}

.w-74 {
  width: 74% !important;
}

.w-75 {
  width: 75% !important;
}

.w-76 {
  width: 76% !important;
}

.w-77 {
  width: 77% !important;
}

.w-78 {
  width: 78% !important;
}

.w-79 {
  width: 79% !important;
}

.w-80 {
  width: 80% !important;
}

.w-81 {
  width: 81% !important;
}

.w-82 {
  width: 82% !important;
}

.w-83 {
  width: 83% !important;
}

.w-84 {
  width: 84% !important;
}

.w-85 {
  width: 85% !important;
}

.w-86 {
  width: 86% !important;
}

.w-87 {
  width: 87% !important;
}

.w-88 {
  width: 88% !important;
}

.w-89 {
  width: 89% !important;
}

.w-90 {
  width: 90% !important;
}

.w-91 {
  width: 91% !important;
}

.w-92 {
  width: 92% !important;
}

.w-93 {
  width: 93% !important;
}

.w-94 {
  width: 94% !important;
}

.w-95 {
  width: 95% !important;
}

.w-96 {
  width: 96% !important;
}

.w-97 {
  width: 97% !important;
}

.w-98 {
  width: 98% !important;
}

.w-99 {
  width: 99% !important;
}

.w-100 {
  width: 100% !important;
}

.h-0 {
  height: 0% !important;
}

.h-1 {
  height: 1% !important;
}

.h-2 {
  height: 2% !important;
}

.h-3 {
  height: 3% !important;
}

.h-4 {
  height: 4% !important;
}

.h-5 {
  height: 5% !important;
}

.h-6 {
  height: 6% !important;
}

.h-7 {
  height: 7% !important;
}

.h-8 {
  height: 8% !important;
}

.h-9 {
  height: 9% !important;
}

.h-10 {
  height: 10% !important;
}

.h-11 {
  height: 11% !important;
}

.h-12 {
  height: 12% !important;
}

.h-13 {
  height: 13% !important;
}

.h-14 {
  height: 14% !important;
}

.h-15 {
  height: 15% !important;
}

.h-16 {
  height: 16% !important;
}

.h-17 {
  height: 17% !important;
}

.h-18 {
  height: 18% !important;
}

.h-19 {
  height: 19% !important;
}

.h-20 {
  height: 20% !important;
}

.h-21 {
  height: 21% !important;
}

.h-22 {
  height: 22% !important;
}

.h-23 {
  height: 23% !important;
}

.h-24 {
  height: 24% !important;
}

.h-25 {
  height: 25% !important;
}

.h-26 {
  height: 26% !important;
}

.h-27 {
  height: 27% !important;
}

.h-28 {
  height: 28% !important;
}

.h-29 {
  height: 29% !important;
}

.h-30 {
  height: 30% !important;
}

.h-31 {
  height: 31% !important;
}

.h-32 {
  height: 32% !important;
}

.h-33 {
  height: 33% !important;
}

.h-34 {
  height: 34% !important;
}

.h-35 {
  height: 35% !important;
}

.h-36 {
  height: 36% !important;
}

.h-37 {
  height: 37% !important;
}

.h-38 {
  height: 38% !important;
}

.h-39 {
  height: 39% !important;
}

.h-40 {
  height: 40% !important;
}

.h-41 {
  height: 41% !important;
}

.h-42 {
  height: 42% !important;
}

.h-43 {
  height: 43% !important;
}

.h-44 {
  height: 44% !important;
}

.h-45 {
  height: 45% !important;
}

.h-46 {
  height: 46% !important;
}

.h-47 {
  height: 47% !important;
}

.h-48 {
  height: 48% !important;
}

.h-49 {
  height: 49% !important;
}

.h-50 {
  height: 50% !important;
}

.h-51 {
  height: 51% !important;
}

.h-52 {
  height: 52% !important;
}

.h-53 {
  height: 53% !important;
}

.h-54 {
  height: 54% !important;
}

.h-55 {
  height: 55% !important;
}

.h-56 {
  height: 56% !important;
}

.h-57 {
  height: 57% !important;
}

.h-58 {
  height: 58% !important;
}

.h-59 {
  height: 59% !important;
}

.h-60 {
  height: 60% !important;
}

.h-61 {
  height: 61% !important;
}

.h-62 {
  height: 62% !important;
}

.h-63 {
  height: 63% !important;
}

.h-64 {
  height: 64% !important;
}

.h-65 {
  height: 65% !important;
}

.h-66 {
  height: 66% !important;
}

.h-67 {
  height: 67% !important;
}

.h-68 {
  height: 68% !important;
}

.h-69 {
  height: 69% !important;
}

.h-70 {
  height: 70% !important;
}

.h-71 {
  height: 71% !important;
}

.h-72 {
  height: 72% !important;
}

.h-73 {
  height: 73% !important;
}

.h-74 {
  height: 74% !important;
}

.h-75 {
  height: 75% !important;
}

.h-76 {
  height: 76% !important;
}

.h-77 {
  height: 77% !important;
}

.h-78 {
  height: 78% !important;
}

.h-79 {
  height: 79% !important;
}

.h-80 {
  height: 80% !important;
}

.h-81 {
  height: 81% !important;
}

.h-82 {
  height: 82% !important;
}

.h-83 {
  height: 83% !important;
}

.h-84 {
  height: 84% !important;
}

.h-85 {
  height: 85% !important;
}

.h-86 {
  height: 86% !important;
}

.h-87 {
  height: 87% !important;
}

.h-88 {
  height: 88% !important;
}

.h-89 {
  height: 89% !important;
}

.h-90 {
  height: 90% !important;
}

.h-91 {
  height: 91% !important;
}

.h-92 {
  height: 92% !important;
}

.h-93 {
  height: 93% !important;
}

.h-94 {
  height: 94% !important;
}

.h-95 {
  height: 95% !important;
}

.h-96 {
  height: 96% !important;
}

.h-97 {
  height: 97% !important;
}

.h-98 {
  height: 98% !important;
}

.h-99 {
  height: 99% !important;
}

.h-100 {
  height: 100% !important;
}

.t-0 {
  top: 0% !important;
}

.t-1 {
  top: 1% !important;
}

.t-2 {
  top: 2% !important;
}

.t-3 {
  top: 3% !important;
}

.t-4 {
  top: 4% !important;
}

.t-5 {
  top: 5% !important;
}

.t-6 {
  top: 6% !important;
}

.t-7 {
  top: 7% !important;
}

.t-8 {
  top: 8% !important;
}

.t-9 {
  top: 9% !important;
}

.t-10 {
  top: 10% !important;
}

.t-11 {
  top: 11% !important;
}

.t-12 {
  top: 12% !important;
}

.t-13 {
  top: 13% !important;
}

.t-14 {
  top: 14% !important;
}

.t-15 {
  top: 15% !important;
}

.t-16 {
  top: 16% !important;
}

.t-17 {
  top: 17% !important;
}

.t-18 {
  top: 18% !important;
}

.t-19 {
  top: 19% !important;
}

.t-20 {
  top: 20% !important;
}

.t-21 {
  top: 21% !important;
}

.t-22 {
  top: 22% !important;
}

.t-23 {
  top: 23% !important;
}

.t-24 {
  top: 24% !important;
}

.t-25 {
  top: 25% !important;
}

.t-26 {
  top: 26% !important;
}

.t-27 {
  top: 27% !important;
}

.t-28 {
  top: 28% !important;
}

.t-29 {
  top: 29% !important;
}

.t-30 {
  top: 30% !important;
}

.t-31 {
  top: 31% !important;
}

.t-32 {
  top: 32% !important;
}

.t-33 {
  top: 33% !important;
}

.t-34 {
  top: 34% !important;
}

.t-35 {
  top: 35% !important;
}

.t-36 {
  top: 36% !important;
}

.t-37 {
  top: 37% !important;
}

.t-38 {
  top: 38% !important;
}

.t-39 {
  top: 39% !important;
}

.t-40 {
  top: 40% !important;
}

.t-41 {
  top: 41% !important;
}

.t-42 {
  top: 42% !important;
}

.t-43 {
  top: 43% !important;
}

.t-44 {
  top: 44% !important;
}

.t-45 {
  top: 45% !important;
}

.t-46 {
  top: 46% !important;
}

.t-47 {
  top: 47% !important;
}

.t-48 {
  top: 48% !important;
}

.t-49 {
  top: 49% !important;
}

.t-50 {
  top: 50% !important;
}

.t-51 {
  top: 51% !important;
}

.t-52 {
  top: 52% !important;
}

.t-53 {
  top: 53% !important;
}

.t-54 {
  top: 54% !important;
}

.t-55 {
  top: 55% !important;
}

.t-56 {
  top: 56% !important;
}

.t-57 {
  top: 57% !important;
}

.t-58 {
  top: 58% !important;
}

.t-59 {
  top: 59% !important;
}

.t-60 {
  top: 60% !important;
}

.t-61 {
  top: 61% !important;
}

.t-62 {
  top: 62% !important;
}

.t-63 {
  top: 63% !important;
}

.t-64 {
  top: 64% !important;
}

.t-65 {
  top: 65% !important;
}

.t-66 {
  top: 66% !important;
}

.t-67 {
  top: 67% !important;
}

.t-68 {
  top: 68% !important;
}

.t-69 {
  top: 69% !important;
}

.t-70 {
  top: 70% !important;
}

.t-71 {
  top: 71% !important;
}

.t-72 {
  top: 72% !important;
}

.t-73 {
  top: 73% !important;
}

.t-74 {
  top: 74% !important;
}

.t-75 {
  top: 75% !important;
}

.t-76 {
  top: 76% !important;
}

.t-77 {
  top: 77% !important;
}

.t-78 {
  top: 78% !important;
}

.t-79 {
  top: 79% !important;
}

.t-80 {
  top: 80% !important;
}

.t-81 {
  top: 81% !important;
}

.t-82 {
  top: 82% !important;
}

.t-83 {
  top: 83% !important;
}

.t-84 {
  top: 84% !important;
}

.t-85 {
  top: 85% !important;
}

.t-86 {
  top: 86% !important;
}

.t-87 {
  top: 87% !important;
}

.t-88 {
  top: 88% !important;
}

.t-89 {
  top: 89% !important;
}

.t-90 {
  top: 90% !important;
}

.t-91 {
  top: 91% !important;
}

.t-92 {
  top: 92% !important;
}

.t-93 {
  top: 93% !important;
}

.t-94 {
  top: 94% !important;
}

.t-95 {
  top: 95% !important;
}

.t-96 {
  top: 96% !important;
}

.t-97 {
  top: 97% !important;
}

.t-98 {
  top: 98% !important;
}

.t-99 {
  top: 99% !important;
}

.t-100 {
  top: 100% !important;
}

.l-0 {
  left: 0% !important;
}

.l-1 {
  left: 1% !important;
}

.l-2 {
  left: 2% !important;
}

.l-3 {
  left: 3% !important;
}

.l-4 {
  left: 4% !important;
}

.l-5 {
  left: 5% !important;
}

.l-6 {
  left: 6% !important;
}

.l-7 {
  left: 7% !important;
}

.l-8 {
  left: 8% !important;
}

.l-9 {
  left: 9% !important;
}

.l-10 {
  left: 10% !important;
}

.l-11 {
  left: 11% !important;
}

.l-12 {
  left: 12% !important;
}

.l-13 {
  left: 13% !important;
}

.l-14 {
  left: 14% !important;
}

.l-15 {
  left: 15% !important;
}

.l-16 {
  left: 16% !important;
}

.l-17 {
  left: 17% !important;
}

.l-18 {
  left: 18% !important;
}

.l-19 {
  left: 19% !important;
}

.l-20 {
  left: 20% !important;
}

.l-21 {
  left: 21% !important;
}

.l-22 {
  left: 22% !important;
}

.l-23 {
  left: 23% !important;
}

.l-24 {
  left: 24% !important;
}

.l-25 {
  left: 25% !important;
}

.l-26 {
  left: 26% !important;
}

.l-27 {
  left: 27% !important;
}

.l-28 {
  left: 28% !important;
}

.l-29 {
  left: 29% !important;
}

.l-30 {
  left: 30% !important;
}

.l-31 {
  left: 31% !important;
}

.l-32 {
  left: 32% !important;
}

.l-33 {
  left: 33% !important;
}

.l-34 {
  left: 34% !important;
}

.l-35 {
  left: 35% !important;
}

.l-36 {
  left: 36% !important;
}

.l-37 {
  left: 37% !important;
}

.l-38 {
  left: 38% !important;
}

.l-39 {
  left: 39% !important;
}

.l-40 {
  left: 40% !important;
}

.l-41 {
  left: 41% !important;
}

.l-42 {
  left: 42% !important;
}

.l-43 {
  left: 43% !important;
}

.l-44 {
  left: 44% !important;
}

.l-45 {
  left: 45% !important;
}

.l-46 {
  left: 46% !important;
}

.l-47 {
  left: 47% !important;
}

.l-48 {
  left: 48% !important;
}

.l-49 {
  left: 49% !important;
}

.l-50 {
  left: 50% !important;
}

.l-51 {
  left: 51% !important;
}

.l-52 {
  left: 52% !important;
}

.l-53 {
  left: 53% !important;
}

.l-54 {
  left: 54% !important;
}

.l-55 {
  left: 55% !important;
}

.l-56 {
  left: 56% !important;
}

.l-57 {
  left: 57% !important;
}

.l-58 {
  left: 58% !important;
}

.l-59 {
  left: 59% !important;
}

.l-60 {
  left: 60% !important;
}

.l-61 {
  left: 61% !important;
}

.l-62 {
  left: 62% !important;
}

.l-63 {
  left: 63% !important;
}

.l-64 {
  left: 64% !important;
}

.l-65 {
  left: 65% !important;
}

.l-66 {
  left: 66% !important;
}

.l-67 {
  left: 67% !important;
}

.l-68 {
  left: 68% !important;
}

.l-69 {
  left: 69% !important;
}

.l-70 {
  left: 70% !important;
}

.l-71 {
  left: 71% !important;
}

.l-72 {
  left: 72% !important;
}

.l-73 {
  left: 73% !important;
}

.l-74 {
  left: 74% !important;
}

.l-75 {
  left: 75% !important;
}

.l-76 {
  left: 76% !important;
}

.l-77 {
  left: 77% !important;
}

.l-78 {
  left: 78% !important;
}

.l-79 {
  left: 79% !important;
}

.l-80 {
  left: 80% !important;
}

.l-81 {
  left: 81% !important;
}

.l-82 {
  left: 82% !important;
}

.l-83 {
  left: 83% !important;
}

.l-84 {
  left: 84% !important;
}

.l-85 {
  left: 85% !important;
}

.l-86 {
  left: 86% !important;
}

.l-87 {
  left: 87% !important;
}

.l-88 {
  left: 88% !important;
}

.l-89 {
  left: 89% !important;
}

.l-90 {
  left: 90% !important;
}

.l-91 {
  left: 91% !important;
}

.l-92 {
  left: 92% !important;
}

.l-93 {
  left: 93% !important;
}

.l-94 {
  left: 94% !important;
}

.l-95 {
  left: 95% !important;
}

.l-96 {
  left: 96% !important;
}

.l-97 {
  left: 97% !important;
}

.l-98 {
  left: 98% !important;
}

.l-99 {
  left: 99% !important;
}

.l-100 {
  left: 100% !important;
}

.d-table-row {
  display: table-row !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
}

.order-initial {
  -ms-flex-order: initial;
      order: initial;
}

.w-0px {
  width: 0px !important;
}

.w-0rem {
  width: 0rem !important;
}

.w-1px {
  width: 1px !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2px {
  width: 2px !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3px {
  width: 3px !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4px {
  width: 4px !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5px {
  width: 5px !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6px {
  width: 6px !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7px {
  width: 7px !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8px {
  width: 8px !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9px {
  width: 9px !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10px {
  width: 10px !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11px {
  width: 11px !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12px {
  width: 12px !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13px {
  width: 13px !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14px {
  width: 14px !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15px {
  width: 15px !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16px {
  width: 16px !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17px {
  width: 17px !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18px {
  width: 18px !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19px {
  width: 19px !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20px {
  width: 20px !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21px {
  width: 21px !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22px {
  width: 22px !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23px {
  width: 23px !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24px {
  width: 24px !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25px {
  width: 25px !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26px {
  width: 26px !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27px {
  width: 27px !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28px {
  width: 28px !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29px {
  width: 29px !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30px {
  width: 30px !important;
}

.w-30rem {
  width: 30rem !important;
}

.w-31px {
  width: 31px !important;
}

.w-31rem {
  width: 31rem !important;
}

.w-32px {
  width: 32px !important;
}

.w-32rem {
  width: 32rem !important;
}

.w-33px {
  width: 33px !important;
}

.w-33rem {
  width: 33rem !important;
}

.w-34px {
  width: 34px !important;
}

.w-34rem {
  width: 34rem !important;
}

.w-35px {
  width: 35px !important;
}

.w-35rem {
  width: 35rem !important;
}

.w-36px {
  width: 36px !important;
}

.w-36rem {
  width: 36rem !important;
}

.w-37px {
  width: 37px !important;
}

.w-37rem {
  width: 37rem !important;
}

.w-38px {
  width: 38px !important;
}

.w-38rem {
  width: 38rem !important;
}

.w-39px {
  width: 39px !important;
}

.w-39rem {
  width: 39rem !important;
}

.w-40px {
  width: 40px !important;
}

.w-40rem {
  width: 40rem !important;
}

.w-41px {
  width: 41px !important;
}

.w-41rem {
  width: 41rem !important;
}

.w-42px {
  width: 42px !important;
}

.w-42rem {
  width: 42rem !important;
}

.w-43px {
  width: 43px !important;
}

.w-43rem {
  width: 43rem !important;
}

.w-44px {
  width: 44px !important;
}

.w-44rem {
  width: 44rem !important;
}

.w-45px {
  width: 45px !important;
}

.w-45rem {
  width: 45rem !important;
}

.w-46px {
  width: 46px !important;
}

.w-46rem {
  width: 46rem !important;
}

.w-47px {
  width: 47px !important;
}

.w-47rem {
  width: 47rem !important;
}

.w-48px {
  width: 48px !important;
}

.w-48rem {
  width: 48rem !important;
}

.w-49px {
  width: 49px !important;
}

.w-49rem {
  width: 49rem !important;
}

.w-50px {
  width: 50px !important;
}

.w-50rem {
  width: 50rem !important;
}

.w-51px {
  width: 51px !important;
}

.w-51rem {
  width: 51rem !important;
}

.w-52px {
  width: 52px !important;
}

.w-52rem {
  width: 52rem !important;
}

.w-53px {
  width: 53px !important;
}

.w-53rem {
  width: 53rem !important;
}

.w-54px {
  width: 54px !important;
}

.w-54rem {
  width: 54rem !important;
}

.w-55px {
  width: 55px !important;
}

.w-55rem {
  width: 55rem !important;
}

.w-56px {
  width: 56px !important;
}

.w-56rem {
  width: 56rem !important;
}

.w-57px {
  width: 57px !important;
}

.w-57rem {
  width: 57rem !important;
}

.w-58px {
  width: 58px !important;
}

.w-58rem {
  width: 58rem !important;
}

.w-59px {
  width: 59px !important;
}

.w-59rem {
  width: 59rem !important;
}

.w-60px {
  width: 60px !important;
}

.w-60rem {
  width: 60rem !important;
}

.w-61px {
  width: 61px !important;
}

.w-61rem {
  width: 61rem !important;
}

.w-62px {
  width: 62px !important;
}

.w-62rem {
  width: 62rem !important;
}

.w-63px {
  width: 63px !important;
}

.w-63rem {
  width: 63rem !important;
}

.w-64px {
  width: 64px !important;
}

.w-64rem {
  width: 64rem !important;
}

.w-65px {
  width: 65px !important;
}

.w-65rem {
  width: 65rem !important;
}

.w-66px {
  width: 66px !important;
}

.w-66rem {
  width: 66rem !important;
}

.w-67px {
  width: 67px !important;
}

.w-67rem {
  width: 67rem !important;
}

.w-68px {
  width: 68px !important;
}

.w-68rem {
  width: 68rem !important;
}

.w-69px {
  width: 69px !important;
}

.w-69rem {
  width: 69rem !important;
}

.w-70px {
  width: 70px !important;
}

.w-70rem {
  width: 70rem !important;
}

.w-71px {
  width: 71px !important;
}

.w-71rem {
  width: 71rem !important;
}

.w-72px {
  width: 72px !important;
}

.w-72rem {
  width: 72rem !important;
}

.w-73px {
  width: 73px !important;
}

.w-73rem {
  width: 73rem !important;
}

.w-74px {
  width: 74px !important;
}

.w-74rem {
  width: 74rem !important;
}

.w-75px {
  width: 75px !important;
}

.w-75rem {
  width: 75rem !important;
}

.w-76px {
  width: 76px !important;
}

.w-76rem {
  width: 76rem !important;
}

.w-77px {
  width: 77px !important;
}

.w-77rem {
  width: 77rem !important;
}

.w-78px {
  width: 78px !important;
}

.w-78rem {
  width: 78rem !important;
}

.w-79px {
  width: 79px !important;
}

.w-79rem {
  width: 79rem !important;
}

.w-80px {
  width: 80px !important;
}

.w-80rem {
  width: 80rem !important;
}

.w-81px {
  width: 81px !important;
}

.w-81rem {
  width: 81rem !important;
}

.w-82px {
  width: 82px !important;
}

.w-82rem {
  width: 82rem !important;
}

.w-83px {
  width: 83px !important;
}

.w-83rem {
  width: 83rem !important;
}

.w-84px {
  width: 84px !important;
}

.w-84rem {
  width: 84rem !important;
}

.w-85px {
  width: 85px !important;
}

.w-85rem {
  width: 85rem !important;
}

.w-86px {
  width: 86px !important;
}

.w-86rem {
  width: 86rem !important;
}

.w-87px {
  width: 87px !important;
}

.w-87rem {
  width: 87rem !important;
}

.w-88px {
  width: 88px !important;
}

.w-88rem {
  width: 88rem !important;
}

.w-89px {
  width: 89px !important;
}

.w-89rem {
  width: 89rem !important;
}

.w-90px {
  width: 90px !important;
}

.w-90rem {
  width: 90rem !important;
}

.w-91px {
  width: 91px !important;
}

.w-91rem {
  width: 91rem !important;
}

.w-92px {
  width: 92px !important;
}

.w-92rem {
  width: 92rem !important;
}

.w-93px {
  width: 93px !important;
}

.w-93rem {
  width: 93rem !important;
}

.w-94px {
  width: 94px !important;
}

.w-94rem {
  width: 94rem !important;
}

.w-95px {
  width: 95px !important;
}

.w-95rem {
  width: 95rem !important;
}

.w-96px {
  width: 96px !important;
}

.w-96rem {
  width: 96rem !important;
}

.w-97px {
  width: 97px !important;
}

.w-97rem {
  width: 97rem !important;
}

.w-98px {
  width: 98px !important;
}

.w-98rem {
  width: 98rem !important;
}

.w-99px {
  width: 99px !important;
}

.w-99rem {
  width: 99rem !important;
}

.w-100px {
  width: 100px !important;
}

.w-100rem {
  width: 100rem !important;
}

.h-0px {
  height: 0px !important;
}

.h-0rem {
  height: 0rem !important;
}

.h-1px {
  height: 1px !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2px {
  height: 2px !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3px {
  height: 3px !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4px {
  height: 4px !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5px {
  height: 5px !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6px {
  height: 6px !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7px {
  height: 7px !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8px {
  height: 8px !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9px {
  height: 9px !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10px {
  height: 10px !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11px {
  height: 11px !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12px {
  height: 12px !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13px {
  height: 13px !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14px {
  height: 14px !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15px {
  height: 15px !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16px {
  height: 16px !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17px {
  height: 17px !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18px {
  height: 18px !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19px {
  height: 19px !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20px {
  height: 20px !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21px {
  height: 21px !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22px {
  height: 22px !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23px {
  height: 23px !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24px {
  height: 24px !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25px {
  height: 25px !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26px {
  height: 26px !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27px {
  height: 27px !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28px {
  height: 28px !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29px {
  height: 29px !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30px {
  height: 30px !important;
}

.h-30rem {
  height: 30rem !important;
}

.h-31px {
  height: 31px !important;
}

.h-31rem {
  height: 31rem !important;
}

.h-32px {
  height: 32px !important;
}

.h-32rem {
  height: 32rem !important;
}

.h-33px {
  height: 33px !important;
}

.h-33rem {
  height: 33rem !important;
}

.h-34px {
  height: 34px !important;
}

.h-34rem {
  height: 34rem !important;
}

.h-35px {
  height: 35px !important;
}

.h-35rem {
  height: 35rem !important;
}

.h-36px {
  height: 36px !important;
}

.h-36rem {
  height: 36rem !important;
}

.h-37px {
  height: 37px !important;
}

.h-37rem {
  height: 37rem !important;
}

.h-38px {
  height: 38px !important;
}

.h-38rem {
  height: 38rem !important;
}

.h-39px {
  height: 39px !important;
}

.h-39rem {
  height: 39rem !important;
}

.h-40px {
  height: 40px !important;
}

.h-40rem {
  height: 40rem !important;
}

.h-41px {
  height: 41px !important;
}

.h-41rem {
  height: 41rem !important;
}

.h-42px {
  height: 42px !important;
}

.h-42rem {
  height: 42rem !important;
}

.h-43px {
  height: 43px !important;
}

.h-43rem {
  height: 43rem !important;
}

.h-44px {
  height: 44px !important;
}

.h-44rem {
  height: 44rem !important;
}

.h-45px {
  height: 45px !important;
}

.h-45rem {
  height: 45rem !important;
}

.h-46px {
  height: 46px !important;
}

.h-46rem {
  height: 46rem !important;
}

.h-47px {
  height: 47px !important;
}

.h-47rem {
  height: 47rem !important;
}

.h-48px {
  height: 48px !important;
}

.h-48rem {
  height: 48rem !important;
}

.h-49px {
  height: 49px !important;
}

.h-49rem {
  height: 49rem !important;
}

.h-50px {
  height: 50px !important;
}

.h-50rem {
  height: 50rem !important;
}

.h-51px {
  height: 51px !important;
}

.h-51rem {
  height: 51rem !important;
}

.h-52px {
  height: 52px !important;
}

.h-52rem {
  height: 52rem !important;
}

.h-53px {
  height: 53px !important;
}

.h-53rem {
  height: 53rem !important;
}

.h-54px {
  height: 54px !important;
}

.h-54rem {
  height: 54rem !important;
}

.h-55px {
  height: 55px !important;
}

.h-55rem {
  height: 55rem !important;
}

.h-56px {
  height: 56px !important;
}

.h-56rem {
  height: 56rem !important;
}

.h-57px {
  height: 57px !important;
}

.h-57rem {
  height: 57rem !important;
}

.h-58px {
  height: 58px !important;
}

.h-58rem {
  height: 58rem !important;
}

.h-59px {
  height: 59px !important;
}

.h-59rem {
  height: 59rem !important;
}

.h-60px {
  height: 60px !important;
}

.h-60rem {
  height: 60rem !important;
}

.h-61px {
  height: 61px !important;
}

.h-61rem {
  height: 61rem !important;
}

.h-62px {
  height: 62px !important;
}

.h-62rem {
  height: 62rem !important;
}

.h-63px {
  height: 63px !important;
}

.h-63rem {
  height: 63rem !important;
}

.h-64px {
  height: 64px !important;
}

.h-64rem {
  height: 64rem !important;
}

.h-65px {
  height: 65px !important;
}

.h-65rem {
  height: 65rem !important;
}

.h-66px {
  height: 66px !important;
}

.h-66rem {
  height: 66rem !important;
}

.h-67px {
  height: 67px !important;
}

.h-67rem {
  height: 67rem !important;
}

.h-68px {
  height: 68px !important;
}

.h-68rem {
  height: 68rem !important;
}

.h-69px {
  height: 69px !important;
}

.h-69rem {
  height: 69rem !important;
}

.h-70px {
  height: 70px !important;
}

.h-70rem {
  height: 70rem !important;
}

.h-71px {
  height: 71px !important;
}

.h-71rem {
  height: 71rem !important;
}

.h-72px {
  height: 72px !important;
}

.h-72rem {
  height: 72rem !important;
}

.h-73px {
  height: 73px !important;
}

.h-73rem {
  height: 73rem !important;
}

.h-74px {
  height: 74px !important;
}

.h-74rem {
  height: 74rem !important;
}

.h-75px {
  height: 75px !important;
}

.h-75rem {
  height: 75rem !important;
}

.h-76px {
  height: 76px !important;
}

.h-76rem {
  height: 76rem !important;
}

.h-77px {
  height: 77px !important;
}

.h-77rem {
  height: 77rem !important;
}

.h-78px {
  height: 78px !important;
}

.h-78rem {
  height: 78rem !important;
}

.h-79px {
  height: 79px !important;
}

.h-79rem {
  height: 79rem !important;
}

.h-80px {
  height: 80px !important;
}

.h-80rem {
  height: 80rem !important;
}

.h-81px {
  height: 81px !important;
}

.h-81rem {
  height: 81rem !important;
}

.h-82px {
  height: 82px !important;
}

.h-82rem {
  height: 82rem !important;
}

.h-83px {
  height: 83px !important;
}

.h-83rem {
  height: 83rem !important;
}

.h-84px {
  height: 84px !important;
}

.h-84rem {
  height: 84rem !important;
}

.h-85px {
  height: 85px !important;
}

.h-85rem {
  height: 85rem !important;
}

.h-86px {
  height: 86px !important;
}

.h-86rem {
  height: 86rem !important;
}

.h-87px {
  height: 87px !important;
}

.h-87rem {
  height: 87rem !important;
}

.h-88px {
  height: 88px !important;
}

.h-88rem {
  height: 88rem !important;
}

.h-89px {
  height: 89px !important;
}

.h-89rem {
  height: 89rem !important;
}

.h-90px {
  height: 90px !important;
}

.h-90rem {
  height: 90rem !important;
}

.h-91px {
  height: 91px !important;
}

.h-91rem {
  height: 91rem !important;
}

.h-92px {
  height: 92px !important;
}

.h-92rem {
  height: 92rem !important;
}

.h-93px {
  height: 93px !important;
}

.h-93rem {
  height: 93rem !important;
}

.h-94px {
  height: 94px !important;
}

.h-94rem {
  height: 94rem !important;
}

.h-95px {
  height: 95px !important;
}

.h-95rem {
  height: 95rem !important;
}

.h-96px {
  height: 96px !important;
}

.h-96rem {
  height: 96rem !important;
}

.h-97px {
  height: 97px !important;
}

.h-97rem {
  height: 97rem !important;
}

.h-98px {
  height: 98px !important;
}

.h-98rem {
  height: 98rem !important;
}

.h-99px {
  height: 99px !important;
}

.h-99rem {
  height: 99rem !important;
}

.h-100px {
  height: 100px !important;
}

.h-100rem {
  height: 100rem !important;
}

.t-0px {
  top: 0px !important;
}

.t-0rem {
  top: 0rem !important;
}

.t-1px {
  top: 1px !important;
}

.t-1rem {
  top: 1rem !important;
}

.t-2px {
  top: 2px !important;
}

.t-2rem {
  top: 2rem !important;
}

.t-3px {
  top: 3px !important;
}

.t-3rem {
  top: 3rem !important;
}

.t-4px {
  top: 4px !important;
}

.t-4rem {
  top: 4rem !important;
}

.t-5px {
  top: 5px !important;
}

.t-5rem {
  top: 5rem !important;
}

.t-6px {
  top: 6px !important;
}

.t-6rem {
  top: 6rem !important;
}

.t-7px {
  top: 7px !important;
}

.t-7rem {
  top: 7rem !important;
}

.t-8px {
  top: 8px !important;
}

.t-8rem {
  top: 8rem !important;
}

.t-9px {
  top: 9px !important;
}

.t-9rem {
  top: 9rem !important;
}

.t-10px {
  top: 10px !important;
}

.t-10rem {
  top: 10rem !important;
}

.t-11px {
  top: 11px !important;
}

.t-11rem {
  top: 11rem !important;
}

.t-12px {
  top: 12px !important;
}

.t-12rem {
  top: 12rem !important;
}

.t-13px {
  top: 13px !important;
}

.t-13rem {
  top: 13rem !important;
}

.t-14px {
  top: 14px !important;
}

.t-14rem {
  top: 14rem !important;
}

.t-15px {
  top: 15px !important;
}

.t-15rem {
  top: 15rem !important;
}

.t-16px {
  top: 16px !important;
}

.t-16rem {
  top: 16rem !important;
}

.t-17px {
  top: 17px !important;
}

.t-17rem {
  top: 17rem !important;
}

.t-18px {
  top: 18px !important;
}

.t-18rem {
  top: 18rem !important;
}

.t-19px {
  top: 19px !important;
}

.t-19rem {
  top: 19rem !important;
}

.t-20px {
  top: 20px !important;
}

.t-20rem {
  top: 20rem !important;
}

.t-21px {
  top: 21px !important;
}

.t-21rem {
  top: 21rem !important;
}

.t-22px {
  top: 22px !important;
}

.t-22rem {
  top: 22rem !important;
}

.t-23px {
  top: 23px !important;
}

.t-23rem {
  top: 23rem !important;
}

.t-24px {
  top: 24px !important;
}

.t-24rem {
  top: 24rem !important;
}

.t-25px {
  top: 25px !important;
}

.t-25rem {
  top: 25rem !important;
}

.t-26px {
  top: 26px !important;
}

.t-26rem {
  top: 26rem !important;
}

.t-27px {
  top: 27px !important;
}

.t-27rem {
  top: 27rem !important;
}

.t-28px {
  top: 28px !important;
}

.t-28rem {
  top: 28rem !important;
}

.t-29px {
  top: 29px !important;
}

.t-29rem {
  top: 29rem !important;
}

.t-30px {
  top: 30px !important;
}

.t-30rem {
  top: 30rem !important;
}

.t-31px {
  top: 31px !important;
}

.t-31rem {
  top: 31rem !important;
}

.t-32px {
  top: 32px !important;
}

.t-32rem {
  top: 32rem !important;
}

.t-33px {
  top: 33px !important;
}

.t-33rem {
  top: 33rem !important;
}

.t-34px {
  top: 34px !important;
}

.t-34rem {
  top: 34rem !important;
}

.t-35px {
  top: 35px !important;
}

.t-35rem {
  top: 35rem !important;
}

.t-36px {
  top: 36px !important;
}

.t-36rem {
  top: 36rem !important;
}

.t-37px {
  top: 37px !important;
}

.t-37rem {
  top: 37rem !important;
}

.t-38px {
  top: 38px !important;
}

.t-38rem {
  top: 38rem !important;
}

.t-39px {
  top: 39px !important;
}

.t-39rem {
  top: 39rem !important;
}

.t-40px {
  top: 40px !important;
}

.t-40rem {
  top: 40rem !important;
}

.t-41px {
  top: 41px !important;
}

.t-41rem {
  top: 41rem !important;
}

.t-42px {
  top: 42px !important;
}

.t-42rem {
  top: 42rem !important;
}

.t-43px {
  top: 43px !important;
}

.t-43rem {
  top: 43rem !important;
}

.t-44px {
  top: 44px !important;
}

.t-44rem {
  top: 44rem !important;
}

.t-45px {
  top: 45px !important;
}

.t-45rem {
  top: 45rem !important;
}

.t-46px {
  top: 46px !important;
}

.t-46rem {
  top: 46rem !important;
}

.t-47px {
  top: 47px !important;
}

.t-47rem {
  top: 47rem !important;
}

.t-48px {
  top: 48px !important;
}

.t-48rem {
  top: 48rem !important;
}

.t-49px {
  top: 49px !important;
}

.t-49rem {
  top: 49rem !important;
}

.t-50px {
  top: 50px !important;
}

.t-50rem {
  top: 50rem !important;
}

.t-51px {
  top: 51px !important;
}

.t-51rem {
  top: 51rem !important;
}

.t-52px {
  top: 52px !important;
}

.t-52rem {
  top: 52rem !important;
}

.t-53px {
  top: 53px !important;
}

.t-53rem {
  top: 53rem !important;
}

.t-54px {
  top: 54px !important;
}

.t-54rem {
  top: 54rem !important;
}

.t-55px {
  top: 55px !important;
}

.t-55rem {
  top: 55rem !important;
}

.t-56px {
  top: 56px !important;
}

.t-56rem {
  top: 56rem !important;
}

.t-57px {
  top: 57px !important;
}

.t-57rem {
  top: 57rem !important;
}

.t-58px {
  top: 58px !important;
}

.t-58rem {
  top: 58rem !important;
}

.t-59px {
  top: 59px !important;
}

.t-59rem {
  top: 59rem !important;
}

.t-60px {
  top: 60px !important;
}

.t-60rem {
  top: 60rem !important;
}

.t-61px {
  top: 61px !important;
}

.t-61rem {
  top: 61rem !important;
}

.t-62px {
  top: 62px !important;
}

.t-62rem {
  top: 62rem !important;
}

.t-63px {
  top: 63px !important;
}

.t-63rem {
  top: 63rem !important;
}

.t-64px {
  top: 64px !important;
}

.t-64rem {
  top: 64rem !important;
}

.t-65px {
  top: 65px !important;
}

.t-65rem {
  top: 65rem !important;
}

.t-66px {
  top: 66px !important;
}

.t-66rem {
  top: 66rem !important;
}

.t-67px {
  top: 67px !important;
}

.t-67rem {
  top: 67rem !important;
}

.t-68px {
  top: 68px !important;
}

.t-68rem {
  top: 68rem !important;
}

.t-69px {
  top: 69px !important;
}

.t-69rem {
  top: 69rem !important;
}

.t-70px {
  top: 70px !important;
}

.t-70rem {
  top: 70rem !important;
}

.t-71px {
  top: 71px !important;
}

.t-71rem {
  top: 71rem !important;
}

.t-72px {
  top: 72px !important;
}

.t-72rem {
  top: 72rem !important;
}

.t-73px {
  top: 73px !important;
}

.t-73rem {
  top: 73rem !important;
}

.t-74px {
  top: 74px !important;
}

.t-74rem {
  top: 74rem !important;
}

.t-75px {
  top: 75px !important;
}

.t-75rem {
  top: 75rem !important;
}

.t-76px {
  top: 76px !important;
}

.t-76rem {
  top: 76rem !important;
}

.t-77px {
  top: 77px !important;
}

.t-77rem {
  top: 77rem !important;
}

.t-78px {
  top: 78px !important;
}

.t-78rem {
  top: 78rem !important;
}

.t-79px {
  top: 79px !important;
}

.t-79rem {
  top: 79rem !important;
}

.t-80px {
  top: 80px !important;
}

.t-80rem {
  top: 80rem !important;
}

.t-81px {
  top: 81px !important;
}

.t-81rem {
  top: 81rem !important;
}

.t-82px {
  top: 82px !important;
}

.t-82rem {
  top: 82rem !important;
}

.t-83px {
  top: 83px !important;
}

.t-83rem {
  top: 83rem !important;
}

.t-84px {
  top: 84px !important;
}

.t-84rem {
  top: 84rem !important;
}

.t-85px {
  top: 85px !important;
}

.t-85rem {
  top: 85rem !important;
}

.t-86px {
  top: 86px !important;
}

.t-86rem {
  top: 86rem !important;
}

.t-87px {
  top: 87px !important;
}

.t-87rem {
  top: 87rem !important;
}

.t-88px {
  top: 88px !important;
}

.t-88rem {
  top: 88rem !important;
}

.t-89px {
  top: 89px !important;
}

.t-89rem {
  top: 89rem !important;
}

.t-90px {
  top: 90px !important;
}

.t-90rem {
  top: 90rem !important;
}

.t-91px {
  top: 91px !important;
}

.t-91rem {
  top: 91rem !important;
}

.t-92px {
  top: 92px !important;
}

.t-92rem {
  top: 92rem !important;
}

.t-93px {
  top: 93px !important;
}

.t-93rem {
  top: 93rem !important;
}

.t-94px {
  top: 94px !important;
}

.t-94rem {
  top: 94rem !important;
}

.t-95px {
  top: 95px !important;
}

.t-95rem {
  top: 95rem !important;
}

.t-96px {
  top: 96px !important;
}

.t-96rem {
  top: 96rem !important;
}

.t-97px {
  top: 97px !important;
}

.t-97rem {
  top: 97rem !important;
}

.t-98px {
  top: 98px !important;
}

.t-98rem {
  top: 98rem !important;
}

.t-99px {
  top: 99px !important;
}

.t-99rem {
  top: 99rem !important;
}

.t-100px {
  top: 100px !important;
}

.t-100rem {
  top: 100rem !important;
}

.l-0px {
  left: 0px !important;
}

.l-0rem {
  left: 0rem !important;
}

.l-1px {
  left: 1px !important;
}

.l-1rem {
  left: 1rem !important;
}

.l-2px {
  left: 2px !important;
}

.l-2rem {
  left: 2rem !important;
}

.l-3px {
  left: 3px !important;
}

.l-3rem {
  left: 3rem !important;
}

.l-4px {
  left: 4px !important;
}

.l-4rem {
  left: 4rem !important;
}

.l-5px {
  left: 5px !important;
}

.l-5rem {
  left: 5rem !important;
}

.l-6px {
  left: 6px !important;
}

.l-6rem {
  left: 6rem !important;
}

.l-7px {
  left: 7px !important;
}

.l-7rem {
  left: 7rem !important;
}

.l-8px {
  left: 8px !important;
}

.l-8rem {
  left: 8rem !important;
}

.l-9px {
  left: 9px !important;
}

.l-9rem {
  left: 9rem !important;
}

.l-10px {
  left: 10px !important;
}

.l-10rem {
  left: 10rem !important;
}

.l-11px {
  left: 11px !important;
}

.l-11rem {
  left: 11rem !important;
}

.l-12px {
  left: 12px !important;
}

.l-12rem {
  left: 12rem !important;
}

.l-13px {
  left: 13px !important;
}

.l-13rem {
  left: 13rem !important;
}

.l-14px {
  left: 14px !important;
}

.l-14rem {
  left: 14rem !important;
}

.l-15px {
  left: 15px !important;
}

.l-15rem {
  left: 15rem !important;
}

.l-16px {
  left: 16px !important;
}

.l-16rem {
  left: 16rem !important;
}

.l-17px {
  left: 17px !important;
}

.l-17rem {
  left: 17rem !important;
}

.l-18px {
  left: 18px !important;
}

.l-18rem {
  left: 18rem !important;
}

.l-19px {
  left: 19px !important;
}

.l-19rem {
  left: 19rem !important;
}

.l-20px {
  left: 20px !important;
}

.l-20rem {
  left: 20rem !important;
}

.l-21px {
  left: 21px !important;
}

.l-21rem {
  left: 21rem !important;
}

.l-22px {
  left: 22px !important;
}

.l-22rem {
  left: 22rem !important;
}

.l-23px {
  left: 23px !important;
}

.l-23rem {
  left: 23rem !important;
}

.l-24px {
  left: 24px !important;
}

.l-24rem {
  left: 24rem !important;
}

.l-25px {
  left: 25px !important;
}

.l-25rem {
  left: 25rem !important;
}

.l-26px {
  left: 26px !important;
}

.l-26rem {
  left: 26rem !important;
}

.l-27px {
  left: 27px !important;
}

.l-27rem {
  left: 27rem !important;
}

.l-28px {
  left: 28px !important;
}

.l-28rem {
  left: 28rem !important;
}

.l-29px {
  left: 29px !important;
}

.l-29rem {
  left: 29rem !important;
}

.l-30px {
  left: 30px !important;
}

.l-30rem {
  left: 30rem !important;
}

.l-31px {
  left: 31px !important;
}

.l-31rem {
  left: 31rem !important;
}

.l-32px {
  left: 32px !important;
}

.l-32rem {
  left: 32rem !important;
}

.l-33px {
  left: 33px !important;
}

.l-33rem {
  left: 33rem !important;
}

.l-34px {
  left: 34px !important;
}

.l-34rem {
  left: 34rem !important;
}

.l-35px {
  left: 35px !important;
}

.l-35rem {
  left: 35rem !important;
}

.l-36px {
  left: 36px !important;
}

.l-36rem {
  left: 36rem !important;
}

.l-37px {
  left: 37px !important;
}

.l-37rem {
  left: 37rem !important;
}

.l-38px {
  left: 38px !important;
}

.l-38rem {
  left: 38rem !important;
}

.l-39px {
  left: 39px !important;
}

.l-39rem {
  left: 39rem !important;
}

.l-40px {
  left: 40px !important;
}

.l-40rem {
  left: 40rem !important;
}

.l-41px {
  left: 41px !important;
}

.l-41rem {
  left: 41rem !important;
}

.l-42px {
  left: 42px !important;
}

.l-42rem {
  left: 42rem !important;
}

.l-43px {
  left: 43px !important;
}

.l-43rem {
  left: 43rem !important;
}

.l-44px {
  left: 44px !important;
}

.l-44rem {
  left: 44rem !important;
}

.l-45px {
  left: 45px !important;
}

.l-45rem {
  left: 45rem !important;
}

.l-46px {
  left: 46px !important;
}

.l-46rem {
  left: 46rem !important;
}

.l-47px {
  left: 47px !important;
}

.l-47rem {
  left: 47rem !important;
}

.l-48px {
  left: 48px !important;
}

.l-48rem {
  left: 48rem !important;
}

.l-49px {
  left: 49px !important;
}

.l-49rem {
  left: 49rem !important;
}

.l-50px {
  left: 50px !important;
}

.l-50rem {
  left: 50rem !important;
}

.l-51px {
  left: 51px !important;
}

.l-51rem {
  left: 51rem !important;
}

.l-52px {
  left: 52px !important;
}

.l-52rem {
  left: 52rem !important;
}

.l-53px {
  left: 53px !important;
}

.l-53rem {
  left: 53rem !important;
}

.l-54px {
  left: 54px !important;
}

.l-54rem {
  left: 54rem !important;
}

.l-55px {
  left: 55px !important;
}

.l-55rem {
  left: 55rem !important;
}

.l-56px {
  left: 56px !important;
}

.l-56rem {
  left: 56rem !important;
}

.l-57px {
  left: 57px !important;
}

.l-57rem {
  left: 57rem !important;
}

.l-58px {
  left: 58px !important;
}

.l-58rem {
  left: 58rem !important;
}

.l-59px {
  left: 59px !important;
}

.l-59rem {
  left: 59rem !important;
}

.l-60px {
  left: 60px !important;
}

.l-60rem {
  left: 60rem !important;
}

.l-61px {
  left: 61px !important;
}

.l-61rem {
  left: 61rem !important;
}

.l-62px {
  left: 62px !important;
}

.l-62rem {
  left: 62rem !important;
}

.l-63px {
  left: 63px !important;
}

.l-63rem {
  left: 63rem !important;
}

.l-64px {
  left: 64px !important;
}

.l-64rem {
  left: 64rem !important;
}

.l-65px {
  left: 65px !important;
}

.l-65rem {
  left: 65rem !important;
}

.l-66px {
  left: 66px !important;
}

.l-66rem {
  left: 66rem !important;
}

.l-67px {
  left: 67px !important;
}

.l-67rem {
  left: 67rem !important;
}

.l-68px {
  left: 68px !important;
}

.l-68rem {
  left: 68rem !important;
}

.l-69px {
  left: 69px !important;
}

.l-69rem {
  left: 69rem !important;
}

.l-70px {
  left: 70px !important;
}

.l-70rem {
  left: 70rem !important;
}

.l-71px {
  left: 71px !important;
}

.l-71rem {
  left: 71rem !important;
}

.l-72px {
  left: 72px !important;
}

.l-72rem {
  left: 72rem !important;
}

.l-73px {
  left: 73px !important;
}

.l-73rem {
  left: 73rem !important;
}

.l-74px {
  left: 74px !important;
}

.l-74rem {
  left: 74rem !important;
}

.l-75px {
  left: 75px !important;
}

.l-75rem {
  left: 75rem !important;
}

.l-76px {
  left: 76px !important;
}

.l-76rem {
  left: 76rem !important;
}

.l-77px {
  left: 77px !important;
}

.l-77rem {
  left: 77rem !important;
}

.l-78px {
  left: 78px !important;
}

.l-78rem {
  left: 78rem !important;
}

.l-79px {
  left: 79px !important;
}

.l-79rem {
  left: 79rem !important;
}

.l-80px {
  left: 80px !important;
}

.l-80rem {
  left: 80rem !important;
}

.l-81px {
  left: 81px !important;
}

.l-81rem {
  left: 81rem !important;
}

.l-82px {
  left: 82px !important;
}

.l-82rem {
  left: 82rem !important;
}

.l-83px {
  left: 83px !important;
}

.l-83rem {
  left: 83rem !important;
}

.l-84px {
  left: 84px !important;
}

.l-84rem {
  left: 84rem !important;
}

.l-85px {
  left: 85px !important;
}

.l-85rem {
  left: 85rem !important;
}

.l-86px {
  left: 86px !important;
}

.l-86rem {
  left: 86rem !important;
}

.l-87px {
  left: 87px !important;
}

.l-87rem {
  left: 87rem !important;
}

.l-88px {
  left: 88px !important;
}

.l-88rem {
  left: 88rem !important;
}

.l-89px {
  left: 89px !important;
}

.l-89rem {
  left: 89rem !important;
}

.l-90px {
  left: 90px !important;
}

.l-90rem {
  left: 90rem !important;
}

.l-91px {
  left: 91px !important;
}

.l-91rem {
  left: 91rem !important;
}

.l-92px {
  left: 92px !important;
}

.l-92rem {
  left: 92rem !important;
}

.l-93px {
  left: 93px !important;
}

.l-93rem {
  left: 93rem !important;
}

.l-94px {
  left: 94px !important;
}

.l-94rem {
  left: 94rem !important;
}

.l-95px {
  left: 95px !important;
}

.l-95rem {
  left: 95rem !important;
}

.l-96px {
  left: 96px !important;
}

.l-96rem {
  left: 96rem !important;
}

.l-97px {
  left: 97px !important;
}

.l-97rem {
  left: 97rem !important;
}

.l-98px {
  left: 98px !important;
}

.l-98rem {
  left: 98rem !important;
}

.l-99px {
  left: 99px !important;
}

.l-99rem {
  left: 99rem !important;
}

.l-100px {
  left: 100px !important;
}

.l-100rem {
  left: 100rem !important;
}

.h-auto {
  height: auto !important;
}

.pt-0px {
  padding-top: 0px !important;
}

.pb-0px {
  padding-bottom: 0px !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.pb-20px {
  padding-bottom: 20px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pt-30px {
  padding-top: 30px !important;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.pt-35px {
  padding-top: 35px !important;
}

.pb-35px {
  padding-bottom: 35px !important;
}

.pt-40px {
  padding-top: 40px !important;
}

.pb-40px {
  padding-bottom: 40px !important;
}

.pt-45px {
  padding-top: 45px !important;
}

.pb-45px {
  padding-bottom: 45px !important;
}

.pt-50px {
  padding-top: 50px !important;
}

.pb-50px {
  padding-bottom: 50px !important;
}

.pt-55px {
  padding-top: 55px !important;
}

.pb-55px {
  padding-bottom: 55px !important;
}

.pt-60px {
  padding-top: 60px !important;
}

.pb-60px {
  padding-bottom: 60px !important;
}

.pt-65px {
  padding-top: 65px !important;
}

.pb-65px {
  padding-bottom: 65px !important;
}

.pt-70px {
  padding-top: 70px !important;
}

.pb-70px {
  padding-bottom: 70px !important;
}

.pt-75px {
  padding-top: 75px !important;
}

.pb-75px {
  padding-bottom: 75px !important;
}

.pt-80px {
  padding-top: 80px !important;
}

.pb-80px {
  padding-bottom: 80px !important;
}

.pt-85px {
  padding-top: 85px !important;
}

.pb-85px {
  padding-bottom: 85px !important;
}

.pt-90px {
  padding-top: 90px !important;
}

.pb-90px {
  padding-bottom: 90px !important;
}

.pt-95px {
  padding-top: 95px !important;
}

.pb-95px {
  padding-bottom: 95px !important;
}

.pt-100px {
  padding-top: 100px !important;
}

.pb-100px {
  padding-bottom: 100px !important;
}

.mt-0px {
  margin-top: 0px !important;
}

.mb-0px {
  margin-bottom: 0px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mb-40px {
  margin-bottom: 40px !important;
}

.mt-45px {
  margin-top: 45px !important;
}

.mb-45px {
  margin-bottom: 45px !important;
}

.mt-50px {
  margin-top: 50px !important;
}

.mb-50px {
  margin-bottom: 50px !important;
}

.mt-55px {
  margin-top: 55px !important;
}

.mb-55px {
  margin-bottom: 55px !important;
}

.mt-60px {
  margin-top: 60px !important;
}

.mb-60px {
  margin-bottom: 60px !important;
}

.mt-65px {
  margin-top: 65px !important;
}

.mb-65px {
  margin-bottom: 65px !important;
}

.mt-70px {
  margin-top: 70px !important;
}

.mb-70px {
  margin-bottom: 70px !important;
}

.mt-75px {
  margin-top: 75px !important;
}

.mb-75px {
  margin-bottom: 75px !important;
}

.mt-80px {
  margin-top: 80px !important;
}

.mb-80px {
  margin-bottom: 80px !important;
}

.mt-85px {
  margin-top: 85px !important;
}

.mb-85px {
  margin-bottom: 85px !important;
}

.mt-90px {
  margin-top: 90px !important;
}

.mb-90px {
  margin-bottom: 90px !important;
}

.mt-95px {
  margin-top: 95px !important;
}

.mb-95px {
  margin-bottom: 95px !important;
}

.mt-100px {
  margin-top: 100px !important;
}

.mb-100px {
  margin-bottom: 100px !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-top-1px-s-2f2f2f {
  border-top: 1px solid #2f2f2f !important;
}

@media (min-width: 576px) {
  .d-sm-table-row {
    display: table-row !important;
  }
  .fixed-sm-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .order-sm-initial {
    -ms-flex-order: initial;
        order: initial;
  }
  .w-sm-0px {
    width: 0px !important;
  }
  .w-sm-0rem {
    width: 0rem !important;
  }
  .w-sm-1px {
    width: 1px !important;
  }
  .w-sm-1rem {
    width: 1rem !important;
  }
  .w-sm-2px {
    width: 2px !important;
  }
  .w-sm-2rem {
    width: 2rem !important;
  }
  .w-sm-3px {
    width: 3px !important;
  }
  .w-sm-3rem {
    width: 3rem !important;
  }
  .w-sm-4px {
    width: 4px !important;
  }
  .w-sm-4rem {
    width: 4rem !important;
  }
  .w-sm-5px {
    width: 5px !important;
  }
  .w-sm-5rem {
    width: 5rem !important;
  }
  .w-sm-6px {
    width: 6px !important;
  }
  .w-sm-6rem {
    width: 6rem !important;
  }
  .w-sm-7px {
    width: 7px !important;
  }
  .w-sm-7rem {
    width: 7rem !important;
  }
  .w-sm-8px {
    width: 8px !important;
  }
  .w-sm-8rem {
    width: 8rem !important;
  }
  .w-sm-9px {
    width: 9px !important;
  }
  .w-sm-9rem {
    width: 9rem !important;
  }
  .w-sm-10px {
    width: 10px !important;
  }
  .w-sm-10rem {
    width: 10rem !important;
  }
  .w-sm-11px {
    width: 11px !important;
  }
  .w-sm-11rem {
    width: 11rem !important;
  }
  .w-sm-12px {
    width: 12px !important;
  }
  .w-sm-12rem {
    width: 12rem !important;
  }
  .w-sm-13px {
    width: 13px !important;
  }
  .w-sm-13rem {
    width: 13rem !important;
  }
  .w-sm-14px {
    width: 14px !important;
  }
  .w-sm-14rem {
    width: 14rem !important;
  }
  .w-sm-15px {
    width: 15px !important;
  }
  .w-sm-15rem {
    width: 15rem !important;
  }
  .w-sm-16px {
    width: 16px !important;
  }
  .w-sm-16rem {
    width: 16rem !important;
  }
  .w-sm-17px {
    width: 17px !important;
  }
  .w-sm-17rem {
    width: 17rem !important;
  }
  .w-sm-18px {
    width: 18px !important;
  }
  .w-sm-18rem {
    width: 18rem !important;
  }
  .w-sm-19px {
    width: 19px !important;
  }
  .w-sm-19rem {
    width: 19rem !important;
  }
  .w-sm-20px {
    width: 20px !important;
  }
  .w-sm-20rem {
    width: 20rem !important;
  }
  .w-sm-21px {
    width: 21px !important;
  }
  .w-sm-21rem {
    width: 21rem !important;
  }
  .w-sm-22px {
    width: 22px !important;
  }
  .w-sm-22rem {
    width: 22rem !important;
  }
  .w-sm-23px {
    width: 23px !important;
  }
  .w-sm-23rem {
    width: 23rem !important;
  }
  .w-sm-24px {
    width: 24px !important;
  }
  .w-sm-24rem {
    width: 24rem !important;
  }
  .w-sm-25px {
    width: 25px !important;
  }
  .w-sm-25rem {
    width: 25rem !important;
  }
  .w-sm-26px {
    width: 26px !important;
  }
  .w-sm-26rem {
    width: 26rem !important;
  }
  .w-sm-27px {
    width: 27px !important;
  }
  .w-sm-27rem {
    width: 27rem !important;
  }
  .w-sm-28px {
    width: 28px !important;
  }
  .w-sm-28rem {
    width: 28rem !important;
  }
  .w-sm-29px {
    width: 29px !important;
  }
  .w-sm-29rem {
    width: 29rem !important;
  }
  .w-sm-30px {
    width: 30px !important;
  }
  .w-sm-30rem {
    width: 30rem !important;
  }
  .w-sm-31px {
    width: 31px !important;
  }
  .w-sm-31rem {
    width: 31rem !important;
  }
  .w-sm-32px {
    width: 32px !important;
  }
  .w-sm-32rem {
    width: 32rem !important;
  }
  .w-sm-33px {
    width: 33px !important;
  }
  .w-sm-33rem {
    width: 33rem !important;
  }
  .w-sm-34px {
    width: 34px !important;
  }
  .w-sm-34rem {
    width: 34rem !important;
  }
  .w-sm-35px {
    width: 35px !important;
  }
  .w-sm-35rem {
    width: 35rem !important;
  }
  .w-sm-36px {
    width: 36px !important;
  }
  .w-sm-36rem {
    width: 36rem !important;
  }
  .w-sm-37px {
    width: 37px !important;
  }
  .w-sm-37rem {
    width: 37rem !important;
  }
  .w-sm-38px {
    width: 38px !important;
  }
  .w-sm-38rem {
    width: 38rem !important;
  }
  .w-sm-39px {
    width: 39px !important;
  }
  .w-sm-39rem {
    width: 39rem !important;
  }
  .w-sm-40px {
    width: 40px !important;
  }
  .w-sm-40rem {
    width: 40rem !important;
  }
  .w-sm-41px {
    width: 41px !important;
  }
  .w-sm-41rem {
    width: 41rem !important;
  }
  .w-sm-42px {
    width: 42px !important;
  }
  .w-sm-42rem {
    width: 42rem !important;
  }
  .w-sm-43px {
    width: 43px !important;
  }
  .w-sm-43rem {
    width: 43rem !important;
  }
  .w-sm-44px {
    width: 44px !important;
  }
  .w-sm-44rem {
    width: 44rem !important;
  }
  .w-sm-45px {
    width: 45px !important;
  }
  .w-sm-45rem {
    width: 45rem !important;
  }
  .w-sm-46px {
    width: 46px !important;
  }
  .w-sm-46rem {
    width: 46rem !important;
  }
  .w-sm-47px {
    width: 47px !important;
  }
  .w-sm-47rem {
    width: 47rem !important;
  }
  .w-sm-48px {
    width: 48px !important;
  }
  .w-sm-48rem {
    width: 48rem !important;
  }
  .w-sm-49px {
    width: 49px !important;
  }
  .w-sm-49rem {
    width: 49rem !important;
  }
  .w-sm-50px {
    width: 50px !important;
  }
  .w-sm-50rem {
    width: 50rem !important;
  }
  .w-sm-51px {
    width: 51px !important;
  }
  .w-sm-51rem {
    width: 51rem !important;
  }
  .w-sm-52px {
    width: 52px !important;
  }
  .w-sm-52rem {
    width: 52rem !important;
  }
  .w-sm-53px {
    width: 53px !important;
  }
  .w-sm-53rem {
    width: 53rem !important;
  }
  .w-sm-54px {
    width: 54px !important;
  }
  .w-sm-54rem {
    width: 54rem !important;
  }
  .w-sm-55px {
    width: 55px !important;
  }
  .w-sm-55rem {
    width: 55rem !important;
  }
  .w-sm-56px {
    width: 56px !important;
  }
  .w-sm-56rem {
    width: 56rem !important;
  }
  .w-sm-57px {
    width: 57px !important;
  }
  .w-sm-57rem {
    width: 57rem !important;
  }
  .w-sm-58px {
    width: 58px !important;
  }
  .w-sm-58rem {
    width: 58rem !important;
  }
  .w-sm-59px {
    width: 59px !important;
  }
  .w-sm-59rem {
    width: 59rem !important;
  }
  .w-sm-60px {
    width: 60px !important;
  }
  .w-sm-60rem {
    width: 60rem !important;
  }
  .w-sm-61px {
    width: 61px !important;
  }
  .w-sm-61rem {
    width: 61rem !important;
  }
  .w-sm-62px {
    width: 62px !important;
  }
  .w-sm-62rem {
    width: 62rem !important;
  }
  .w-sm-63px {
    width: 63px !important;
  }
  .w-sm-63rem {
    width: 63rem !important;
  }
  .w-sm-64px {
    width: 64px !important;
  }
  .w-sm-64rem {
    width: 64rem !important;
  }
  .w-sm-65px {
    width: 65px !important;
  }
  .w-sm-65rem {
    width: 65rem !important;
  }
  .w-sm-66px {
    width: 66px !important;
  }
  .w-sm-66rem {
    width: 66rem !important;
  }
  .w-sm-67px {
    width: 67px !important;
  }
  .w-sm-67rem {
    width: 67rem !important;
  }
  .w-sm-68px {
    width: 68px !important;
  }
  .w-sm-68rem {
    width: 68rem !important;
  }
  .w-sm-69px {
    width: 69px !important;
  }
  .w-sm-69rem {
    width: 69rem !important;
  }
  .w-sm-70px {
    width: 70px !important;
  }
  .w-sm-70rem {
    width: 70rem !important;
  }
  .w-sm-71px {
    width: 71px !important;
  }
  .w-sm-71rem {
    width: 71rem !important;
  }
  .w-sm-72px {
    width: 72px !important;
  }
  .w-sm-72rem {
    width: 72rem !important;
  }
  .w-sm-73px {
    width: 73px !important;
  }
  .w-sm-73rem {
    width: 73rem !important;
  }
  .w-sm-74px {
    width: 74px !important;
  }
  .w-sm-74rem {
    width: 74rem !important;
  }
  .w-sm-75px {
    width: 75px !important;
  }
  .w-sm-75rem {
    width: 75rem !important;
  }
  .w-sm-76px {
    width: 76px !important;
  }
  .w-sm-76rem {
    width: 76rem !important;
  }
  .w-sm-77px {
    width: 77px !important;
  }
  .w-sm-77rem {
    width: 77rem !important;
  }
  .w-sm-78px {
    width: 78px !important;
  }
  .w-sm-78rem {
    width: 78rem !important;
  }
  .w-sm-79px {
    width: 79px !important;
  }
  .w-sm-79rem {
    width: 79rem !important;
  }
  .w-sm-80px {
    width: 80px !important;
  }
  .w-sm-80rem {
    width: 80rem !important;
  }
  .w-sm-81px {
    width: 81px !important;
  }
  .w-sm-81rem {
    width: 81rem !important;
  }
  .w-sm-82px {
    width: 82px !important;
  }
  .w-sm-82rem {
    width: 82rem !important;
  }
  .w-sm-83px {
    width: 83px !important;
  }
  .w-sm-83rem {
    width: 83rem !important;
  }
  .w-sm-84px {
    width: 84px !important;
  }
  .w-sm-84rem {
    width: 84rem !important;
  }
  .w-sm-85px {
    width: 85px !important;
  }
  .w-sm-85rem {
    width: 85rem !important;
  }
  .w-sm-86px {
    width: 86px !important;
  }
  .w-sm-86rem {
    width: 86rem !important;
  }
  .w-sm-87px {
    width: 87px !important;
  }
  .w-sm-87rem {
    width: 87rem !important;
  }
  .w-sm-88px {
    width: 88px !important;
  }
  .w-sm-88rem {
    width: 88rem !important;
  }
  .w-sm-89px {
    width: 89px !important;
  }
  .w-sm-89rem {
    width: 89rem !important;
  }
  .w-sm-90px {
    width: 90px !important;
  }
  .w-sm-90rem {
    width: 90rem !important;
  }
  .w-sm-91px {
    width: 91px !important;
  }
  .w-sm-91rem {
    width: 91rem !important;
  }
  .w-sm-92px {
    width: 92px !important;
  }
  .w-sm-92rem {
    width: 92rem !important;
  }
  .w-sm-93px {
    width: 93px !important;
  }
  .w-sm-93rem {
    width: 93rem !important;
  }
  .w-sm-94px {
    width: 94px !important;
  }
  .w-sm-94rem {
    width: 94rem !important;
  }
  .w-sm-95px {
    width: 95px !important;
  }
  .w-sm-95rem {
    width: 95rem !important;
  }
  .w-sm-96px {
    width: 96px !important;
  }
  .w-sm-96rem {
    width: 96rem !important;
  }
  .w-sm-97px {
    width: 97px !important;
  }
  .w-sm-97rem {
    width: 97rem !important;
  }
  .w-sm-98px {
    width: 98px !important;
  }
  .w-sm-98rem {
    width: 98rem !important;
  }
  .w-sm-99px {
    width: 99px !important;
  }
  .w-sm-99rem {
    width: 99rem !important;
  }
  .w-sm-100px {
    width: 100px !important;
  }
  .w-sm-100rem {
    width: 100rem !important;
  }
  .h-sm-0px {
    height: 0px !important;
  }
  .h-sm-0rem {
    height: 0rem !important;
  }
  .h-sm-1px {
    height: 1px !important;
  }
  .h-sm-1rem {
    height: 1rem !important;
  }
  .h-sm-2px {
    height: 2px !important;
  }
  .h-sm-2rem {
    height: 2rem !important;
  }
  .h-sm-3px {
    height: 3px !important;
  }
  .h-sm-3rem {
    height: 3rem !important;
  }
  .h-sm-4px {
    height: 4px !important;
  }
  .h-sm-4rem {
    height: 4rem !important;
  }
  .h-sm-5px {
    height: 5px !important;
  }
  .h-sm-5rem {
    height: 5rem !important;
  }
  .h-sm-6px {
    height: 6px !important;
  }
  .h-sm-6rem {
    height: 6rem !important;
  }
  .h-sm-7px {
    height: 7px !important;
  }
  .h-sm-7rem {
    height: 7rem !important;
  }
  .h-sm-8px {
    height: 8px !important;
  }
  .h-sm-8rem {
    height: 8rem !important;
  }
  .h-sm-9px {
    height: 9px !important;
  }
  .h-sm-9rem {
    height: 9rem !important;
  }
  .h-sm-10px {
    height: 10px !important;
  }
  .h-sm-10rem {
    height: 10rem !important;
  }
  .h-sm-11px {
    height: 11px !important;
  }
  .h-sm-11rem {
    height: 11rem !important;
  }
  .h-sm-12px {
    height: 12px !important;
  }
  .h-sm-12rem {
    height: 12rem !important;
  }
  .h-sm-13px {
    height: 13px !important;
  }
  .h-sm-13rem {
    height: 13rem !important;
  }
  .h-sm-14px {
    height: 14px !important;
  }
  .h-sm-14rem {
    height: 14rem !important;
  }
  .h-sm-15px {
    height: 15px !important;
  }
  .h-sm-15rem {
    height: 15rem !important;
  }
  .h-sm-16px {
    height: 16px !important;
  }
  .h-sm-16rem {
    height: 16rem !important;
  }
  .h-sm-17px {
    height: 17px !important;
  }
  .h-sm-17rem {
    height: 17rem !important;
  }
  .h-sm-18px {
    height: 18px !important;
  }
  .h-sm-18rem {
    height: 18rem !important;
  }
  .h-sm-19px {
    height: 19px !important;
  }
  .h-sm-19rem {
    height: 19rem !important;
  }
  .h-sm-20px {
    height: 20px !important;
  }
  .h-sm-20rem {
    height: 20rem !important;
  }
  .h-sm-21px {
    height: 21px !important;
  }
  .h-sm-21rem {
    height: 21rem !important;
  }
  .h-sm-22px {
    height: 22px !important;
  }
  .h-sm-22rem {
    height: 22rem !important;
  }
  .h-sm-23px {
    height: 23px !important;
  }
  .h-sm-23rem {
    height: 23rem !important;
  }
  .h-sm-24px {
    height: 24px !important;
  }
  .h-sm-24rem {
    height: 24rem !important;
  }
  .h-sm-25px {
    height: 25px !important;
  }
  .h-sm-25rem {
    height: 25rem !important;
  }
  .h-sm-26px {
    height: 26px !important;
  }
  .h-sm-26rem {
    height: 26rem !important;
  }
  .h-sm-27px {
    height: 27px !important;
  }
  .h-sm-27rem {
    height: 27rem !important;
  }
  .h-sm-28px {
    height: 28px !important;
  }
  .h-sm-28rem {
    height: 28rem !important;
  }
  .h-sm-29px {
    height: 29px !important;
  }
  .h-sm-29rem {
    height: 29rem !important;
  }
  .h-sm-30px {
    height: 30px !important;
  }
  .h-sm-30rem {
    height: 30rem !important;
  }
  .h-sm-31px {
    height: 31px !important;
  }
  .h-sm-31rem {
    height: 31rem !important;
  }
  .h-sm-32px {
    height: 32px !important;
  }
  .h-sm-32rem {
    height: 32rem !important;
  }
  .h-sm-33px {
    height: 33px !important;
  }
  .h-sm-33rem {
    height: 33rem !important;
  }
  .h-sm-34px {
    height: 34px !important;
  }
  .h-sm-34rem {
    height: 34rem !important;
  }
  .h-sm-35px {
    height: 35px !important;
  }
  .h-sm-35rem {
    height: 35rem !important;
  }
  .h-sm-36px {
    height: 36px !important;
  }
  .h-sm-36rem {
    height: 36rem !important;
  }
  .h-sm-37px {
    height: 37px !important;
  }
  .h-sm-37rem {
    height: 37rem !important;
  }
  .h-sm-38px {
    height: 38px !important;
  }
  .h-sm-38rem {
    height: 38rem !important;
  }
  .h-sm-39px {
    height: 39px !important;
  }
  .h-sm-39rem {
    height: 39rem !important;
  }
  .h-sm-40px {
    height: 40px !important;
  }
  .h-sm-40rem {
    height: 40rem !important;
  }
  .h-sm-41px {
    height: 41px !important;
  }
  .h-sm-41rem {
    height: 41rem !important;
  }
  .h-sm-42px {
    height: 42px !important;
  }
  .h-sm-42rem {
    height: 42rem !important;
  }
  .h-sm-43px {
    height: 43px !important;
  }
  .h-sm-43rem {
    height: 43rem !important;
  }
  .h-sm-44px {
    height: 44px !important;
  }
  .h-sm-44rem {
    height: 44rem !important;
  }
  .h-sm-45px {
    height: 45px !important;
  }
  .h-sm-45rem {
    height: 45rem !important;
  }
  .h-sm-46px {
    height: 46px !important;
  }
  .h-sm-46rem {
    height: 46rem !important;
  }
  .h-sm-47px {
    height: 47px !important;
  }
  .h-sm-47rem {
    height: 47rem !important;
  }
  .h-sm-48px {
    height: 48px !important;
  }
  .h-sm-48rem {
    height: 48rem !important;
  }
  .h-sm-49px {
    height: 49px !important;
  }
  .h-sm-49rem {
    height: 49rem !important;
  }
  .h-sm-50px {
    height: 50px !important;
  }
  .h-sm-50rem {
    height: 50rem !important;
  }
  .h-sm-51px {
    height: 51px !important;
  }
  .h-sm-51rem {
    height: 51rem !important;
  }
  .h-sm-52px {
    height: 52px !important;
  }
  .h-sm-52rem {
    height: 52rem !important;
  }
  .h-sm-53px {
    height: 53px !important;
  }
  .h-sm-53rem {
    height: 53rem !important;
  }
  .h-sm-54px {
    height: 54px !important;
  }
  .h-sm-54rem {
    height: 54rem !important;
  }
  .h-sm-55px {
    height: 55px !important;
  }
  .h-sm-55rem {
    height: 55rem !important;
  }
  .h-sm-56px {
    height: 56px !important;
  }
  .h-sm-56rem {
    height: 56rem !important;
  }
  .h-sm-57px {
    height: 57px !important;
  }
  .h-sm-57rem {
    height: 57rem !important;
  }
  .h-sm-58px {
    height: 58px !important;
  }
  .h-sm-58rem {
    height: 58rem !important;
  }
  .h-sm-59px {
    height: 59px !important;
  }
  .h-sm-59rem {
    height: 59rem !important;
  }
  .h-sm-60px {
    height: 60px !important;
  }
  .h-sm-60rem {
    height: 60rem !important;
  }
  .h-sm-61px {
    height: 61px !important;
  }
  .h-sm-61rem {
    height: 61rem !important;
  }
  .h-sm-62px {
    height: 62px !important;
  }
  .h-sm-62rem {
    height: 62rem !important;
  }
  .h-sm-63px {
    height: 63px !important;
  }
  .h-sm-63rem {
    height: 63rem !important;
  }
  .h-sm-64px {
    height: 64px !important;
  }
  .h-sm-64rem {
    height: 64rem !important;
  }
  .h-sm-65px {
    height: 65px !important;
  }
  .h-sm-65rem {
    height: 65rem !important;
  }
  .h-sm-66px {
    height: 66px !important;
  }
  .h-sm-66rem {
    height: 66rem !important;
  }
  .h-sm-67px {
    height: 67px !important;
  }
  .h-sm-67rem {
    height: 67rem !important;
  }
  .h-sm-68px {
    height: 68px !important;
  }
  .h-sm-68rem {
    height: 68rem !important;
  }
  .h-sm-69px {
    height: 69px !important;
  }
  .h-sm-69rem {
    height: 69rem !important;
  }
  .h-sm-70px {
    height: 70px !important;
  }
  .h-sm-70rem {
    height: 70rem !important;
  }
  .h-sm-71px {
    height: 71px !important;
  }
  .h-sm-71rem {
    height: 71rem !important;
  }
  .h-sm-72px {
    height: 72px !important;
  }
  .h-sm-72rem {
    height: 72rem !important;
  }
  .h-sm-73px {
    height: 73px !important;
  }
  .h-sm-73rem {
    height: 73rem !important;
  }
  .h-sm-74px {
    height: 74px !important;
  }
  .h-sm-74rem {
    height: 74rem !important;
  }
  .h-sm-75px {
    height: 75px !important;
  }
  .h-sm-75rem {
    height: 75rem !important;
  }
  .h-sm-76px {
    height: 76px !important;
  }
  .h-sm-76rem {
    height: 76rem !important;
  }
  .h-sm-77px {
    height: 77px !important;
  }
  .h-sm-77rem {
    height: 77rem !important;
  }
  .h-sm-78px {
    height: 78px !important;
  }
  .h-sm-78rem {
    height: 78rem !important;
  }
  .h-sm-79px {
    height: 79px !important;
  }
  .h-sm-79rem {
    height: 79rem !important;
  }
  .h-sm-80px {
    height: 80px !important;
  }
  .h-sm-80rem {
    height: 80rem !important;
  }
  .h-sm-81px {
    height: 81px !important;
  }
  .h-sm-81rem {
    height: 81rem !important;
  }
  .h-sm-82px {
    height: 82px !important;
  }
  .h-sm-82rem {
    height: 82rem !important;
  }
  .h-sm-83px {
    height: 83px !important;
  }
  .h-sm-83rem {
    height: 83rem !important;
  }
  .h-sm-84px {
    height: 84px !important;
  }
  .h-sm-84rem {
    height: 84rem !important;
  }
  .h-sm-85px {
    height: 85px !important;
  }
  .h-sm-85rem {
    height: 85rem !important;
  }
  .h-sm-86px {
    height: 86px !important;
  }
  .h-sm-86rem {
    height: 86rem !important;
  }
  .h-sm-87px {
    height: 87px !important;
  }
  .h-sm-87rem {
    height: 87rem !important;
  }
  .h-sm-88px {
    height: 88px !important;
  }
  .h-sm-88rem {
    height: 88rem !important;
  }
  .h-sm-89px {
    height: 89px !important;
  }
  .h-sm-89rem {
    height: 89rem !important;
  }
  .h-sm-90px {
    height: 90px !important;
  }
  .h-sm-90rem {
    height: 90rem !important;
  }
  .h-sm-91px {
    height: 91px !important;
  }
  .h-sm-91rem {
    height: 91rem !important;
  }
  .h-sm-92px {
    height: 92px !important;
  }
  .h-sm-92rem {
    height: 92rem !important;
  }
  .h-sm-93px {
    height: 93px !important;
  }
  .h-sm-93rem {
    height: 93rem !important;
  }
  .h-sm-94px {
    height: 94px !important;
  }
  .h-sm-94rem {
    height: 94rem !important;
  }
  .h-sm-95px {
    height: 95px !important;
  }
  .h-sm-95rem {
    height: 95rem !important;
  }
  .h-sm-96px {
    height: 96px !important;
  }
  .h-sm-96rem {
    height: 96rem !important;
  }
  .h-sm-97px {
    height: 97px !important;
  }
  .h-sm-97rem {
    height: 97rem !important;
  }
  .h-sm-98px {
    height: 98px !important;
  }
  .h-sm-98rem {
    height: 98rem !important;
  }
  .h-sm-99px {
    height: 99px !important;
  }
  .h-sm-99rem {
    height: 99rem !important;
  }
  .h-sm-100px {
    height: 100px !important;
  }
  .h-sm-100rem {
    height: 100rem !important;
  }
  .t-sm-0px {
    top: 0px !important;
  }
  .t-sm-0rem {
    top: 0rem !important;
  }
  .t-sm-1px {
    top: 1px !important;
  }
  .t-sm-1rem {
    top: 1rem !important;
  }
  .t-sm-2px {
    top: 2px !important;
  }
  .t-sm-2rem {
    top: 2rem !important;
  }
  .t-sm-3px {
    top: 3px !important;
  }
  .t-sm-3rem {
    top: 3rem !important;
  }
  .t-sm-4px {
    top: 4px !important;
  }
  .t-sm-4rem {
    top: 4rem !important;
  }
  .t-sm-5px {
    top: 5px !important;
  }
  .t-sm-5rem {
    top: 5rem !important;
  }
  .t-sm-6px {
    top: 6px !important;
  }
  .t-sm-6rem {
    top: 6rem !important;
  }
  .t-sm-7px {
    top: 7px !important;
  }
  .t-sm-7rem {
    top: 7rem !important;
  }
  .t-sm-8px {
    top: 8px !important;
  }
  .t-sm-8rem {
    top: 8rem !important;
  }
  .t-sm-9px {
    top: 9px !important;
  }
  .t-sm-9rem {
    top: 9rem !important;
  }
  .t-sm-10px {
    top: 10px !important;
  }
  .t-sm-10rem {
    top: 10rem !important;
  }
  .t-sm-11px {
    top: 11px !important;
  }
  .t-sm-11rem {
    top: 11rem !important;
  }
  .t-sm-12px {
    top: 12px !important;
  }
  .t-sm-12rem {
    top: 12rem !important;
  }
  .t-sm-13px {
    top: 13px !important;
  }
  .t-sm-13rem {
    top: 13rem !important;
  }
  .t-sm-14px {
    top: 14px !important;
  }
  .t-sm-14rem {
    top: 14rem !important;
  }
  .t-sm-15px {
    top: 15px !important;
  }
  .t-sm-15rem {
    top: 15rem !important;
  }
  .t-sm-16px {
    top: 16px !important;
  }
  .t-sm-16rem {
    top: 16rem !important;
  }
  .t-sm-17px {
    top: 17px !important;
  }
  .t-sm-17rem {
    top: 17rem !important;
  }
  .t-sm-18px {
    top: 18px !important;
  }
  .t-sm-18rem {
    top: 18rem !important;
  }
  .t-sm-19px {
    top: 19px !important;
  }
  .t-sm-19rem {
    top: 19rem !important;
  }
  .t-sm-20px {
    top: 20px !important;
  }
  .t-sm-20rem {
    top: 20rem !important;
  }
  .t-sm-21px {
    top: 21px !important;
  }
  .t-sm-21rem {
    top: 21rem !important;
  }
  .t-sm-22px {
    top: 22px !important;
  }
  .t-sm-22rem {
    top: 22rem !important;
  }
  .t-sm-23px {
    top: 23px !important;
  }
  .t-sm-23rem {
    top: 23rem !important;
  }
  .t-sm-24px {
    top: 24px !important;
  }
  .t-sm-24rem {
    top: 24rem !important;
  }
  .t-sm-25px {
    top: 25px !important;
  }
  .t-sm-25rem {
    top: 25rem !important;
  }
  .t-sm-26px {
    top: 26px !important;
  }
  .t-sm-26rem {
    top: 26rem !important;
  }
  .t-sm-27px {
    top: 27px !important;
  }
  .t-sm-27rem {
    top: 27rem !important;
  }
  .t-sm-28px {
    top: 28px !important;
  }
  .t-sm-28rem {
    top: 28rem !important;
  }
  .t-sm-29px {
    top: 29px !important;
  }
  .t-sm-29rem {
    top: 29rem !important;
  }
  .t-sm-30px {
    top: 30px !important;
  }
  .t-sm-30rem {
    top: 30rem !important;
  }
  .t-sm-31px {
    top: 31px !important;
  }
  .t-sm-31rem {
    top: 31rem !important;
  }
  .t-sm-32px {
    top: 32px !important;
  }
  .t-sm-32rem {
    top: 32rem !important;
  }
  .t-sm-33px {
    top: 33px !important;
  }
  .t-sm-33rem {
    top: 33rem !important;
  }
  .t-sm-34px {
    top: 34px !important;
  }
  .t-sm-34rem {
    top: 34rem !important;
  }
  .t-sm-35px {
    top: 35px !important;
  }
  .t-sm-35rem {
    top: 35rem !important;
  }
  .t-sm-36px {
    top: 36px !important;
  }
  .t-sm-36rem {
    top: 36rem !important;
  }
  .t-sm-37px {
    top: 37px !important;
  }
  .t-sm-37rem {
    top: 37rem !important;
  }
  .t-sm-38px {
    top: 38px !important;
  }
  .t-sm-38rem {
    top: 38rem !important;
  }
  .t-sm-39px {
    top: 39px !important;
  }
  .t-sm-39rem {
    top: 39rem !important;
  }
  .t-sm-40px {
    top: 40px !important;
  }
  .t-sm-40rem {
    top: 40rem !important;
  }
  .t-sm-41px {
    top: 41px !important;
  }
  .t-sm-41rem {
    top: 41rem !important;
  }
  .t-sm-42px {
    top: 42px !important;
  }
  .t-sm-42rem {
    top: 42rem !important;
  }
  .t-sm-43px {
    top: 43px !important;
  }
  .t-sm-43rem {
    top: 43rem !important;
  }
  .t-sm-44px {
    top: 44px !important;
  }
  .t-sm-44rem {
    top: 44rem !important;
  }
  .t-sm-45px {
    top: 45px !important;
  }
  .t-sm-45rem {
    top: 45rem !important;
  }
  .t-sm-46px {
    top: 46px !important;
  }
  .t-sm-46rem {
    top: 46rem !important;
  }
  .t-sm-47px {
    top: 47px !important;
  }
  .t-sm-47rem {
    top: 47rem !important;
  }
  .t-sm-48px {
    top: 48px !important;
  }
  .t-sm-48rem {
    top: 48rem !important;
  }
  .t-sm-49px {
    top: 49px !important;
  }
  .t-sm-49rem {
    top: 49rem !important;
  }
  .t-sm-50px {
    top: 50px !important;
  }
  .t-sm-50rem {
    top: 50rem !important;
  }
  .t-sm-51px {
    top: 51px !important;
  }
  .t-sm-51rem {
    top: 51rem !important;
  }
  .t-sm-52px {
    top: 52px !important;
  }
  .t-sm-52rem {
    top: 52rem !important;
  }
  .t-sm-53px {
    top: 53px !important;
  }
  .t-sm-53rem {
    top: 53rem !important;
  }
  .t-sm-54px {
    top: 54px !important;
  }
  .t-sm-54rem {
    top: 54rem !important;
  }
  .t-sm-55px {
    top: 55px !important;
  }
  .t-sm-55rem {
    top: 55rem !important;
  }
  .t-sm-56px {
    top: 56px !important;
  }
  .t-sm-56rem {
    top: 56rem !important;
  }
  .t-sm-57px {
    top: 57px !important;
  }
  .t-sm-57rem {
    top: 57rem !important;
  }
  .t-sm-58px {
    top: 58px !important;
  }
  .t-sm-58rem {
    top: 58rem !important;
  }
  .t-sm-59px {
    top: 59px !important;
  }
  .t-sm-59rem {
    top: 59rem !important;
  }
  .t-sm-60px {
    top: 60px !important;
  }
  .t-sm-60rem {
    top: 60rem !important;
  }
  .t-sm-61px {
    top: 61px !important;
  }
  .t-sm-61rem {
    top: 61rem !important;
  }
  .t-sm-62px {
    top: 62px !important;
  }
  .t-sm-62rem {
    top: 62rem !important;
  }
  .t-sm-63px {
    top: 63px !important;
  }
  .t-sm-63rem {
    top: 63rem !important;
  }
  .t-sm-64px {
    top: 64px !important;
  }
  .t-sm-64rem {
    top: 64rem !important;
  }
  .t-sm-65px {
    top: 65px !important;
  }
  .t-sm-65rem {
    top: 65rem !important;
  }
  .t-sm-66px {
    top: 66px !important;
  }
  .t-sm-66rem {
    top: 66rem !important;
  }
  .t-sm-67px {
    top: 67px !important;
  }
  .t-sm-67rem {
    top: 67rem !important;
  }
  .t-sm-68px {
    top: 68px !important;
  }
  .t-sm-68rem {
    top: 68rem !important;
  }
  .t-sm-69px {
    top: 69px !important;
  }
  .t-sm-69rem {
    top: 69rem !important;
  }
  .t-sm-70px {
    top: 70px !important;
  }
  .t-sm-70rem {
    top: 70rem !important;
  }
  .t-sm-71px {
    top: 71px !important;
  }
  .t-sm-71rem {
    top: 71rem !important;
  }
  .t-sm-72px {
    top: 72px !important;
  }
  .t-sm-72rem {
    top: 72rem !important;
  }
  .t-sm-73px {
    top: 73px !important;
  }
  .t-sm-73rem {
    top: 73rem !important;
  }
  .t-sm-74px {
    top: 74px !important;
  }
  .t-sm-74rem {
    top: 74rem !important;
  }
  .t-sm-75px {
    top: 75px !important;
  }
  .t-sm-75rem {
    top: 75rem !important;
  }
  .t-sm-76px {
    top: 76px !important;
  }
  .t-sm-76rem {
    top: 76rem !important;
  }
  .t-sm-77px {
    top: 77px !important;
  }
  .t-sm-77rem {
    top: 77rem !important;
  }
  .t-sm-78px {
    top: 78px !important;
  }
  .t-sm-78rem {
    top: 78rem !important;
  }
  .t-sm-79px {
    top: 79px !important;
  }
  .t-sm-79rem {
    top: 79rem !important;
  }
  .t-sm-80px {
    top: 80px !important;
  }
  .t-sm-80rem {
    top: 80rem !important;
  }
  .t-sm-81px {
    top: 81px !important;
  }
  .t-sm-81rem {
    top: 81rem !important;
  }
  .t-sm-82px {
    top: 82px !important;
  }
  .t-sm-82rem {
    top: 82rem !important;
  }
  .t-sm-83px {
    top: 83px !important;
  }
  .t-sm-83rem {
    top: 83rem !important;
  }
  .t-sm-84px {
    top: 84px !important;
  }
  .t-sm-84rem {
    top: 84rem !important;
  }
  .t-sm-85px {
    top: 85px !important;
  }
  .t-sm-85rem {
    top: 85rem !important;
  }
  .t-sm-86px {
    top: 86px !important;
  }
  .t-sm-86rem {
    top: 86rem !important;
  }
  .t-sm-87px {
    top: 87px !important;
  }
  .t-sm-87rem {
    top: 87rem !important;
  }
  .t-sm-88px {
    top: 88px !important;
  }
  .t-sm-88rem {
    top: 88rem !important;
  }
  .t-sm-89px {
    top: 89px !important;
  }
  .t-sm-89rem {
    top: 89rem !important;
  }
  .t-sm-90px {
    top: 90px !important;
  }
  .t-sm-90rem {
    top: 90rem !important;
  }
  .t-sm-91px {
    top: 91px !important;
  }
  .t-sm-91rem {
    top: 91rem !important;
  }
  .t-sm-92px {
    top: 92px !important;
  }
  .t-sm-92rem {
    top: 92rem !important;
  }
  .t-sm-93px {
    top: 93px !important;
  }
  .t-sm-93rem {
    top: 93rem !important;
  }
  .t-sm-94px {
    top: 94px !important;
  }
  .t-sm-94rem {
    top: 94rem !important;
  }
  .t-sm-95px {
    top: 95px !important;
  }
  .t-sm-95rem {
    top: 95rem !important;
  }
  .t-sm-96px {
    top: 96px !important;
  }
  .t-sm-96rem {
    top: 96rem !important;
  }
  .t-sm-97px {
    top: 97px !important;
  }
  .t-sm-97rem {
    top: 97rem !important;
  }
  .t-sm-98px {
    top: 98px !important;
  }
  .t-sm-98rem {
    top: 98rem !important;
  }
  .t-sm-99px {
    top: 99px !important;
  }
  .t-sm-99rem {
    top: 99rem !important;
  }
  .t-sm-100px {
    top: 100px !important;
  }
  .t-sm-100rem {
    top: 100rem !important;
  }
  .l-sm-0px {
    left: 0px !important;
  }
  .l-sm-0rem {
    left: 0rem !important;
  }
  .l-sm-1px {
    left: 1px !important;
  }
  .l-sm-1rem {
    left: 1rem !important;
  }
  .l-sm-2px {
    left: 2px !important;
  }
  .l-sm-2rem {
    left: 2rem !important;
  }
  .l-sm-3px {
    left: 3px !important;
  }
  .l-sm-3rem {
    left: 3rem !important;
  }
  .l-sm-4px {
    left: 4px !important;
  }
  .l-sm-4rem {
    left: 4rem !important;
  }
  .l-sm-5px {
    left: 5px !important;
  }
  .l-sm-5rem {
    left: 5rem !important;
  }
  .l-sm-6px {
    left: 6px !important;
  }
  .l-sm-6rem {
    left: 6rem !important;
  }
  .l-sm-7px {
    left: 7px !important;
  }
  .l-sm-7rem {
    left: 7rem !important;
  }
  .l-sm-8px {
    left: 8px !important;
  }
  .l-sm-8rem {
    left: 8rem !important;
  }
  .l-sm-9px {
    left: 9px !important;
  }
  .l-sm-9rem {
    left: 9rem !important;
  }
  .l-sm-10px {
    left: 10px !important;
  }
  .l-sm-10rem {
    left: 10rem !important;
  }
  .l-sm-11px {
    left: 11px !important;
  }
  .l-sm-11rem {
    left: 11rem !important;
  }
  .l-sm-12px {
    left: 12px !important;
  }
  .l-sm-12rem {
    left: 12rem !important;
  }
  .l-sm-13px {
    left: 13px !important;
  }
  .l-sm-13rem {
    left: 13rem !important;
  }
  .l-sm-14px {
    left: 14px !important;
  }
  .l-sm-14rem {
    left: 14rem !important;
  }
  .l-sm-15px {
    left: 15px !important;
  }
  .l-sm-15rem {
    left: 15rem !important;
  }
  .l-sm-16px {
    left: 16px !important;
  }
  .l-sm-16rem {
    left: 16rem !important;
  }
  .l-sm-17px {
    left: 17px !important;
  }
  .l-sm-17rem {
    left: 17rem !important;
  }
  .l-sm-18px {
    left: 18px !important;
  }
  .l-sm-18rem {
    left: 18rem !important;
  }
  .l-sm-19px {
    left: 19px !important;
  }
  .l-sm-19rem {
    left: 19rem !important;
  }
  .l-sm-20px {
    left: 20px !important;
  }
  .l-sm-20rem {
    left: 20rem !important;
  }
  .l-sm-21px {
    left: 21px !important;
  }
  .l-sm-21rem {
    left: 21rem !important;
  }
  .l-sm-22px {
    left: 22px !important;
  }
  .l-sm-22rem {
    left: 22rem !important;
  }
  .l-sm-23px {
    left: 23px !important;
  }
  .l-sm-23rem {
    left: 23rem !important;
  }
  .l-sm-24px {
    left: 24px !important;
  }
  .l-sm-24rem {
    left: 24rem !important;
  }
  .l-sm-25px {
    left: 25px !important;
  }
  .l-sm-25rem {
    left: 25rem !important;
  }
  .l-sm-26px {
    left: 26px !important;
  }
  .l-sm-26rem {
    left: 26rem !important;
  }
  .l-sm-27px {
    left: 27px !important;
  }
  .l-sm-27rem {
    left: 27rem !important;
  }
  .l-sm-28px {
    left: 28px !important;
  }
  .l-sm-28rem {
    left: 28rem !important;
  }
  .l-sm-29px {
    left: 29px !important;
  }
  .l-sm-29rem {
    left: 29rem !important;
  }
  .l-sm-30px {
    left: 30px !important;
  }
  .l-sm-30rem {
    left: 30rem !important;
  }
  .l-sm-31px {
    left: 31px !important;
  }
  .l-sm-31rem {
    left: 31rem !important;
  }
  .l-sm-32px {
    left: 32px !important;
  }
  .l-sm-32rem {
    left: 32rem !important;
  }
  .l-sm-33px {
    left: 33px !important;
  }
  .l-sm-33rem {
    left: 33rem !important;
  }
  .l-sm-34px {
    left: 34px !important;
  }
  .l-sm-34rem {
    left: 34rem !important;
  }
  .l-sm-35px {
    left: 35px !important;
  }
  .l-sm-35rem {
    left: 35rem !important;
  }
  .l-sm-36px {
    left: 36px !important;
  }
  .l-sm-36rem {
    left: 36rem !important;
  }
  .l-sm-37px {
    left: 37px !important;
  }
  .l-sm-37rem {
    left: 37rem !important;
  }
  .l-sm-38px {
    left: 38px !important;
  }
  .l-sm-38rem {
    left: 38rem !important;
  }
  .l-sm-39px {
    left: 39px !important;
  }
  .l-sm-39rem {
    left: 39rem !important;
  }
  .l-sm-40px {
    left: 40px !important;
  }
  .l-sm-40rem {
    left: 40rem !important;
  }
  .l-sm-41px {
    left: 41px !important;
  }
  .l-sm-41rem {
    left: 41rem !important;
  }
  .l-sm-42px {
    left: 42px !important;
  }
  .l-sm-42rem {
    left: 42rem !important;
  }
  .l-sm-43px {
    left: 43px !important;
  }
  .l-sm-43rem {
    left: 43rem !important;
  }
  .l-sm-44px {
    left: 44px !important;
  }
  .l-sm-44rem {
    left: 44rem !important;
  }
  .l-sm-45px {
    left: 45px !important;
  }
  .l-sm-45rem {
    left: 45rem !important;
  }
  .l-sm-46px {
    left: 46px !important;
  }
  .l-sm-46rem {
    left: 46rem !important;
  }
  .l-sm-47px {
    left: 47px !important;
  }
  .l-sm-47rem {
    left: 47rem !important;
  }
  .l-sm-48px {
    left: 48px !important;
  }
  .l-sm-48rem {
    left: 48rem !important;
  }
  .l-sm-49px {
    left: 49px !important;
  }
  .l-sm-49rem {
    left: 49rem !important;
  }
  .l-sm-50px {
    left: 50px !important;
  }
  .l-sm-50rem {
    left: 50rem !important;
  }
  .l-sm-51px {
    left: 51px !important;
  }
  .l-sm-51rem {
    left: 51rem !important;
  }
  .l-sm-52px {
    left: 52px !important;
  }
  .l-sm-52rem {
    left: 52rem !important;
  }
  .l-sm-53px {
    left: 53px !important;
  }
  .l-sm-53rem {
    left: 53rem !important;
  }
  .l-sm-54px {
    left: 54px !important;
  }
  .l-sm-54rem {
    left: 54rem !important;
  }
  .l-sm-55px {
    left: 55px !important;
  }
  .l-sm-55rem {
    left: 55rem !important;
  }
  .l-sm-56px {
    left: 56px !important;
  }
  .l-sm-56rem {
    left: 56rem !important;
  }
  .l-sm-57px {
    left: 57px !important;
  }
  .l-sm-57rem {
    left: 57rem !important;
  }
  .l-sm-58px {
    left: 58px !important;
  }
  .l-sm-58rem {
    left: 58rem !important;
  }
  .l-sm-59px {
    left: 59px !important;
  }
  .l-sm-59rem {
    left: 59rem !important;
  }
  .l-sm-60px {
    left: 60px !important;
  }
  .l-sm-60rem {
    left: 60rem !important;
  }
  .l-sm-61px {
    left: 61px !important;
  }
  .l-sm-61rem {
    left: 61rem !important;
  }
  .l-sm-62px {
    left: 62px !important;
  }
  .l-sm-62rem {
    left: 62rem !important;
  }
  .l-sm-63px {
    left: 63px !important;
  }
  .l-sm-63rem {
    left: 63rem !important;
  }
  .l-sm-64px {
    left: 64px !important;
  }
  .l-sm-64rem {
    left: 64rem !important;
  }
  .l-sm-65px {
    left: 65px !important;
  }
  .l-sm-65rem {
    left: 65rem !important;
  }
  .l-sm-66px {
    left: 66px !important;
  }
  .l-sm-66rem {
    left: 66rem !important;
  }
  .l-sm-67px {
    left: 67px !important;
  }
  .l-sm-67rem {
    left: 67rem !important;
  }
  .l-sm-68px {
    left: 68px !important;
  }
  .l-sm-68rem {
    left: 68rem !important;
  }
  .l-sm-69px {
    left: 69px !important;
  }
  .l-sm-69rem {
    left: 69rem !important;
  }
  .l-sm-70px {
    left: 70px !important;
  }
  .l-sm-70rem {
    left: 70rem !important;
  }
  .l-sm-71px {
    left: 71px !important;
  }
  .l-sm-71rem {
    left: 71rem !important;
  }
  .l-sm-72px {
    left: 72px !important;
  }
  .l-sm-72rem {
    left: 72rem !important;
  }
  .l-sm-73px {
    left: 73px !important;
  }
  .l-sm-73rem {
    left: 73rem !important;
  }
  .l-sm-74px {
    left: 74px !important;
  }
  .l-sm-74rem {
    left: 74rem !important;
  }
  .l-sm-75px {
    left: 75px !important;
  }
  .l-sm-75rem {
    left: 75rem !important;
  }
  .l-sm-76px {
    left: 76px !important;
  }
  .l-sm-76rem {
    left: 76rem !important;
  }
  .l-sm-77px {
    left: 77px !important;
  }
  .l-sm-77rem {
    left: 77rem !important;
  }
  .l-sm-78px {
    left: 78px !important;
  }
  .l-sm-78rem {
    left: 78rem !important;
  }
  .l-sm-79px {
    left: 79px !important;
  }
  .l-sm-79rem {
    left: 79rem !important;
  }
  .l-sm-80px {
    left: 80px !important;
  }
  .l-sm-80rem {
    left: 80rem !important;
  }
  .l-sm-81px {
    left: 81px !important;
  }
  .l-sm-81rem {
    left: 81rem !important;
  }
  .l-sm-82px {
    left: 82px !important;
  }
  .l-sm-82rem {
    left: 82rem !important;
  }
  .l-sm-83px {
    left: 83px !important;
  }
  .l-sm-83rem {
    left: 83rem !important;
  }
  .l-sm-84px {
    left: 84px !important;
  }
  .l-sm-84rem {
    left: 84rem !important;
  }
  .l-sm-85px {
    left: 85px !important;
  }
  .l-sm-85rem {
    left: 85rem !important;
  }
  .l-sm-86px {
    left: 86px !important;
  }
  .l-sm-86rem {
    left: 86rem !important;
  }
  .l-sm-87px {
    left: 87px !important;
  }
  .l-sm-87rem {
    left: 87rem !important;
  }
  .l-sm-88px {
    left: 88px !important;
  }
  .l-sm-88rem {
    left: 88rem !important;
  }
  .l-sm-89px {
    left: 89px !important;
  }
  .l-sm-89rem {
    left: 89rem !important;
  }
  .l-sm-90px {
    left: 90px !important;
  }
  .l-sm-90rem {
    left: 90rem !important;
  }
  .l-sm-91px {
    left: 91px !important;
  }
  .l-sm-91rem {
    left: 91rem !important;
  }
  .l-sm-92px {
    left: 92px !important;
  }
  .l-sm-92rem {
    left: 92rem !important;
  }
  .l-sm-93px {
    left: 93px !important;
  }
  .l-sm-93rem {
    left: 93rem !important;
  }
  .l-sm-94px {
    left: 94px !important;
  }
  .l-sm-94rem {
    left: 94rem !important;
  }
  .l-sm-95px {
    left: 95px !important;
  }
  .l-sm-95rem {
    left: 95rem !important;
  }
  .l-sm-96px {
    left: 96px !important;
  }
  .l-sm-96rem {
    left: 96rem !important;
  }
  .l-sm-97px {
    left: 97px !important;
  }
  .l-sm-97rem {
    left: 97rem !important;
  }
  .l-sm-98px {
    left: 98px !important;
  }
  .l-sm-98rem {
    left: 98rem !important;
  }
  .l-sm-99px {
    left: 99px !important;
  }
  .l-sm-99rem {
    left: 99rem !important;
  }
  .l-sm-100px {
    left: 100px !important;
  }
  .l-sm-100rem {
    left: 100rem !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .pt-sm-0px {
    padding-top: 0px !important;
  }
  .pb-sm-0px {
    padding-bottom: 0px !important;
  }
  .pt-sm-5px {
    padding-top: 5px !important;
  }
  .pb-sm-5px {
    padding-bottom: 5px !important;
  }
  .pt-sm-10px {
    padding-top: 10px !important;
  }
  .pb-sm-10px {
    padding-bottom: 10px !important;
  }
  .pt-sm-15px {
    padding-top: 15px !important;
  }
  .pb-sm-15px {
    padding-bottom: 15px !important;
  }
  .pt-sm-20px {
    padding-top: 20px !important;
  }
  .pb-sm-20px {
    padding-bottom: 20px !important;
  }
  .pt-sm-25px {
    padding-top: 25px !important;
  }
  .pb-sm-25px {
    padding-bottom: 25px !important;
  }
  .pt-sm-30px {
    padding-top: 30px !important;
  }
  .pb-sm-30px {
    padding-bottom: 30px !important;
  }
  .pt-sm-35px {
    padding-top: 35px !important;
  }
  .pb-sm-35px {
    padding-bottom: 35px !important;
  }
  .pt-sm-40px {
    padding-top: 40px !important;
  }
  .pb-sm-40px {
    padding-bottom: 40px !important;
  }
  .pt-sm-45px {
    padding-top: 45px !important;
  }
  .pb-sm-45px {
    padding-bottom: 45px !important;
  }
  .pt-sm-50px {
    padding-top: 50px !important;
  }
  .pb-sm-50px {
    padding-bottom: 50px !important;
  }
  .pt-sm-55px {
    padding-top: 55px !important;
  }
  .pb-sm-55px {
    padding-bottom: 55px !important;
  }
  .pt-sm-60px {
    padding-top: 60px !important;
  }
  .pb-sm-60px {
    padding-bottom: 60px !important;
  }
  .pt-sm-65px {
    padding-top: 65px !important;
  }
  .pb-sm-65px {
    padding-bottom: 65px !important;
  }
  .pt-sm-70px {
    padding-top: 70px !important;
  }
  .pb-sm-70px {
    padding-bottom: 70px !important;
  }
  .pt-sm-75px {
    padding-top: 75px !important;
  }
  .pb-sm-75px {
    padding-bottom: 75px !important;
  }
  .pt-sm-80px {
    padding-top: 80px !important;
  }
  .pb-sm-80px {
    padding-bottom: 80px !important;
  }
  .pt-sm-85px {
    padding-top: 85px !important;
  }
  .pb-sm-85px {
    padding-bottom: 85px !important;
  }
  .pt-sm-90px {
    padding-top: 90px !important;
  }
  .pb-sm-90px {
    padding-bottom: 90px !important;
  }
  .pt-sm-95px {
    padding-top: 95px !important;
  }
  .pb-sm-95px {
    padding-bottom: 95px !important;
  }
  .pt-sm-100px {
    padding-top: 100px !important;
  }
  .pb-sm-100px {
    padding-bottom: 100px !important;
  }
  .mt-sm-0px {
    margin-top: 0px !important;
  }
  .mb-sm-0px {
    margin-bottom: 0px !important;
  }
  .mt-sm-5px {
    margin-top: 5px !important;
  }
  .mb-sm-5px {
    margin-bottom: 5px !important;
  }
  .mt-sm-10px {
    margin-top: 10px !important;
  }
  .mb-sm-10px {
    margin-bottom: 10px !important;
  }
  .mt-sm-15px {
    margin-top: 15px !important;
  }
  .mb-sm-15px {
    margin-bottom: 15px !important;
  }
  .mt-sm-20px {
    margin-top: 20px !important;
  }
  .mb-sm-20px {
    margin-bottom: 20px !important;
  }
  .mt-sm-25px {
    margin-top: 25px !important;
  }
  .mb-sm-25px {
    margin-bottom: 25px !important;
  }
  .mt-sm-30px {
    margin-top: 30px !important;
  }
  .mb-sm-30px {
    margin-bottom: 30px !important;
  }
  .mt-sm-35px {
    margin-top: 35px !important;
  }
  .mb-sm-35px {
    margin-bottom: 35px !important;
  }
  .mt-sm-40px {
    margin-top: 40px !important;
  }
  .mb-sm-40px {
    margin-bottom: 40px !important;
  }
  .mt-sm-45px {
    margin-top: 45px !important;
  }
  .mb-sm-45px {
    margin-bottom: 45px !important;
  }
  .mt-sm-50px {
    margin-top: 50px !important;
  }
  .mb-sm-50px {
    margin-bottom: 50px !important;
  }
  .mt-sm-55px {
    margin-top: 55px !important;
  }
  .mb-sm-55px {
    margin-bottom: 55px !important;
  }
  .mt-sm-60px {
    margin-top: 60px !important;
  }
  .mb-sm-60px {
    margin-bottom: 60px !important;
  }
  .mt-sm-65px {
    margin-top: 65px !important;
  }
  .mb-sm-65px {
    margin-bottom: 65px !important;
  }
  .mt-sm-70px {
    margin-top: 70px !important;
  }
  .mb-sm-70px {
    margin-bottom: 70px !important;
  }
  .mt-sm-75px {
    margin-top: 75px !important;
  }
  .mb-sm-75px {
    margin-bottom: 75px !important;
  }
  .mt-sm-80px {
    margin-top: 80px !important;
  }
  .mb-sm-80px {
    margin-bottom: 80px !important;
  }
  .mt-sm-85px {
    margin-top: 85px !important;
  }
  .mb-sm-85px {
    margin-bottom: 85px !important;
  }
  .mt-sm-90px {
    margin-top: 90px !important;
  }
  .mb-sm-90px {
    margin-bottom: 90px !important;
  }
  .mt-sm-95px {
    margin-top: 95px !important;
  }
  .mb-sm-95px {
    margin-bottom: 95px !important;
  }
  .mt-sm-100px {
    margin-top: 100px !important;
  }
  .mb-sm-100px {
    margin-bottom: 100px !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-top-sm-1px-s-2f2f2f {
    border-top: 1px solid #2f2f2f !important;
  }
}

@media (min-width: 768px) {
  .d-md-table-row {
    display: table-row !important;
  }
  .fixed-md-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .order-md-initial {
    -ms-flex-order: initial;
        order: initial;
  }
  .w-md-0px {
    width: 0px !important;
  }
  .w-md-0rem {
    width: 0rem !important;
  }
  .w-md-1px {
    width: 1px !important;
  }
  .w-md-1rem {
    width: 1rem !important;
  }
  .w-md-2px {
    width: 2px !important;
  }
  .w-md-2rem {
    width: 2rem !important;
  }
  .w-md-3px {
    width: 3px !important;
  }
  .w-md-3rem {
    width: 3rem !important;
  }
  .w-md-4px {
    width: 4px !important;
  }
  .w-md-4rem {
    width: 4rem !important;
  }
  .w-md-5px {
    width: 5px !important;
  }
  .w-md-5rem {
    width: 5rem !important;
  }
  .w-md-6px {
    width: 6px !important;
  }
  .w-md-6rem {
    width: 6rem !important;
  }
  .w-md-7px {
    width: 7px !important;
  }
  .w-md-7rem {
    width: 7rem !important;
  }
  .w-md-8px {
    width: 8px !important;
  }
  .w-md-8rem {
    width: 8rem !important;
  }
  .w-md-9px {
    width: 9px !important;
  }
  .w-md-9rem {
    width: 9rem !important;
  }
  .w-md-10px {
    width: 10px !important;
  }
  .w-md-10rem {
    width: 10rem !important;
  }
  .w-md-11px {
    width: 11px !important;
  }
  .w-md-11rem {
    width: 11rem !important;
  }
  .w-md-12px {
    width: 12px !important;
  }
  .w-md-12rem {
    width: 12rem !important;
  }
  .w-md-13px {
    width: 13px !important;
  }
  .w-md-13rem {
    width: 13rem !important;
  }
  .w-md-14px {
    width: 14px !important;
  }
  .w-md-14rem {
    width: 14rem !important;
  }
  .w-md-15px {
    width: 15px !important;
  }
  .w-md-15rem {
    width: 15rem !important;
  }
  .w-md-16px {
    width: 16px !important;
  }
  .w-md-16rem {
    width: 16rem !important;
  }
  .w-md-17px {
    width: 17px !important;
  }
  .w-md-17rem {
    width: 17rem !important;
  }
  .w-md-18px {
    width: 18px !important;
  }
  .w-md-18rem {
    width: 18rem !important;
  }
  .w-md-19px {
    width: 19px !important;
  }
  .w-md-19rem {
    width: 19rem !important;
  }
  .w-md-20px {
    width: 20px !important;
  }
  .w-md-20rem {
    width: 20rem !important;
  }
  .w-md-21px {
    width: 21px !important;
  }
  .w-md-21rem {
    width: 21rem !important;
  }
  .w-md-22px {
    width: 22px !important;
  }
  .w-md-22rem {
    width: 22rem !important;
  }
  .w-md-23px {
    width: 23px !important;
  }
  .w-md-23rem {
    width: 23rem !important;
  }
  .w-md-24px {
    width: 24px !important;
  }
  .w-md-24rem {
    width: 24rem !important;
  }
  .w-md-25px {
    width: 25px !important;
  }
  .w-md-25rem {
    width: 25rem !important;
  }
  .w-md-26px {
    width: 26px !important;
  }
  .w-md-26rem {
    width: 26rem !important;
  }
  .w-md-27px {
    width: 27px !important;
  }
  .w-md-27rem {
    width: 27rem !important;
  }
  .w-md-28px {
    width: 28px !important;
  }
  .w-md-28rem {
    width: 28rem !important;
  }
  .w-md-29px {
    width: 29px !important;
  }
  .w-md-29rem {
    width: 29rem !important;
  }
  .w-md-30px {
    width: 30px !important;
  }
  .w-md-30rem {
    width: 30rem !important;
  }
  .w-md-31px {
    width: 31px !important;
  }
  .w-md-31rem {
    width: 31rem !important;
  }
  .w-md-32px {
    width: 32px !important;
  }
  .w-md-32rem {
    width: 32rem !important;
  }
  .w-md-33px {
    width: 33px !important;
  }
  .w-md-33rem {
    width: 33rem !important;
  }
  .w-md-34px {
    width: 34px !important;
  }
  .w-md-34rem {
    width: 34rem !important;
  }
  .w-md-35px {
    width: 35px !important;
  }
  .w-md-35rem {
    width: 35rem !important;
  }
  .w-md-36px {
    width: 36px !important;
  }
  .w-md-36rem {
    width: 36rem !important;
  }
  .w-md-37px {
    width: 37px !important;
  }
  .w-md-37rem {
    width: 37rem !important;
  }
  .w-md-38px {
    width: 38px !important;
  }
  .w-md-38rem {
    width: 38rem !important;
  }
  .w-md-39px {
    width: 39px !important;
  }
  .w-md-39rem {
    width: 39rem !important;
  }
  .w-md-40px {
    width: 40px !important;
  }
  .w-md-40rem {
    width: 40rem !important;
  }
  .w-md-41px {
    width: 41px !important;
  }
  .w-md-41rem {
    width: 41rem !important;
  }
  .w-md-42px {
    width: 42px !important;
  }
  .w-md-42rem {
    width: 42rem !important;
  }
  .w-md-43px {
    width: 43px !important;
  }
  .w-md-43rem {
    width: 43rem !important;
  }
  .w-md-44px {
    width: 44px !important;
  }
  .w-md-44rem {
    width: 44rem !important;
  }
  .w-md-45px {
    width: 45px !important;
  }
  .w-md-45rem {
    width: 45rem !important;
  }
  .w-md-46px {
    width: 46px !important;
  }
  .w-md-46rem {
    width: 46rem !important;
  }
  .w-md-47px {
    width: 47px !important;
  }
  .w-md-47rem {
    width: 47rem !important;
  }
  .w-md-48px {
    width: 48px !important;
  }
  .w-md-48rem {
    width: 48rem !important;
  }
  .w-md-49px {
    width: 49px !important;
  }
  .w-md-49rem {
    width: 49rem !important;
  }
  .w-md-50px {
    width: 50px !important;
  }
  .w-md-50rem {
    width: 50rem !important;
  }
  .w-md-51px {
    width: 51px !important;
  }
  .w-md-51rem {
    width: 51rem !important;
  }
  .w-md-52px {
    width: 52px !important;
  }
  .w-md-52rem {
    width: 52rem !important;
  }
  .w-md-53px {
    width: 53px !important;
  }
  .w-md-53rem {
    width: 53rem !important;
  }
  .w-md-54px {
    width: 54px !important;
  }
  .w-md-54rem {
    width: 54rem !important;
  }
  .w-md-55px {
    width: 55px !important;
  }
  .w-md-55rem {
    width: 55rem !important;
  }
  .w-md-56px {
    width: 56px !important;
  }
  .w-md-56rem {
    width: 56rem !important;
  }
  .w-md-57px {
    width: 57px !important;
  }
  .w-md-57rem {
    width: 57rem !important;
  }
  .w-md-58px {
    width: 58px !important;
  }
  .w-md-58rem {
    width: 58rem !important;
  }
  .w-md-59px {
    width: 59px !important;
  }
  .w-md-59rem {
    width: 59rem !important;
  }
  .w-md-60px {
    width: 60px !important;
  }
  .w-md-60rem {
    width: 60rem !important;
  }
  .w-md-61px {
    width: 61px !important;
  }
  .w-md-61rem {
    width: 61rem !important;
  }
  .w-md-62px {
    width: 62px !important;
  }
  .w-md-62rem {
    width: 62rem !important;
  }
  .w-md-63px {
    width: 63px !important;
  }
  .w-md-63rem {
    width: 63rem !important;
  }
  .w-md-64px {
    width: 64px !important;
  }
  .w-md-64rem {
    width: 64rem !important;
  }
  .w-md-65px {
    width: 65px !important;
  }
  .w-md-65rem {
    width: 65rem !important;
  }
  .w-md-66px {
    width: 66px !important;
  }
  .w-md-66rem {
    width: 66rem !important;
  }
  .w-md-67px {
    width: 67px !important;
  }
  .w-md-67rem {
    width: 67rem !important;
  }
  .w-md-68px {
    width: 68px !important;
  }
  .w-md-68rem {
    width: 68rem !important;
  }
  .w-md-69px {
    width: 69px !important;
  }
  .w-md-69rem {
    width: 69rem !important;
  }
  .w-md-70px {
    width: 70px !important;
  }
  .w-md-70rem {
    width: 70rem !important;
  }
  .w-md-71px {
    width: 71px !important;
  }
  .w-md-71rem {
    width: 71rem !important;
  }
  .w-md-72px {
    width: 72px !important;
  }
  .w-md-72rem {
    width: 72rem !important;
  }
  .w-md-73px {
    width: 73px !important;
  }
  .w-md-73rem {
    width: 73rem !important;
  }
  .w-md-74px {
    width: 74px !important;
  }
  .w-md-74rem {
    width: 74rem !important;
  }
  .w-md-75px {
    width: 75px !important;
  }
  .w-md-75rem {
    width: 75rem !important;
  }
  .w-md-76px {
    width: 76px !important;
  }
  .w-md-76rem {
    width: 76rem !important;
  }
  .w-md-77px {
    width: 77px !important;
  }
  .w-md-77rem {
    width: 77rem !important;
  }
  .w-md-78px {
    width: 78px !important;
  }
  .w-md-78rem {
    width: 78rem !important;
  }
  .w-md-79px {
    width: 79px !important;
  }
  .w-md-79rem {
    width: 79rem !important;
  }
  .w-md-80px {
    width: 80px !important;
  }
  .w-md-80rem {
    width: 80rem !important;
  }
  .w-md-81px {
    width: 81px !important;
  }
  .w-md-81rem {
    width: 81rem !important;
  }
  .w-md-82px {
    width: 82px !important;
  }
  .w-md-82rem {
    width: 82rem !important;
  }
  .w-md-83px {
    width: 83px !important;
  }
  .w-md-83rem {
    width: 83rem !important;
  }
  .w-md-84px {
    width: 84px !important;
  }
  .w-md-84rem {
    width: 84rem !important;
  }
  .w-md-85px {
    width: 85px !important;
  }
  .w-md-85rem {
    width: 85rem !important;
  }
  .w-md-86px {
    width: 86px !important;
  }
  .w-md-86rem {
    width: 86rem !important;
  }
  .w-md-87px {
    width: 87px !important;
  }
  .w-md-87rem {
    width: 87rem !important;
  }
  .w-md-88px {
    width: 88px !important;
  }
  .w-md-88rem {
    width: 88rem !important;
  }
  .w-md-89px {
    width: 89px !important;
  }
  .w-md-89rem {
    width: 89rem !important;
  }
  .w-md-90px {
    width: 90px !important;
  }
  .w-md-90rem {
    width: 90rem !important;
  }
  .w-md-91px {
    width: 91px !important;
  }
  .w-md-91rem {
    width: 91rem !important;
  }
  .w-md-92px {
    width: 92px !important;
  }
  .w-md-92rem {
    width: 92rem !important;
  }
  .w-md-93px {
    width: 93px !important;
  }
  .w-md-93rem {
    width: 93rem !important;
  }
  .w-md-94px {
    width: 94px !important;
  }
  .w-md-94rem {
    width: 94rem !important;
  }
  .w-md-95px {
    width: 95px !important;
  }
  .w-md-95rem {
    width: 95rem !important;
  }
  .w-md-96px {
    width: 96px !important;
  }
  .w-md-96rem {
    width: 96rem !important;
  }
  .w-md-97px {
    width: 97px !important;
  }
  .w-md-97rem {
    width: 97rem !important;
  }
  .w-md-98px {
    width: 98px !important;
  }
  .w-md-98rem {
    width: 98rem !important;
  }
  .w-md-99px {
    width: 99px !important;
  }
  .w-md-99rem {
    width: 99rem !important;
  }
  .w-md-100px {
    width: 100px !important;
  }
  .w-md-100rem {
    width: 100rem !important;
  }
  .h-md-0px {
    height: 0px !important;
  }
  .h-md-0rem {
    height: 0rem !important;
  }
  .h-md-1px {
    height: 1px !important;
  }
  .h-md-1rem {
    height: 1rem !important;
  }
  .h-md-2px {
    height: 2px !important;
  }
  .h-md-2rem {
    height: 2rem !important;
  }
  .h-md-3px {
    height: 3px !important;
  }
  .h-md-3rem {
    height: 3rem !important;
  }
  .h-md-4px {
    height: 4px !important;
  }
  .h-md-4rem {
    height: 4rem !important;
  }
  .h-md-5px {
    height: 5px !important;
  }
  .h-md-5rem {
    height: 5rem !important;
  }
  .h-md-6px {
    height: 6px !important;
  }
  .h-md-6rem {
    height: 6rem !important;
  }
  .h-md-7px {
    height: 7px !important;
  }
  .h-md-7rem {
    height: 7rem !important;
  }
  .h-md-8px {
    height: 8px !important;
  }
  .h-md-8rem {
    height: 8rem !important;
  }
  .h-md-9px {
    height: 9px !important;
  }
  .h-md-9rem {
    height: 9rem !important;
  }
  .h-md-10px {
    height: 10px !important;
  }
  .h-md-10rem {
    height: 10rem !important;
  }
  .h-md-11px {
    height: 11px !important;
  }
  .h-md-11rem {
    height: 11rem !important;
  }
  .h-md-12px {
    height: 12px !important;
  }
  .h-md-12rem {
    height: 12rem !important;
  }
  .h-md-13px {
    height: 13px !important;
  }
  .h-md-13rem {
    height: 13rem !important;
  }
  .h-md-14px {
    height: 14px !important;
  }
  .h-md-14rem {
    height: 14rem !important;
  }
  .h-md-15px {
    height: 15px !important;
  }
  .h-md-15rem {
    height: 15rem !important;
  }
  .h-md-16px {
    height: 16px !important;
  }
  .h-md-16rem {
    height: 16rem !important;
  }
  .h-md-17px {
    height: 17px !important;
  }
  .h-md-17rem {
    height: 17rem !important;
  }
  .h-md-18px {
    height: 18px !important;
  }
  .h-md-18rem {
    height: 18rem !important;
  }
  .h-md-19px {
    height: 19px !important;
  }
  .h-md-19rem {
    height: 19rem !important;
  }
  .h-md-20px {
    height: 20px !important;
  }
  .h-md-20rem {
    height: 20rem !important;
  }
  .h-md-21px {
    height: 21px !important;
  }
  .h-md-21rem {
    height: 21rem !important;
  }
  .h-md-22px {
    height: 22px !important;
  }
  .h-md-22rem {
    height: 22rem !important;
  }
  .h-md-23px {
    height: 23px !important;
  }
  .h-md-23rem {
    height: 23rem !important;
  }
  .h-md-24px {
    height: 24px !important;
  }
  .h-md-24rem {
    height: 24rem !important;
  }
  .h-md-25px {
    height: 25px !important;
  }
  .h-md-25rem {
    height: 25rem !important;
  }
  .h-md-26px {
    height: 26px !important;
  }
  .h-md-26rem {
    height: 26rem !important;
  }
  .h-md-27px {
    height: 27px !important;
  }
  .h-md-27rem {
    height: 27rem !important;
  }
  .h-md-28px {
    height: 28px !important;
  }
  .h-md-28rem {
    height: 28rem !important;
  }
  .h-md-29px {
    height: 29px !important;
  }
  .h-md-29rem {
    height: 29rem !important;
  }
  .h-md-30px {
    height: 30px !important;
  }
  .h-md-30rem {
    height: 30rem !important;
  }
  .h-md-31px {
    height: 31px !important;
  }
  .h-md-31rem {
    height: 31rem !important;
  }
  .h-md-32px {
    height: 32px !important;
  }
  .h-md-32rem {
    height: 32rem !important;
  }
  .h-md-33px {
    height: 33px !important;
  }
  .h-md-33rem {
    height: 33rem !important;
  }
  .h-md-34px {
    height: 34px !important;
  }
  .h-md-34rem {
    height: 34rem !important;
  }
  .h-md-35px {
    height: 35px !important;
  }
  .h-md-35rem {
    height: 35rem !important;
  }
  .h-md-36px {
    height: 36px !important;
  }
  .h-md-36rem {
    height: 36rem !important;
  }
  .h-md-37px {
    height: 37px !important;
  }
  .h-md-37rem {
    height: 37rem !important;
  }
  .h-md-38px {
    height: 38px !important;
  }
  .h-md-38rem {
    height: 38rem !important;
  }
  .h-md-39px {
    height: 39px !important;
  }
  .h-md-39rem {
    height: 39rem !important;
  }
  .h-md-40px {
    height: 40px !important;
  }
  .h-md-40rem {
    height: 40rem !important;
  }
  .h-md-41px {
    height: 41px !important;
  }
  .h-md-41rem {
    height: 41rem !important;
  }
  .h-md-42px {
    height: 42px !important;
  }
  .h-md-42rem {
    height: 42rem !important;
  }
  .h-md-43px {
    height: 43px !important;
  }
  .h-md-43rem {
    height: 43rem !important;
  }
  .h-md-44px {
    height: 44px !important;
  }
  .h-md-44rem {
    height: 44rem !important;
  }
  .h-md-45px {
    height: 45px !important;
  }
  .h-md-45rem {
    height: 45rem !important;
  }
  .h-md-46px {
    height: 46px !important;
  }
  .h-md-46rem {
    height: 46rem !important;
  }
  .h-md-47px {
    height: 47px !important;
  }
  .h-md-47rem {
    height: 47rem !important;
  }
  .h-md-48px {
    height: 48px !important;
  }
  .h-md-48rem {
    height: 48rem !important;
  }
  .h-md-49px {
    height: 49px !important;
  }
  .h-md-49rem {
    height: 49rem !important;
  }
  .h-md-50px {
    height: 50px !important;
  }
  .h-md-50rem {
    height: 50rem !important;
  }
  .h-md-51px {
    height: 51px !important;
  }
  .h-md-51rem {
    height: 51rem !important;
  }
  .h-md-52px {
    height: 52px !important;
  }
  .h-md-52rem {
    height: 52rem !important;
  }
  .h-md-53px {
    height: 53px !important;
  }
  .h-md-53rem {
    height: 53rem !important;
  }
  .h-md-54px {
    height: 54px !important;
  }
  .h-md-54rem {
    height: 54rem !important;
  }
  .h-md-55px {
    height: 55px !important;
  }
  .h-md-55rem {
    height: 55rem !important;
  }
  .h-md-56px {
    height: 56px !important;
  }
  .h-md-56rem {
    height: 56rem !important;
  }
  .h-md-57px {
    height: 57px !important;
  }
  .h-md-57rem {
    height: 57rem !important;
  }
  .h-md-58px {
    height: 58px !important;
  }
  .h-md-58rem {
    height: 58rem !important;
  }
  .h-md-59px {
    height: 59px !important;
  }
  .h-md-59rem {
    height: 59rem !important;
  }
  .h-md-60px {
    height: 60px !important;
  }
  .h-md-60rem {
    height: 60rem !important;
  }
  .h-md-61px {
    height: 61px !important;
  }
  .h-md-61rem {
    height: 61rem !important;
  }
  .h-md-62px {
    height: 62px !important;
  }
  .h-md-62rem {
    height: 62rem !important;
  }
  .h-md-63px {
    height: 63px !important;
  }
  .h-md-63rem {
    height: 63rem !important;
  }
  .h-md-64px {
    height: 64px !important;
  }
  .h-md-64rem {
    height: 64rem !important;
  }
  .h-md-65px {
    height: 65px !important;
  }
  .h-md-65rem {
    height: 65rem !important;
  }
  .h-md-66px {
    height: 66px !important;
  }
  .h-md-66rem {
    height: 66rem !important;
  }
  .h-md-67px {
    height: 67px !important;
  }
  .h-md-67rem {
    height: 67rem !important;
  }
  .h-md-68px {
    height: 68px !important;
  }
  .h-md-68rem {
    height: 68rem !important;
  }
  .h-md-69px {
    height: 69px !important;
  }
  .h-md-69rem {
    height: 69rem !important;
  }
  .h-md-70px {
    height: 70px !important;
  }
  .h-md-70rem {
    height: 70rem !important;
  }
  .h-md-71px {
    height: 71px !important;
  }
  .h-md-71rem {
    height: 71rem !important;
  }
  .h-md-72px {
    height: 72px !important;
  }
  .h-md-72rem {
    height: 72rem !important;
  }
  .h-md-73px {
    height: 73px !important;
  }
  .h-md-73rem {
    height: 73rem !important;
  }
  .h-md-74px {
    height: 74px !important;
  }
  .h-md-74rem {
    height: 74rem !important;
  }
  .h-md-75px {
    height: 75px !important;
  }
  .h-md-75rem {
    height: 75rem !important;
  }
  .h-md-76px {
    height: 76px !important;
  }
  .h-md-76rem {
    height: 76rem !important;
  }
  .h-md-77px {
    height: 77px !important;
  }
  .h-md-77rem {
    height: 77rem !important;
  }
  .h-md-78px {
    height: 78px !important;
  }
  .h-md-78rem {
    height: 78rem !important;
  }
  .h-md-79px {
    height: 79px !important;
  }
  .h-md-79rem {
    height: 79rem !important;
  }
  .h-md-80px {
    height: 80px !important;
  }
  .h-md-80rem {
    height: 80rem !important;
  }
  .h-md-81px {
    height: 81px !important;
  }
  .h-md-81rem {
    height: 81rem !important;
  }
  .h-md-82px {
    height: 82px !important;
  }
  .h-md-82rem {
    height: 82rem !important;
  }
  .h-md-83px {
    height: 83px !important;
  }
  .h-md-83rem {
    height: 83rem !important;
  }
  .h-md-84px {
    height: 84px !important;
  }
  .h-md-84rem {
    height: 84rem !important;
  }
  .h-md-85px {
    height: 85px !important;
  }
  .h-md-85rem {
    height: 85rem !important;
  }
  .h-md-86px {
    height: 86px !important;
  }
  .h-md-86rem {
    height: 86rem !important;
  }
  .h-md-87px {
    height: 87px !important;
  }
  .h-md-87rem {
    height: 87rem !important;
  }
  .h-md-88px {
    height: 88px !important;
  }
  .h-md-88rem {
    height: 88rem !important;
  }
  .h-md-89px {
    height: 89px !important;
  }
  .h-md-89rem {
    height: 89rem !important;
  }
  .h-md-90px {
    height: 90px !important;
  }
  .h-md-90rem {
    height: 90rem !important;
  }
  .h-md-91px {
    height: 91px !important;
  }
  .h-md-91rem {
    height: 91rem !important;
  }
  .h-md-92px {
    height: 92px !important;
  }
  .h-md-92rem {
    height: 92rem !important;
  }
  .h-md-93px {
    height: 93px !important;
  }
  .h-md-93rem {
    height: 93rem !important;
  }
  .h-md-94px {
    height: 94px !important;
  }
  .h-md-94rem {
    height: 94rem !important;
  }
  .h-md-95px {
    height: 95px !important;
  }
  .h-md-95rem {
    height: 95rem !important;
  }
  .h-md-96px {
    height: 96px !important;
  }
  .h-md-96rem {
    height: 96rem !important;
  }
  .h-md-97px {
    height: 97px !important;
  }
  .h-md-97rem {
    height: 97rem !important;
  }
  .h-md-98px {
    height: 98px !important;
  }
  .h-md-98rem {
    height: 98rem !important;
  }
  .h-md-99px {
    height: 99px !important;
  }
  .h-md-99rem {
    height: 99rem !important;
  }
  .h-md-100px {
    height: 100px !important;
  }
  .h-md-100rem {
    height: 100rem !important;
  }
  .t-md-0px {
    top: 0px !important;
  }
  .t-md-0rem {
    top: 0rem !important;
  }
  .t-md-1px {
    top: 1px !important;
  }
  .t-md-1rem {
    top: 1rem !important;
  }
  .t-md-2px {
    top: 2px !important;
  }
  .t-md-2rem {
    top: 2rem !important;
  }
  .t-md-3px {
    top: 3px !important;
  }
  .t-md-3rem {
    top: 3rem !important;
  }
  .t-md-4px {
    top: 4px !important;
  }
  .t-md-4rem {
    top: 4rem !important;
  }
  .t-md-5px {
    top: 5px !important;
  }
  .t-md-5rem {
    top: 5rem !important;
  }
  .t-md-6px {
    top: 6px !important;
  }
  .t-md-6rem {
    top: 6rem !important;
  }
  .t-md-7px {
    top: 7px !important;
  }
  .t-md-7rem {
    top: 7rem !important;
  }
  .t-md-8px {
    top: 8px !important;
  }
  .t-md-8rem {
    top: 8rem !important;
  }
  .t-md-9px {
    top: 9px !important;
  }
  .t-md-9rem {
    top: 9rem !important;
  }
  .t-md-10px {
    top: 10px !important;
  }
  .t-md-10rem {
    top: 10rem !important;
  }
  .t-md-11px {
    top: 11px !important;
  }
  .t-md-11rem {
    top: 11rem !important;
  }
  .t-md-12px {
    top: 12px !important;
  }
  .t-md-12rem {
    top: 12rem !important;
  }
  .t-md-13px {
    top: 13px !important;
  }
  .t-md-13rem {
    top: 13rem !important;
  }
  .t-md-14px {
    top: 14px !important;
  }
  .t-md-14rem {
    top: 14rem !important;
  }
  .t-md-15px {
    top: 15px !important;
  }
  .t-md-15rem {
    top: 15rem !important;
  }
  .t-md-16px {
    top: 16px !important;
  }
  .t-md-16rem {
    top: 16rem !important;
  }
  .t-md-17px {
    top: 17px !important;
  }
  .t-md-17rem {
    top: 17rem !important;
  }
  .t-md-18px {
    top: 18px !important;
  }
  .t-md-18rem {
    top: 18rem !important;
  }
  .t-md-19px {
    top: 19px !important;
  }
  .t-md-19rem {
    top: 19rem !important;
  }
  .t-md-20px {
    top: 20px !important;
  }
  .t-md-20rem {
    top: 20rem !important;
  }
  .t-md-21px {
    top: 21px !important;
  }
  .t-md-21rem {
    top: 21rem !important;
  }
  .t-md-22px {
    top: 22px !important;
  }
  .t-md-22rem {
    top: 22rem !important;
  }
  .t-md-23px {
    top: 23px !important;
  }
  .t-md-23rem {
    top: 23rem !important;
  }
  .t-md-24px {
    top: 24px !important;
  }
  .t-md-24rem {
    top: 24rem !important;
  }
  .t-md-25px {
    top: 25px !important;
  }
  .t-md-25rem {
    top: 25rem !important;
  }
  .t-md-26px {
    top: 26px !important;
  }
  .t-md-26rem {
    top: 26rem !important;
  }
  .t-md-27px {
    top: 27px !important;
  }
  .t-md-27rem {
    top: 27rem !important;
  }
  .t-md-28px {
    top: 28px !important;
  }
  .t-md-28rem {
    top: 28rem !important;
  }
  .t-md-29px {
    top: 29px !important;
  }
  .t-md-29rem {
    top: 29rem !important;
  }
  .t-md-30px {
    top: 30px !important;
  }
  .t-md-30rem {
    top: 30rem !important;
  }
  .t-md-31px {
    top: 31px !important;
  }
  .t-md-31rem {
    top: 31rem !important;
  }
  .t-md-32px {
    top: 32px !important;
  }
  .t-md-32rem {
    top: 32rem !important;
  }
  .t-md-33px {
    top: 33px !important;
  }
  .t-md-33rem {
    top: 33rem !important;
  }
  .t-md-34px {
    top: 34px !important;
  }
  .t-md-34rem {
    top: 34rem !important;
  }
  .t-md-35px {
    top: 35px !important;
  }
  .t-md-35rem {
    top: 35rem !important;
  }
  .t-md-36px {
    top: 36px !important;
  }
  .t-md-36rem {
    top: 36rem !important;
  }
  .t-md-37px {
    top: 37px !important;
  }
  .t-md-37rem {
    top: 37rem !important;
  }
  .t-md-38px {
    top: 38px !important;
  }
  .t-md-38rem {
    top: 38rem !important;
  }
  .t-md-39px {
    top: 39px !important;
  }
  .t-md-39rem {
    top: 39rem !important;
  }
  .t-md-40px {
    top: 40px !important;
  }
  .t-md-40rem {
    top: 40rem !important;
  }
  .t-md-41px {
    top: 41px !important;
  }
  .t-md-41rem {
    top: 41rem !important;
  }
  .t-md-42px {
    top: 42px !important;
  }
  .t-md-42rem {
    top: 42rem !important;
  }
  .t-md-43px {
    top: 43px !important;
  }
  .t-md-43rem {
    top: 43rem !important;
  }
  .t-md-44px {
    top: 44px !important;
  }
  .t-md-44rem {
    top: 44rem !important;
  }
  .t-md-45px {
    top: 45px !important;
  }
  .t-md-45rem {
    top: 45rem !important;
  }
  .t-md-46px {
    top: 46px !important;
  }
  .t-md-46rem {
    top: 46rem !important;
  }
  .t-md-47px {
    top: 47px !important;
  }
  .t-md-47rem {
    top: 47rem !important;
  }
  .t-md-48px {
    top: 48px !important;
  }
  .t-md-48rem {
    top: 48rem !important;
  }
  .t-md-49px {
    top: 49px !important;
  }
  .t-md-49rem {
    top: 49rem !important;
  }
  .t-md-50px {
    top: 50px !important;
  }
  .t-md-50rem {
    top: 50rem !important;
  }
  .t-md-51px {
    top: 51px !important;
  }
  .t-md-51rem {
    top: 51rem !important;
  }
  .t-md-52px {
    top: 52px !important;
  }
  .t-md-52rem {
    top: 52rem !important;
  }
  .t-md-53px {
    top: 53px !important;
  }
  .t-md-53rem {
    top: 53rem !important;
  }
  .t-md-54px {
    top: 54px !important;
  }
  .t-md-54rem {
    top: 54rem !important;
  }
  .t-md-55px {
    top: 55px !important;
  }
  .t-md-55rem {
    top: 55rem !important;
  }
  .t-md-56px {
    top: 56px !important;
  }
  .t-md-56rem {
    top: 56rem !important;
  }
  .t-md-57px {
    top: 57px !important;
  }
  .t-md-57rem {
    top: 57rem !important;
  }
  .t-md-58px {
    top: 58px !important;
  }
  .t-md-58rem {
    top: 58rem !important;
  }
  .t-md-59px {
    top: 59px !important;
  }
  .t-md-59rem {
    top: 59rem !important;
  }
  .t-md-60px {
    top: 60px !important;
  }
  .t-md-60rem {
    top: 60rem !important;
  }
  .t-md-61px {
    top: 61px !important;
  }
  .t-md-61rem {
    top: 61rem !important;
  }
  .t-md-62px {
    top: 62px !important;
  }
  .t-md-62rem {
    top: 62rem !important;
  }
  .t-md-63px {
    top: 63px !important;
  }
  .t-md-63rem {
    top: 63rem !important;
  }
  .t-md-64px {
    top: 64px !important;
  }
  .t-md-64rem {
    top: 64rem !important;
  }
  .t-md-65px {
    top: 65px !important;
  }
  .t-md-65rem {
    top: 65rem !important;
  }
  .t-md-66px {
    top: 66px !important;
  }
  .t-md-66rem {
    top: 66rem !important;
  }
  .t-md-67px {
    top: 67px !important;
  }
  .t-md-67rem {
    top: 67rem !important;
  }
  .t-md-68px {
    top: 68px !important;
  }
  .t-md-68rem {
    top: 68rem !important;
  }
  .t-md-69px {
    top: 69px !important;
  }
  .t-md-69rem {
    top: 69rem !important;
  }
  .t-md-70px {
    top: 70px !important;
  }
  .t-md-70rem {
    top: 70rem !important;
  }
  .t-md-71px {
    top: 71px !important;
  }
  .t-md-71rem {
    top: 71rem !important;
  }
  .t-md-72px {
    top: 72px !important;
  }
  .t-md-72rem {
    top: 72rem !important;
  }
  .t-md-73px {
    top: 73px !important;
  }
  .t-md-73rem {
    top: 73rem !important;
  }
  .t-md-74px {
    top: 74px !important;
  }
  .t-md-74rem {
    top: 74rem !important;
  }
  .t-md-75px {
    top: 75px !important;
  }
  .t-md-75rem {
    top: 75rem !important;
  }
  .t-md-76px {
    top: 76px !important;
  }
  .t-md-76rem {
    top: 76rem !important;
  }
  .t-md-77px {
    top: 77px !important;
  }
  .t-md-77rem {
    top: 77rem !important;
  }
  .t-md-78px {
    top: 78px !important;
  }
  .t-md-78rem {
    top: 78rem !important;
  }
  .t-md-79px {
    top: 79px !important;
  }
  .t-md-79rem {
    top: 79rem !important;
  }
  .t-md-80px {
    top: 80px !important;
  }
  .t-md-80rem {
    top: 80rem !important;
  }
  .t-md-81px {
    top: 81px !important;
  }
  .t-md-81rem {
    top: 81rem !important;
  }
  .t-md-82px {
    top: 82px !important;
  }
  .t-md-82rem {
    top: 82rem !important;
  }
  .t-md-83px {
    top: 83px !important;
  }
  .t-md-83rem {
    top: 83rem !important;
  }
  .t-md-84px {
    top: 84px !important;
  }
  .t-md-84rem {
    top: 84rem !important;
  }
  .t-md-85px {
    top: 85px !important;
  }
  .t-md-85rem {
    top: 85rem !important;
  }
  .t-md-86px {
    top: 86px !important;
  }
  .t-md-86rem {
    top: 86rem !important;
  }
  .t-md-87px {
    top: 87px !important;
  }
  .t-md-87rem {
    top: 87rem !important;
  }
  .t-md-88px {
    top: 88px !important;
  }
  .t-md-88rem {
    top: 88rem !important;
  }
  .t-md-89px {
    top: 89px !important;
  }
  .t-md-89rem {
    top: 89rem !important;
  }
  .t-md-90px {
    top: 90px !important;
  }
  .t-md-90rem {
    top: 90rem !important;
  }
  .t-md-91px {
    top: 91px !important;
  }
  .t-md-91rem {
    top: 91rem !important;
  }
  .t-md-92px {
    top: 92px !important;
  }
  .t-md-92rem {
    top: 92rem !important;
  }
  .t-md-93px {
    top: 93px !important;
  }
  .t-md-93rem {
    top: 93rem !important;
  }
  .t-md-94px {
    top: 94px !important;
  }
  .t-md-94rem {
    top: 94rem !important;
  }
  .t-md-95px {
    top: 95px !important;
  }
  .t-md-95rem {
    top: 95rem !important;
  }
  .t-md-96px {
    top: 96px !important;
  }
  .t-md-96rem {
    top: 96rem !important;
  }
  .t-md-97px {
    top: 97px !important;
  }
  .t-md-97rem {
    top: 97rem !important;
  }
  .t-md-98px {
    top: 98px !important;
  }
  .t-md-98rem {
    top: 98rem !important;
  }
  .t-md-99px {
    top: 99px !important;
  }
  .t-md-99rem {
    top: 99rem !important;
  }
  .t-md-100px {
    top: 100px !important;
  }
  .t-md-100rem {
    top: 100rem !important;
  }
  .l-md-0px {
    left: 0px !important;
  }
  .l-md-0rem {
    left: 0rem !important;
  }
  .l-md-1px {
    left: 1px !important;
  }
  .l-md-1rem {
    left: 1rem !important;
  }
  .l-md-2px {
    left: 2px !important;
  }
  .l-md-2rem {
    left: 2rem !important;
  }
  .l-md-3px {
    left: 3px !important;
  }
  .l-md-3rem {
    left: 3rem !important;
  }
  .l-md-4px {
    left: 4px !important;
  }
  .l-md-4rem {
    left: 4rem !important;
  }
  .l-md-5px {
    left: 5px !important;
  }
  .l-md-5rem {
    left: 5rem !important;
  }
  .l-md-6px {
    left: 6px !important;
  }
  .l-md-6rem {
    left: 6rem !important;
  }
  .l-md-7px {
    left: 7px !important;
  }
  .l-md-7rem {
    left: 7rem !important;
  }
  .l-md-8px {
    left: 8px !important;
  }
  .l-md-8rem {
    left: 8rem !important;
  }
  .l-md-9px {
    left: 9px !important;
  }
  .l-md-9rem {
    left: 9rem !important;
  }
  .l-md-10px {
    left: 10px !important;
  }
  .l-md-10rem {
    left: 10rem !important;
  }
  .l-md-11px {
    left: 11px !important;
  }
  .l-md-11rem {
    left: 11rem !important;
  }
  .l-md-12px {
    left: 12px !important;
  }
  .l-md-12rem {
    left: 12rem !important;
  }
  .l-md-13px {
    left: 13px !important;
  }
  .l-md-13rem {
    left: 13rem !important;
  }
  .l-md-14px {
    left: 14px !important;
  }
  .l-md-14rem {
    left: 14rem !important;
  }
  .l-md-15px {
    left: 15px !important;
  }
  .l-md-15rem {
    left: 15rem !important;
  }
  .l-md-16px {
    left: 16px !important;
  }
  .l-md-16rem {
    left: 16rem !important;
  }
  .l-md-17px {
    left: 17px !important;
  }
  .l-md-17rem {
    left: 17rem !important;
  }
  .l-md-18px {
    left: 18px !important;
  }
  .l-md-18rem {
    left: 18rem !important;
  }
  .l-md-19px {
    left: 19px !important;
  }
  .l-md-19rem {
    left: 19rem !important;
  }
  .l-md-20px {
    left: 20px !important;
  }
  .l-md-20rem {
    left: 20rem !important;
  }
  .l-md-21px {
    left: 21px !important;
  }
  .l-md-21rem {
    left: 21rem !important;
  }
  .l-md-22px {
    left: 22px !important;
  }
  .l-md-22rem {
    left: 22rem !important;
  }
  .l-md-23px {
    left: 23px !important;
  }
  .l-md-23rem {
    left: 23rem !important;
  }
  .l-md-24px {
    left: 24px !important;
  }
  .l-md-24rem {
    left: 24rem !important;
  }
  .l-md-25px {
    left: 25px !important;
  }
  .l-md-25rem {
    left: 25rem !important;
  }
  .l-md-26px {
    left: 26px !important;
  }
  .l-md-26rem {
    left: 26rem !important;
  }
  .l-md-27px {
    left: 27px !important;
  }
  .l-md-27rem {
    left: 27rem !important;
  }
  .l-md-28px {
    left: 28px !important;
  }
  .l-md-28rem {
    left: 28rem !important;
  }
  .l-md-29px {
    left: 29px !important;
  }
  .l-md-29rem {
    left: 29rem !important;
  }
  .l-md-30px {
    left: 30px !important;
  }
  .l-md-30rem {
    left: 30rem !important;
  }
  .l-md-31px {
    left: 31px !important;
  }
  .l-md-31rem {
    left: 31rem !important;
  }
  .l-md-32px {
    left: 32px !important;
  }
  .l-md-32rem {
    left: 32rem !important;
  }
  .l-md-33px {
    left: 33px !important;
  }
  .l-md-33rem {
    left: 33rem !important;
  }
  .l-md-34px {
    left: 34px !important;
  }
  .l-md-34rem {
    left: 34rem !important;
  }
  .l-md-35px {
    left: 35px !important;
  }
  .l-md-35rem {
    left: 35rem !important;
  }
  .l-md-36px {
    left: 36px !important;
  }
  .l-md-36rem {
    left: 36rem !important;
  }
  .l-md-37px {
    left: 37px !important;
  }
  .l-md-37rem {
    left: 37rem !important;
  }
  .l-md-38px {
    left: 38px !important;
  }
  .l-md-38rem {
    left: 38rem !important;
  }
  .l-md-39px {
    left: 39px !important;
  }
  .l-md-39rem {
    left: 39rem !important;
  }
  .l-md-40px {
    left: 40px !important;
  }
  .l-md-40rem {
    left: 40rem !important;
  }
  .l-md-41px {
    left: 41px !important;
  }
  .l-md-41rem {
    left: 41rem !important;
  }
  .l-md-42px {
    left: 42px !important;
  }
  .l-md-42rem {
    left: 42rem !important;
  }
  .l-md-43px {
    left: 43px !important;
  }
  .l-md-43rem {
    left: 43rem !important;
  }
  .l-md-44px {
    left: 44px !important;
  }
  .l-md-44rem {
    left: 44rem !important;
  }
  .l-md-45px {
    left: 45px !important;
  }
  .l-md-45rem {
    left: 45rem !important;
  }
  .l-md-46px {
    left: 46px !important;
  }
  .l-md-46rem {
    left: 46rem !important;
  }
  .l-md-47px {
    left: 47px !important;
  }
  .l-md-47rem {
    left: 47rem !important;
  }
  .l-md-48px {
    left: 48px !important;
  }
  .l-md-48rem {
    left: 48rem !important;
  }
  .l-md-49px {
    left: 49px !important;
  }
  .l-md-49rem {
    left: 49rem !important;
  }
  .l-md-50px {
    left: 50px !important;
  }
  .l-md-50rem {
    left: 50rem !important;
  }
  .l-md-51px {
    left: 51px !important;
  }
  .l-md-51rem {
    left: 51rem !important;
  }
  .l-md-52px {
    left: 52px !important;
  }
  .l-md-52rem {
    left: 52rem !important;
  }
  .l-md-53px {
    left: 53px !important;
  }
  .l-md-53rem {
    left: 53rem !important;
  }
  .l-md-54px {
    left: 54px !important;
  }
  .l-md-54rem {
    left: 54rem !important;
  }
  .l-md-55px {
    left: 55px !important;
  }
  .l-md-55rem {
    left: 55rem !important;
  }
  .l-md-56px {
    left: 56px !important;
  }
  .l-md-56rem {
    left: 56rem !important;
  }
  .l-md-57px {
    left: 57px !important;
  }
  .l-md-57rem {
    left: 57rem !important;
  }
  .l-md-58px {
    left: 58px !important;
  }
  .l-md-58rem {
    left: 58rem !important;
  }
  .l-md-59px {
    left: 59px !important;
  }
  .l-md-59rem {
    left: 59rem !important;
  }
  .l-md-60px {
    left: 60px !important;
  }
  .l-md-60rem {
    left: 60rem !important;
  }
  .l-md-61px {
    left: 61px !important;
  }
  .l-md-61rem {
    left: 61rem !important;
  }
  .l-md-62px {
    left: 62px !important;
  }
  .l-md-62rem {
    left: 62rem !important;
  }
  .l-md-63px {
    left: 63px !important;
  }
  .l-md-63rem {
    left: 63rem !important;
  }
  .l-md-64px {
    left: 64px !important;
  }
  .l-md-64rem {
    left: 64rem !important;
  }
  .l-md-65px {
    left: 65px !important;
  }
  .l-md-65rem {
    left: 65rem !important;
  }
  .l-md-66px {
    left: 66px !important;
  }
  .l-md-66rem {
    left: 66rem !important;
  }
  .l-md-67px {
    left: 67px !important;
  }
  .l-md-67rem {
    left: 67rem !important;
  }
  .l-md-68px {
    left: 68px !important;
  }
  .l-md-68rem {
    left: 68rem !important;
  }
  .l-md-69px {
    left: 69px !important;
  }
  .l-md-69rem {
    left: 69rem !important;
  }
  .l-md-70px {
    left: 70px !important;
  }
  .l-md-70rem {
    left: 70rem !important;
  }
  .l-md-71px {
    left: 71px !important;
  }
  .l-md-71rem {
    left: 71rem !important;
  }
  .l-md-72px {
    left: 72px !important;
  }
  .l-md-72rem {
    left: 72rem !important;
  }
  .l-md-73px {
    left: 73px !important;
  }
  .l-md-73rem {
    left: 73rem !important;
  }
  .l-md-74px {
    left: 74px !important;
  }
  .l-md-74rem {
    left: 74rem !important;
  }
  .l-md-75px {
    left: 75px !important;
  }
  .l-md-75rem {
    left: 75rem !important;
  }
  .l-md-76px {
    left: 76px !important;
  }
  .l-md-76rem {
    left: 76rem !important;
  }
  .l-md-77px {
    left: 77px !important;
  }
  .l-md-77rem {
    left: 77rem !important;
  }
  .l-md-78px {
    left: 78px !important;
  }
  .l-md-78rem {
    left: 78rem !important;
  }
  .l-md-79px {
    left: 79px !important;
  }
  .l-md-79rem {
    left: 79rem !important;
  }
  .l-md-80px {
    left: 80px !important;
  }
  .l-md-80rem {
    left: 80rem !important;
  }
  .l-md-81px {
    left: 81px !important;
  }
  .l-md-81rem {
    left: 81rem !important;
  }
  .l-md-82px {
    left: 82px !important;
  }
  .l-md-82rem {
    left: 82rem !important;
  }
  .l-md-83px {
    left: 83px !important;
  }
  .l-md-83rem {
    left: 83rem !important;
  }
  .l-md-84px {
    left: 84px !important;
  }
  .l-md-84rem {
    left: 84rem !important;
  }
  .l-md-85px {
    left: 85px !important;
  }
  .l-md-85rem {
    left: 85rem !important;
  }
  .l-md-86px {
    left: 86px !important;
  }
  .l-md-86rem {
    left: 86rem !important;
  }
  .l-md-87px {
    left: 87px !important;
  }
  .l-md-87rem {
    left: 87rem !important;
  }
  .l-md-88px {
    left: 88px !important;
  }
  .l-md-88rem {
    left: 88rem !important;
  }
  .l-md-89px {
    left: 89px !important;
  }
  .l-md-89rem {
    left: 89rem !important;
  }
  .l-md-90px {
    left: 90px !important;
  }
  .l-md-90rem {
    left: 90rem !important;
  }
  .l-md-91px {
    left: 91px !important;
  }
  .l-md-91rem {
    left: 91rem !important;
  }
  .l-md-92px {
    left: 92px !important;
  }
  .l-md-92rem {
    left: 92rem !important;
  }
  .l-md-93px {
    left: 93px !important;
  }
  .l-md-93rem {
    left: 93rem !important;
  }
  .l-md-94px {
    left: 94px !important;
  }
  .l-md-94rem {
    left: 94rem !important;
  }
  .l-md-95px {
    left: 95px !important;
  }
  .l-md-95rem {
    left: 95rem !important;
  }
  .l-md-96px {
    left: 96px !important;
  }
  .l-md-96rem {
    left: 96rem !important;
  }
  .l-md-97px {
    left: 97px !important;
  }
  .l-md-97rem {
    left: 97rem !important;
  }
  .l-md-98px {
    left: 98px !important;
  }
  .l-md-98rem {
    left: 98rem !important;
  }
  .l-md-99px {
    left: 99px !important;
  }
  .l-md-99rem {
    left: 99rem !important;
  }
  .l-md-100px {
    left: 100px !important;
  }
  .l-md-100rem {
    left: 100rem !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .pt-md-0px {
    padding-top: 0px !important;
  }
  .pb-md-0px {
    padding-bottom: 0px !important;
  }
  .pt-md-5px {
    padding-top: 5px !important;
  }
  .pb-md-5px {
    padding-bottom: 5px !important;
  }
  .pt-md-10px {
    padding-top: 10px !important;
  }
  .pb-md-10px {
    padding-bottom: 10px !important;
  }
  .pt-md-15px {
    padding-top: 15px !important;
  }
  .pb-md-15px {
    padding-bottom: 15px !important;
  }
  .pt-md-20px {
    padding-top: 20px !important;
  }
  .pb-md-20px {
    padding-bottom: 20px !important;
  }
  .pt-md-25px {
    padding-top: 25px !important;
  }
  .pb-md-25px {
    padding-bottom: 25px !important;
  }
  .pt-md-30px {
    padding-top: 30px !important;
  }
  .pb-md-30px {
    padding-bottom: 30px !important;
  }
  .pt-md-35px {
    padding-top: 35px !important;
  }
  .pb-md-35px {
    padding-bottom: 35px !important;
  }
  .pt-md-40px {
    padding-top: 40px !important;
  }
  .pb-md-40px {
    padding-bottom: 40px !important;
  }
  .pt-md-45px {
    padding-top: 45px !important;
  }
  .pb-md-45px {
    padding-bottom: 45px !important;
  }
  .pt-md-50px {
    padding-top: 50px !important;
  }
  .pb-md-50px {
    padding-bottom: 50px !important;
  }
  .pt-md-55px {
    padding-top: 55px !important;
  }
  .pb-md-55px {
    padding-bottom: 55px !important;
  }
  .pt-md-60px {
    padding-top: 60px !important;
  }
  .pb-md-60px {
    padding-bottom: 60px !important;
  }
  .pt-md-65px {
    padding-top: 65px !important;
  }
  .pb-md-65px {
    padding-bottom: 65px !important;
  }
  .pt-md-70px {
    padding-top: 70px !important;
  }
  .pb-md-70px {
    padding-bottom: 70px !important;
  }
  .pt-md-75px {
    padding-top: 75px !important;
  }
  .pb-md-75px {
    padding-bottom: 75px !important;
  }
  .pt-md-80px {
    padding-top: 80px !important;
  }
  .pb-md-80px {
    padding-bottom: 80px !important;
  }
  .pt-md-85px {
    padding-top: 85px !important;
  }
  .pb-md-85px {
    padding-bottom: 85px !important;
  }
  .pt-md-90px {
    padding-top: 90px !important;
  }
  .pb-md-90px {
    padding-bottom: 90px !important;
  }
  .pt-md-95px {
    padding-top: 95px !important;
  }
  .pb-md-95px {
    padding-bottom: 95px !important;
  }
  .pt-md-100px {
    padding-top: 100px !important;
  }
  .pb-md-100px {
    padding-bottom: 100px !important;
  }
  .mt-md-0px {
    margin-top: 0px !important;
  }
  .mb-md-0px {
    margin-bottom: 0px !important;
  }
  .mt-md-5px {
    margin-top: 5px !important;
  }
  .mb-md-5px {
    margin-bottom: 5px !important;
  }
  .mt-md-10px {
    margin-top: 10px !important;
  }
  .mb-md-10px {
    margin-bottom: 10px !important;
  }
  .mt-md-15px {
    margin-top: 15px !important;
  }
  .mb-md-15px {
    margin-bottom: 15px !important;
  }
  .mt-md-20px {
    margin-top: 20px !important;
  }
  .mb-md-20px {
    margin-bottom: 20px !important;
  }
  .mt-md-25px {
    margin-top: 25px !important;
  }
  .mb-md-25px {
    margin-bottom: 25px !important;
  }
  .mt-md-30px {
    margin-top: 30px !important;
  }
  .mb-md-30px {
    margin-bottom: 30px !important;
  }
  .mt-md-35px {
    margin-top: 35px !important;
  }
  .mb-md-35px {
    margin-bottom: 35px !important;
  }
  .mt-md-40px {
    margin-top: 40px !important;
  }
  .mb-md-40px {
    margin-bottom: 40px !important;
  }
  .mt-md-45px {
    margin-top: 45px !important;
  }
  .mb-md-45px {
    margin-bottom: 45px !important;
  }
  .mt-md-50px {
    margin-top: 50px !important;
  }
  .mb-md-50px {
    margin-bottom: 50px !important;
  }
  .mt-md-55px {
    margin-top: 55px !important;
  }
  .mb-md-55px {
    margin-bottom: 55px !important;
  }
  .mt-md-60px {
    margin-top: 60px !important;
  }
  .mb-md-60px {
    margin-bottom: 60px !important;
  }
  .mt-md-65px {
    margin-top: 65px !important;
  }
  .mb-md-65px {
    margin-bottom: 65px !important;
  }
  .mt-md-70px {
    margin-top: 70px !important;
  }
  .mb-md-70px {
    margin-bottom: 70px !important;
  }
  .mt-md-75px {
    margin-top: 75px !important;
  }
  .mb-md-75px {
    margin-bottom: 75px !important;
  }
  .mt-md-80px {
    margin-top: 80px !important;
  }
  .mb-md-80px {
    margin-bottom: 80px !important;
  }
  .mt-md-85px {
    margin-top: 85px !important;
  }
  .mb-md-85px {
    margin-bottom: 85px !important;
  }
  .mt-md-90px {
    margin-top: 90px !important;
  }
  .mb-md-90px {
    margin-bottom: 90px !important;
  }
  .mt-md-95px {
    margin-top: 95px !important;
  }
  .mb-md-95px {
    margin-bottom: 95px !important;
  }
  .mt-md-100px {
    margin-top: 100px !important;
  }
  .mb-md-100px {
    margin-bottom: 100px !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-top-md-1px-s-2f2f2f {
    border-top: 1px solid #2f2f2f !important;
  }
}

@media (min-width: 992px) {
  .d-lg-table-row {
    display: table-row !important;
  }
  .fixed-lg-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .order-lg-initial {
    -ms-flex-order: initial;
        order: initial;
  }
  .w-lg-0px {
    width: 0px !important;
  }
  .w-lg-0rem {
    width: 0rem !important;
  }
  .w-lg-1px {
    width: 1px !important;
  }
  .w-lg-1rem {
    width: 1rem !important;
  }
  .w-lg-2px {
    width: 2px !important;
  }
  .w-lg-2rem {
    width: 2rem !important;
  }
  .w-lg-3px {
    width: 3px !important;
  }
  .w-lg-3rem {
    width: 3rem !important;
  }
  .w-lg-4px {
    width: 4px !important;
  }
  .w-lg-4rem {
    width: 4rem !important;
  }
  .w-lg-5px {
    width: 5px !important;
  }
  .w-lg-5rem {
    width: 5rem !important;
  }
  .w-lg-6px {
    width: 6px !important;
  }
  .w-lg-6rem {
    width: 6rem !important;
  }
  .w-lg-7px {
    width: 7px !important;
  }
  .w-lg-7rem {
    width: 7rem !important;
  }
  .w-lg-8px {
    width: 8px !important;
  }
  .w-lg-8rem {
    width: 8rem !important;
  }
  .w-lg-9px {
    width: 9px !important;
  }
  .w-lg-9rem {
    width: 9rem !important;
  }
  .w-lg-10px {
    width: 10px !important;
  }
  .w-lg-10rem {
    width: 10rem !important;
  }
  .w-lg-11px {
    width: 11px !important;
  }
  .w-lg-11rem {
    width: 11rem !important;
  }
  .w-lg-12px {
    width: 12px !important;
  }
  .w-lg-12rem {
    width: 12rem !important;
  }
  .w-lg-13px {
    width: 13px !important;
  }
  .w-lg-13rem {
    width: 13rem !important;
  }
  .w-lg-14px {
    width: 14px !important;
  }
  .w-lg-14rem {
    width: 14rem !important;
  }
  .w-lg-15px {
    width: 15px !important;
  }
  .w-lg-15rem {
    width: 15rem !important;
  }
  .w-lg-16px {
    width: 16px !important;
  }
  .w-lg-16rem {
    width: 16rem !important;
  }
  .w-lg-17px {
    width: 17px !important;
  }
  .w-lg-17rem {
    width: 17rem !important;
  }
  .w-lg-18px {
    width: 18px !important;
  }
  .w-lg-18rem {
    width: 18rem !important;
  }
  .w-lg-19px {
    width: 19px !important;
  }
  .w-lg-19rem {
    width: 19rem !important;
  }
  .w-lg-20px {
    width: 20px !important;
  }
  .w-lg-20rem {
    width: 20rem !important;
  }
  .w-lg-21px {
    width: 21px !important;
  }
  .w-lg-21rem {
    width: 21rem !important;
  }
  .w-lg-22px {
    width: 22px !important;
  }
  .w-lg-22rem {
    width: 22rem !important;
  }
  .w-lg-23px {
    width: 23px !important;
  }
  .w-lg-23rem {
    width: 23rem !important;
  }
  .w-lg-24px {
    width: 24px !important;
  }
  .w-lg-24rem {
    width: 24rem !important;
  }
  .w-lg-25px {
    width: 25px !important;
  }
  .w-lg-25rem {
    width: 25rem !important;
  }
  .w-lg-26px {
    width: 26px !important;
  }
  .w-lg-26rem {
    width: 26rem !important;
  }
  .w-lg-27px {
    width: 27px !important;
  }
  .w-lg-27rem {
    width: 27rem !important;
  }
  .w-lg-28px {
    width: 28px !important;
  }
  .w-lg-28rem {
    width: 28rem !important;
  }
  .w-lg-29px {
    width: 29px !important;
  }
  .w-lg-29rem {
    width: 29rem !important;
  }
  .w-lg-30px {
    width: 30px !important;
  }
  .w-lg-30rem {
    width: 30rem !important;
  }
  .w-lg-31px {
    width: 31px !important;
  }
  .w-lg-31rem {
    width: 31rem !important;
  }
  .w-lg-32px {
    width: 32px !important;
  }
  .w-lg-32rem {
    width: 32rem !important;
  }
  .w-lg-33px {
    width: 33px !important;
  }
  .w-lg-33rem {
    width: 33rem !important;
  }
  .w-lg-34px {
    width: 34px !important;
  }
  .w-lg-34rem {
    width: 34rem !important;
  }
  .w-lg-35px {
    width: 35px !important;
  }
  .w-lg-35rem {
    width: 35rem !important;
  }
  .w-lg-36px {
    width: 36px !important;
  }
  .w-lg-36rem {
    width: 36rem !important;
  }
  .w-lg-37px {
    width: 37px !important;
  }
  .w-lg-37rem {
    width: 37rem !important;
  }
  .w-lg-38px {
    width: 38px !important;
  }
  .w-lg-38rem {
    width: 38rem !important;
  }
  .w-lg-39px {
    width: 39px !important;
  }
  .w-lg-39rem {
    width: 39rem !important;
  }
  .w-lg-40px {
    width: 40px !important;
  }
  .w-lg-40rem {
    width: 40rem !important;
  }
  .w-lg-41px {
    width: 41px !important;
  }
  .w-lg-41rem {
    width: 41rem !important;
  }
  .w-lg-42px {
    width: 42px !important;
  }
  .w-lg-42rem {
    width: 42rem !important;
  }
  .w-lg-43px {
    width: 43px !important;
  }
  .w-lg-43rem {
    width: 43rem !important;
  }
  .w-lg-44px {
    width: 44px !important;
  }
  .w-lg-44rem {
    width: 44rem !important;
  }
  .w-lg-45px {
    width: 45px !important;
  }
  .w-lg-45rem {
    width: 45rem !important;
  }
  .w-lg-46px {
    width: 46px !important;
  }
  .w-lg-46rem {
    width: 46rem !important;
  }
  .w-lg-47px {
    width: 47px !important;
  }
  .w-lg-47rem {
    width: 47rem !important;
  }
  .w-lg-48px {
    width: 48px !important;
  }
  .w-lg-48rem {
    width: 48rem !important;
  }
  .w-lg-49px {
    width: 49px !important;
  }
  .w-lg-49rem {
    width: 49rem !important;
  }
  .w-lg-50px {
    width: 50px !important;
  }
  .w-lg-50rem {
    width: 50rem !important;
  }
  .w-lg-51px {
    width: 51px !important;
  }
  .w-lg-51rem {
    width: 51rem !important;
  }
  .w-lg-52px {
    width: 52px !important;
  }
  .w-lg-52rem {
    width: 52rem !important;
  }
  .w-lg-53px {
    width: 53px !important;
  }
  .w-lg-53rem {
    width: 53rem !important;
  }
  .w-lg-54px {
    width: 54px !important;
  }
  .w-lg-54rem {
    width: 54rem !important;
  }
  .w-lg-55px {
    width: 55px !important;
  }
  .w-lg-55rem {
    width: 55rem !important;
  }
  .w-lg-56px {
    width: 56px !important;
  }
  .w-lg-56rem {
    width: 56rem !important;
  }
  .w-lg-57px {
    width: 57px !important;
  }
  .w-lg-57rem {
    width: 57rem !important;
  }
  .w-lg-58px {
    width: 58px !important;
  }
  .w-lg-58rem {
    width: 58rem !important;
  }
  .w-lg-59px {
    width: 59px !important;
  }
  .w-lg-59rem {
    width: 59rem !important;
  }
  .w-lg-60px {
    width: 60px !important;
  }
  .w-lg-60rem {
    width: 60rem !important;
  }
  .w-lg-61px {
    width: 61px !important;
  }
  .w-lg-61rem {
    width: 61rem !important;
  }
  .w-lg-62px {
    width: 62px !important;
  }
  .w-lg-62rem {
    width: 62rem !important;
  }
  .w-lg-63px {
    width: 63px !important;
  }
  .w-lg-63rem {
    width: 63rem !important;
  }
  .w-lg-64px {
    width: 64px !important;
  }
  .w-lg-64rem {
    width: 64rem !important;
  }
  .w-lg-65px {
    width: 65px !important;
  }
  .w-lg-65rem {
    width: 65rem !important;
  }
  .w-lg-66px {
    width: 66px !important;
  }
  .w-lg-66rem {
    width: 66rem !important;
  }
  .w-lg-67px {
    width: 67px !important;
  }
  .w-lg-67rem {
    width: 67rem !important;
  }
  .w-lg-68px {
    width: 68px !important;
  }
  .w-lg-68rem {
    width: 68rem !important;
  }
  .w-lg-69px {
    width: 69px !important;
  }
  .w-lg-69rem {
    width: 69rem !important;
  }
  .w-lg-70px {
    width: 70px !important;
  }
  .w-lg-70rem {
    width: 70rem !important;
  }
  .w-lg-71px {
    width: 71px !important;
  }
  .w-lg-71rem {
    width: 71rem !important;
  }
  .w-lg-72px {
    width: 72px !important;
  }
  .w-lg-72rem {
    width: 72rem !important;
  }
  .w-lg-73px {
    width: 73px !important;
  }
  .w-lg-73rem {
    width: 73rem !important;
  }
  .w-lg-74px {
    width: 74px !important;
  }
  .w-lg-74rem {
    width: 74rem !important;
  }
  .w-lg-75px {
    width: 75px !important;
  }
  .w-lg-75rem {
    width: 75rem !important;
  }
  .w-lg-76px {
    width: 76px !important;
  }
  .w-lg-76rem {
    width: 76rem !important;
  }
  .w-lg-77px {
    width: 77px !important;
  }
  .w-lg-77rem {
    width: 77rem !important;
  }
  .w-lg-78px {
    width: 78px !important;
  }
  .w-lg-78rem {
    width: 78rem !important;
  }
  .w-lg-79px {
    width: 79px !important;
  }
  .w-lg-79rem {
    width: 79rem !important;
  }
  .w-lg-80px {
    width: 80px !important;
  }
  .w-lg-80rem {
    width: 80rem !important;
  }
  .w-lg-81px {
    width: 81px !important;
  }
  .w-lg-81rem {
    width: 81rem !important;
  }
  .w-lg-82px {
    width: 82px !important;
  }
  .w-lg-82rem {
    width: 82rem !important;
  }
  .w-lg-83px {
    width: 83px !important;
  }
  .w-lg-83rem {
    width: 83rem !important;
  }
  .w-lg-84px {
    width: 84px !important;
  }
  .w-lg-84rem {
    width: 84rem !important;
  }
  .w-lg-85px {
    width: 85px !important;
  }
  .w-lg-85rem {
    width: 85rem !important;
  }
  .w-lg-86px {
    width: 86px !important;
  }
  .w-lg-86rem {
    width: 86rem !important;
  }
  .w-lg-87px {
    width: 87px !important;
  }
  .w-lg-87rem {
    width: 87rem !important;
  }
  .w-lg-88px {
    width: 88px !important;
  }
  .w-lg-88rem {
    width: 88rem !important;
  }
  .w-lg-89px {
    width: 89px !important;
  }
  .w-lg-89rem {
    width: 89rem !important;
  }
  .w-lg-90px {
    width: 90px !important;
  }
  .w-lg-90rem {
    width: 90rem !important;
  }
  .w-lg-91px {
    width: 91px !important;
  }
  .w-lg-91rem {
    width: 91rem !important;
  }
  .w-lg-92px {
    width: 92px !important;
  }
  .w-lg-92rem {
    width: 92rem !important;
  }
  .w-lg-93px {
    width: 93px !important;
  }
  .w-lg-93rem {
    width: 93rem !important;
  }
  .w-lg-94px {
    width: 94px !important;
  }
  .w-lg-94rem {
    width: 94rem !important;
  }
  .w-lg-95px {
    width: 95px !important;
  }
  .w-lg-95rem {
    width: 95rem !important;
  }
  .w-lg-96px {
    width: 96px !important;
  }
  .w-lg-96rem {
    width: 96rem !important;
  }
  .w-lg-97px {
    width: 97px !important;
  }
  .w-lg-97rem {
    width: 97rem !important;
  }
  .w-lg-98px {
    width: 98px !important;
  }
  .w-lg-98rem {
    width: 98rem !important;
  }
  .w-lg-99px {
    width: 99px !important;
  }
  .w-lg-99rem {
    width: 99rem !important;
  }
  .w-lg-100px {
    width: 100px !important;
  }
  .w-lg-100rem {
    width: 100rem !important;
  }
  .h-lg-0px {
    height: 0px !important;
  }
  .h-lg-0rem {
    height: 0rem !important;
  }
  .h-lg-1px {
    height: 1px !important;
  }
  .h-lg-1rem {
    height: 1rem !important;
  }
  .h-lg-2px {
    height: 2px !important;
  }
  .h-lg-2rem {
    height: 2rem !important;
  }
  .h-lg-3px {
    height: 3px !important;
  }
  .h-lg-3rem {
    height: 3rem !important;
  }
  .h-lg-4px {
    height: 4px !important;
  }
  .h-lg-4rem {
    height: 4rem !important;
  }
  .h-lg-5px {
    height: 5px !important;
  }
  .h-lg-5rem {
    height: 5rem !important;
  }
  .h-lg-6px {
    height: 6px !important;
  }
  .h-lg-6rem {
    height: 6rem !important;
  }
  .h-lg-7px {
    height: 7px !important;
  }
  .h-lg-7rem {
    height: 7rem !important;
  }
  .h-lg-8px {
    height: 8px !important;
  }
  .h-lg-8rem {
    height: 8rem !important;
  }
  .h-lg-9px {
    height: 9px !important;
  }
  .h-lg-9rem {
    height: 9rem !important;
  }
  .h-lg-10px {
    height: 10px !important;
  }
  .h-lg-10rem {
    height: 10rem !important;
  }
  .h-lg-11px {
    height: 11px !important;
  }
  .h-lg-11rem {
    height: 11rem !important;
  }
  .h-lg-12px {
    height: 12px !important;
  }
  .h-lg-12rem {
    height: 12rem !important;
  }
  .h-lg-13px {
    height: 13px !important;
  }
  .h-lg-13rem {
    height: 13rem !important;
  }
  .h-lg-14px {
    height: 14px !important;
  }
  .h-lg-14rem {
    height: 14rem !important;
  }
  .h-lg-15px {
    height: 15px !important;
  }
  .h-lg-15rem {
    height: 15rem !important;
  }
  .h-lg-16px {
    height: 16px !important;
  }
  .h-lg-16rem {
    height: 16rem !important;
  }
  .h-lg-17px {
    height: 17px !important;
  }
  .h-lg-17rem {
    height: 17rem !important;
  }
  .h-lg-18px {
    height: 18px !important;
  }
  .h-lg-18rem {
    height: 18rem !important;
  }
  .h-lg-19px {
    height: 19px !important;
  }
  .h-lg-19rem {
    height: 19rem !important;
  }
  .h-lg-20px {
    height: 20px !important;
  }
  .h-lg-20rem {
    height: 20rem !important;
  }
  .h-lg-21px {
    height: 21px !important;
  }
  .h-lg-21rem {
    height: 21rem !important;
  }
  .h-lg-22px {
    height: 22px !important;
  }
  .h-lg-22rem {
    height: 22rem !important;
  }
  .h-lg-23px {
    height: 23px !important;
  }
  .h-lg-23rem {
    height: 23rem !important;
  }
  .h-lg-24px {
    height: 24px !important;
  }
  .h-lg-24rem {
    height: 24rem !important;
  }
  .h-lg-25px {
    height: 25px !important;
  }
  .h-lg-25rem {
    height: 25rem !important;
  }
  .h-lg-26px {
    height: 26px !important;
  }
  .h-lg-26rem {
    height: 26rem !important;
  }
  .h-lg-27px {
    height: 27px !important;
  }
  .h-lg-27rem {
    height: 27rem !important;
  }
  .h-lg-28px {
    height: 28px !important;
  }
  .h-lg-28rem {
    height: 28rem !important;
  }
  .h-lg-29px {
    height: 29px !important;
  }
  .h-lg-29rem {
    height: 29rem !important;
  }
  .h-lg-30px {
    height: 30px !important;
  }
  .h-lg-30rem {
    height: 30rem !important;
  }
  .h-lg-31px {
    height: 31px !important;
  }
  .h-lg-31rem {
    height: 31rem !important;
  }
  .h-lg-32px {
    height: 32px !important;
  }
  .h-lg-32rem {
    height: 32rem !important;
  }
  .h-lg-33px {
    height: 33px !important;
  }
  .h-lg-33rem {
    height: 33rem !important;
  }
  .h-lg-34px {
    height: 34px !important;
  }
  .h-lg-34rem {
    height: 34rem !important;
  }
  .h-lg-35px {
    height: 35px !important;
  }
  .h-lg-35rem {
    height: 35rem !important;
  }
  .h-lg-36px {
    height: 36px !important;
  }
  .h-lg-36rem {
    height: 36rem !important;
  }
  .h-lg-37px {
    height: 37px !important;
  }
  .h-lg-37rem {
    height: 37rem !important;
  }
  .h-lg-38px {
    height: 38px !important;
  }
  .h-lg-38rem {
    height: 38rem !important;
  }
  .h-lg-39px {
    height: 39px !important;
  }
  .h-lg-39rem {
    height: 39rem !important;
  }
  .h-lg-40px {
    height: 40px !important;
  }
  .h-lg-40rem {
    height: 40rem !important;
  }
  .h-lg-41px {
    height: 41px !important;
  }
  .h-lg-41rem {
    height: 41rem !important;
  }
  .h-lg-42px {
    height: 42px !important;
  }
  .h-lg-42rem {
    height: 42rem !important;
  }
  .h-lg-43px {
    height: 43px !important;
  }
  .h-lg-43rem {
    height: 43rem !important;
  }
  .h-lg-44px {
    height: 44px !important;
  }
  .h-lg-44rem {
    height: 44rem !important;
  }
  .h-lg-45px {
    height: 45px !important;
  }
  .h-lg-45rem {
    height: 45rem !important;
  }
  .h-lg-46px {
    height: 46px !important;
  }
  .h-lg-46rem {
    height: 46rem !important;
  }
  .h-lg-47px {
    height: 47px !important;
  }
  .h-lg-47rem {
    height: 47rem !important;
  }
  .h-lg-48px {
    height: 48px !important;
  }
  .h-lg-48rem {
    height: 48rem !important;
  }
  .h-lg-49px {
    height: 49px !important;
  }
  .h-lg-49rem {
    height: 49rem !important;
  }
  .h-lg-50px {
    height: 50px !important;
  }
  .h-lg-50rem {
    height: 50rem !important;
  }
  .h-lg-51px {
    height: 51px !important;
  }
  .h-lg-51rem {
    height: 51rem !important;
  }
  .h-lg-52px {
    height: 52px !important;
  }
  .h-lg-52rem {
    height: 52rem !important;
  }
  .h-lg-53px {
    height: 53px !important;
  }
  .h-lg-53rem {
    height: 53rem !important;
  }
  .h-lg-54px {
    height: 54px !important;
  }
  .h-lg-54rem {
    height: 54rem !important;
  }
  .h-lg-55px {
    height: 55px !important;
  }
  .h-lg-55rem {
    height: 55rem !important;
  }
  .h-lg-56px {
    height: 56px !important;
  }
  .h-lg-56rem {
    height: 56rem !important;
  }
  .h-lg-57px {
    height: 57px !important;
  }
  .h-lg-57rem {
    height: 57rem !important;
  }
  .h-lg-58px {
    height: 58px !important;
  }
  .h-lg-58rem {
    height: 58rem !important;
  }
  .h-lg-59px {
    height: 59px !important;
  }
  .h-lg-59rem {
    height: 59rem !important;
  }
  .h-lg-60px {
    height: 60px !important;
  }
  .h-lg-60rem {
    height: 60rem !important;
  }
  .h-lg-61px {
    height: 61px !important;
  }
  .h-lg-61rem {
    height: 61rem !important;
  }
  .h-lg-62px {
    height: 62px !important;
  }
  .h-lg-62rem {
    height: 62rem !important;
  }
  .h-lg-63px {
    height: 63px !important;
  }
  .h-lg-63rem {
    height: 63rem !important;
  }
  .h-lg-64px {
    height: 64px !important;
  }
  .h-lg-64rem {
    height: 64rem !important;
  }
  .h-lg-65px {
    height: 65px !important;
  }
  .h-lg-65rem {
    height: 65rem !important;
  }
  .h-lg-66px {
    height: 66px !important;
  }
  .h-lg-66rem {
    height: 66rem !important;
  }
  .h-lg-67px {
    height: 67px !important;
  }
  .h-lg-67rem {
    height: 67rem !important;
  }
  .h-lg-68px {
    height: 68px !important;
  }
  .h-lg-68rem {
    height: 68rem !important;
  }
  .h-lg-69px {
    height: 69px !important;
  }
  .h-lg-69rem {
    height: 69rem !important;
  }
  .h-lg-70px {
    height: 70px !important;
  }
  .h-lg-70rem {
    height: 70rem !important;
  }
  .h-lg-71px {
    height: 71px !important;
  }
  .h-lg-71rem {
    height: 71rem !important;
  }
  .h-lg-72px {
    height: 72px !important;
  }
  .h-lg-72rem {
    height: 72rem !important;
  }
  .h-lg-73px {
    height: 73px !important;
  }
  .h-lg-73rem {
    height: 73rem !important;
  }
  .h-lg-74px {
    height: 74px !important;
  }
  .h-lg-74rem {
    height: 74rem !important;
  }
  .h-lg-75px {
    height: 75px !important;
  }
  .h-lg-75rem {
    height: 75rem !important;
  }
  .h-lg-76px {
    height: 76px !important;
  }
  .h-lg-76rem {
    height: 76rem !important;
  }
  .h-lg-77px {
    height: 77px !important;
  }
  .h-lg-77rem {
    height: 77rem !important;
  }
  .h-lg-78px {
    height: 78px !important;
  }
  .h-lg-78rem {
    height: 78rem !important;
  }
  .h-lg-79px {
    height: 79px !important;
  }
  .h-lg-79rem {
    height: 79rem !important;
  }
  .h-lg-80px {
    height: 80px !important;
  }
  .h-lg-80rem {
    height: 80rem !important;
  }
  .h-lg-81px {
    height: 81px !important;
  }
  .h-lg-81rem {
    height: 81rem !important;
  }
  .h-lg-82px {
    height: 82px !important;
  }
  .h-lg-82rem {
    height: 82rem !important;
  }
  .h-lg-83px {
    height: 83px !important;
  }
  .h-lg-83rem {
    height: 83rem !important;
  }
  .h-lg-84px {
    height: 84px !important;
  }
  .h-lg-84rem {
    height: 84rem !important;
  }
  .h-lg-85px {
    height: 85px !important;
  }
  .h-lg-85rem {
    height: 85rem !important;
  }
  .h-lg-86px {
    height: 86px !important;
  }
  .h-lg-86rem {
    height: 86rem !important;
  }
  .h-lg-87px {
    height: 87px !important;
  }
  .h-lg-87rem {
    height: 87rem !important;
  }
  .h-lg-88px {
    height: 88px !important;
  }
  .h-lg-88rem {
    height: 88rem !important;
  }
  .h-lg-89px {
    height: 89px !important;
  }
  .h-lg-89rem {
    height: 89rem !important;
  }
  .h-lg-90px {
    height: 90px !important;
  }
  .h-lg-90rem {
    height: 90rem !important;
  }
  .h-lg-91px {
    height: 91px !important;
  }
  .h-lg-91rem {
    height: 91rem !important;
  }
  .h-lg-92px {
    height: 92px !important;
  }
  .h-lg-92rem {
    height: 92rem !important;
  }
  .h-lg-93px {
    height: 93px !important;
  }
  .h-lg-93rem {
    height: 93rem !important;
  }
  .h-lg-94px {
    height: 94px !important;
  }
  .h-lg-94rem {
    height: 94rem !important;
  }
  .h-lg-95px {
    height: 95px !important;
  }
  .h-lg-95rem {
    height: 95rem !important;
  }
  .h-lg-96px {
    height: 96px !important;
  }
  .h-lg-96rem {
    height: 96rem !important;
  }
  .h-lg-97px {
    height: 97px !important;
  }
  .h-lg-97rem {
    height: 97rem !important;
  }
  .h-lg-98px {
    height: 98px !important;
  }
  .h-lg-98rem {
    height: 98rem !important;
  }
  .h-lg-99px {
    height: 99px !important;
  }
  .h-lg-99rem {
    height: 99rem !important;
  }
  .h-lg-100px {
    height: 100px !important;
  }
  .h-lg-100rem {
    height: 100rem !important;
  }
  .t-lg-0px {
    top: 0px !important;
  }
  .t-lg-0rem {
    top: 0rem !important;
  }
  .t-lg-1px {
    top: 1px !important;
  }
  .t-lg-1rem {
    top: 1rem !important;
  }
  .t-lg-2px {
    top: 2px !important;
  }
  .t-lg-2rem {
    top: 2rem !important;
  }
  .t-lg-3px {
    top: 3px !important;
  }
  .t-lg-3rem {
    top: 3rem !important;
  }
  .t-lg-4px {
    top: 4px !important;
  }
  .t-lg-4rem {
    top: 4rem !important;
  }
  .t-lg-5px {
    top: 5px !important;
  }
  .t-lg-5rem {
    top: 5rem !important;
  }
  .t-lg-6px {
    top: 6px !important;
  }
  .t-lg-6rem {
    top: 6rem !important;
  }
  .t-lg-7px {
    top: 7px !important;
  }
  .t-lg-7rem {
    top: 7rem !important;
  }
  .t-lg-8px {
    top: 8px !important;
  }
  .t-lg-8rem {
    top: 8rem !important;
  }
  .t-lg-9px {
    top: 9px !important;
  }
  .t-lg-9rem {
    top: 9rem !important;
  }
  .t-lg-10px {
    top: 10px !important;
  }
  .t-lg-10rem {
    top: 10rem !important;
  }
  .t-lg-11px {
    top: 11px !important;
  }
  .t-lg-11rem {
    top: 11rem !important;
  }
  .t-lg-12px {
    top: 12px !important;
  }
  .t-lg-12rem {
    top: 12rem !important;
  }
  .t-lg-13px {
    top: 13px !important;
  }
  .t-lg-13rem {
    top: 13rem !important;
  }
  .t-lg-14px {
    top: 14px !important;
  }
  .t-lg-14rem {
    top: 14rem !important;
  }
  .t-lg-15px {
    top: 15px !important;
  }
  .t-lg-15rem {
    top: 15rem !important;
  }
  .t-lg-16px {
    top: 16px !important;
  }
  .t-lg-16rem {
    top: 16rem !important;
  }
  .t-lg-17px {
    top: 17px !important;
  }
  .t-lg-17rem {
    top: 17rem !important;
  }
  .t-lg-18px {
    top: 18px !important;
  }
  .t-lg-18rem {
    top: 18rem !important;
  }
  .t-lg-19px {
    top: 19px !important;
  }
  .t-lg-19rem {
    top: 19rem !important;
  }
  .t-lg-20px {
    top: 20px !important;
  }
  .t-lg-20rem {
    top: 20rem !important;
  }
  .t-lg-21px {
    top: 21px !important;
  }
  .t-lg-21rem {
    top: 21rem !important;
  }
  .t-lg-22px {
    top: 22px !important;
  }
  .t-lg-22rem {
    top: 22rem !important;
  }
  .t-lg-23px {
    top: 23px !important;
  }
  .t-lg-23rem {
    top: 23rem !important;
  }
  .t-lg-24px {
    top: 24px !important;
  }
  .t-lg-24rem {
    top: 24rem !important;
  }
  .t-lg-25px {
    top: 25px !important;
  }
  .t-lg-25rem {
    top: 25rem !important;
  }
  .t-lg-26px {
    top: 26px !important;
  }
  .t-lg-26rem {
    top: 26rem !important;
  }
  .t-lg-27px {
    top: 27px !important;
  }
  .t-lg-27rem {
    top: 27rem !important;
  }
  .t-lg-28px {
    top: 28px !important;
  }
  .t-lg-28rem {
    top: 28rem !important;
  }
  .t-lg-29px {
    top: 29px !important;
  }
  .t-lg-29rem {
    top: 29rem !important;
  }
  .t-lg-30px {
    top: 30px !important;
  }
  .t-lg-30rem {
    top: 30rem !important;
  }
  .t-lg-31px {
    top: 31px !important;
  }
  .t-lg-31rem {
    top: 31rem !important;
  }
  .t-lg-32px {
    top: 32px !important;
  }
  .t-lg-32rem {
    top: 32rem !important;
  }
  .t-lg-33px {
    top: 33px !important;
  }
  .t-lg-33rem {
    top: 33rem !important;
  }
  .t-lg-34px {
    top: 34px !important;
  }
  .t-lg-34rem {
    top: 34rem !important;
  }
  .t-lg-35px {
    top: 35px !important;
  }
  .t-lg-35rem {
    top: 35rem !important;
  }
  .t-lg-36px {
    top: 36px !important;
  }
  .t-lg-36rem {
    top: 36rem !important;
  }
  .t-lg-37px {
    top: 37px !important;
  }
  .t-lg-37rem {
    top: 37rem !important;
  }
  .t-lg-38px {
    top: 38px !important;
  }
  .t-lg-38rem {
    top: 38rem !important;
  }
  .t-lg-39px {
    top: 39px !important;
  }
  .t-lg-39rem {
    top: 39rem !important;
  }
  .t-lg-40px {
    top: 40px !important;
  }
  .t-lg-40rem {
    top: 40rem !important;
  }
  .t-lg-41px {
    top: 41px !important;
  }
  .t-lg-41rem {
    top: 41rem !important;
  }
  .t-lg-42px {
    top: 42px !important;
  }
  .t-lg-42rem {
    top: 42rem !important;
  }
  .t-lg-43px {
    top: 43px !important;
  }
  .t-lg-43rem {
    top: 43rem !important;
  }
  .t-lg-44px {
    top: 44px !important;
  }
  .t-lg-44rem {
    top: 44rem !important;
  }
  .t-lg-45px {
    top: 45px !important;
  }
  .t-lg-45rem {
    top: 45rem !important;
  }
  .t-lg-46px {
    top: 46px !important;
  }
  .t-lg-46rem {
    top: 46rem !important;
  }
  .t-lg-47px {
    top: 47px !important;
  }
  .t-lg-47rem {
    top: 47rem !important;
  }
  .t-lg-48px {
    top: 48px !important;
  }
  .t-lg-48rem {
    top: 48rem !important;
  }
  .t-lg-49px {
    top: 49px !important;
  }
  .t-lg-49rem {
    top: 49rem !important;
  }
  .t-lg-50px {
    top: 50px !important;
  }
  .t-lg-50rem {
    top: 50rem !important;
  }
  .t-lg-51px {
    top: 51px !important;
  }
  .t-lg-51rem {
    top: 51rem !important;
  }
  .t-lg-52px {
    top: 52px !important;
  }
  .t-lg-52rem {
    top: 52rem !important;
  }
  .t-lg-53px {
    top: 53px !important;
  }
  .t-lg-53rem {
    top: 53rem !important;
  }
  .t-lg-54px {
    top: 54px !important;
  }
  .t-lg-54rem {
    top: 54rem !important;
  }
  .t-lg-55px {
    top: 55px !important;
  }
  .t-lg-55rem {
    top: 55rem !important;
  }
  .t-lg-56px {
    top: 56px !important;
  }
  .t-lg-56rem {
    top: 56rem !important;
  }
  .t-lg-57px {
    top: 57px !important;
  }
  .t-lg-57rem {
    top: 57rem !important;
  }
  .t-lg-58px {
    top: 58px !important;
  }
  .t-lg-58rem {
    top: 58rem !important;
  }
  .t-lg-59px {
    top: 59px !important;
  }
  .t-lg-59rem {
    top: 59rem !important;
  }
  .t-lg-60px {
    top: 60px !important;
  }
  .t-lg-60rem {
    top: 60rem !important;
  }
  .t-lg-61px {
    top: 61px !important;
  }
  .t-lg-61rem {
    top: 61rem !important;
  }
  .t-lg-62px {
    top: 62px !important;
  }
  .t-lg-62rem {
    top: 62rem !important;
  }
  .t-lg-63px {
    top: 63px !important;
  }
  .t-lg-63rem {
    top: 63rem !important;
  }
  .t-lg-64px {
    top: 64px !important;
  }
  .t-lg-64rem {
    top: 64rem !important;
  }
  .t-lg-65px {
    top: 65px !important;
  }
  .t-lg-65rem {
    top: 65rem !important;
  }
  .t-lg-66px {
    top: 66px !important;
  }
  .t-lg-66rem {
    top: 66rem !important;
  }
  .t-lg-67px {
    top: 67px !important;
  }
  .t-lg-67rem {
    top: 67rem !important;
  }
  .t-lg-68px {
    top: 68px !important;
  }
  .t-lg-68rem {
    top: 68rem !important;
  }
  .t-lg-69px {
    top: 69px !important;
  }
  .t-lg-69rem {
    top: 69rem !important;
  }
  .t-lg-70px {
    top: 70px !important;
  }
  .t-lg-70rem {
    top: 70rem !important;
  }
  .t-lg-71px {
    top: 71px !important;
  }
  .t-lg-71rem {
    top: 71rem !important;
  }
  .t-lg-72px {
    top: 72px !important;
  }
  .t-lg-72rem {
    top: 72rem !important;
  }
  .t-lg-73px {
    top: 73px !important;
  }
  .t-lg-73rem {
    top: 73rem !important;
  }
  .t-lg-74px {
    top: 74px !important;
  }
  .t-lg-74rem {
    top: 74rem !important;
  }
  .t-lg-75px {
    top: 75px !important;
  }
  .t-lg-75rem {
    top: 75rem !important;
  }
  .t-lg-76px {
    top: 76px !important;
  }
  .t-lg-76rem {
    top: 76rem !important;
  }
  .t-lg-77px {
    top: 77px !important;
  }
  .t-lg-77rem {
    top: 77rem !important;
  }
  .t-lg-78px {
    top: 78px !important;
  }
  .t-lg-78rem {
    top: 78rem !important;
  }
  .t-lg-79px {
    top: 79px !important;
  }
  .t-lg-79rem {
    top: 79rem !important;
  }
  .t-lg-80px {
    top: 80px !important;
  }
  .t-lg-80rem {
    top: 80rem !important;
  }
  .t-lg-81px {
    top: 81px !important;
  }
  .t-lg-81rem {
    top: 81rem !important;
  }
  .t-lg-82px {
    top: 82px !important;
  }
  .t-lg-82rem {
    top: 82rem !important;
  }
  .t-lg-83px {
    top: 83px !important;
  }
  .t-lg-83rem {
    top: 83rem !important;
  }
  .t-lg-84px {
    top: 84px !important;
  }
  .t-lg-84rem {
    top: 84rem !important;
  }
  .t-lg-85px {
    top: 85px !important;
  }
  .t-lg-85rem {
    top: 85rem !important;
  }
  .t-lg-86px {
    top: 86px !important;
  }
  .t-lg-86rem {
    top: 86rem !important;
  }
  .t-lg-87px {
    top: 87px !important;
  }
  .t-lg-87rem {
    top: 87rem !important;
  }
  .t-lg-88px {
    top: 88px !important;
  }
  .t-lg-88rem {
    top: 88rem !important;
  }
  .t-lg-89px {
    top: 89px !important;
  }
  .t-lg-89rem {
    top: 89rem !important;
  }
  .t-lg-90px {
    top: 90px !important;
  }
  .t-lg-90rem {
    top: 90rem !important;
  }
  .t-lg-91px {
    top: 91px !important;
  }
  .t-lg-91rem {
    top: 91rem !important;
  }
  .t-lg-92px {
    top: 92px !important;
  }
  .t-lg-92rem {
    top: 92rem !important;
  }
  .t-lg-93px {
    top: 93px !important;
  }
  .t-lg-93rem {
    top: 93rem !important;
  }
  .t-lg-94px {
    top: 94px !important;
  }
  .t-lg-94rem {
    top: 94rem !important;
  }
  .t-lg-95px {
    top: 95px !important;
  }
  .t-lg-95rem {
    top: 95rem !important;
  }
  .t-lg-96px {
    top: 96px !important;
  }
  .t-lg-96rem {
    top: 96rem !important;
  }
  .t-lg-97px {
    top: 97px !important;
  }
  .t-lg-97rem {
    top: 97rem !important;
  }
  .t-lg-98px {
    top: 98px !important;
  }
  .t-lg-98rem {
    top: 98rem !important;
  }
  .t-lg-99px {
    top: 99px !important;
  }
  .t-lg-99rem {
    top: 99rem !important;
  }
  .t-lg-100px {
    top: 100px !important;
  }
  .t-lg-100rem {
    top: 100rem !important;
  }
  .l-lg-0px {
    left: 0px !important;
  }
  .l-lg-0rem {
    left: 0rem !important;
  }
  .l-lg-1px {
    left: 1px !important;
  }
  .l-lg-1rem {
    left: 1rem !important;
  }
  .l-lg-2px {
    left: 2px !important;
  }
  .l-lg-2rem {
    left: 2rem !important;
  }
  .l-lg-3px {
    left: 3px !important;
  }
  .l-lg-3rem {
    left: 3rem !important;
  }
  .l-lg-4px {
    left: 4px !important;
  }
  .l-lg-4rem {
    left: 4rem !important;
  }
  .l-lg-5px {
    left: 5px !important;
  }
  .l-lg-5rem {
    left: 5rem !important;
  }
  .l-lg-6px {
    left: 6px !important;
  }
  .l-lg-6rem {
    left: 6rem !important;
  }
  .l-lg-7px {
    left: 7px !important;
  }
  .l-lg-7rem {
    left: 7rem !important;
  }
  .l-lg-8px {
    left: 8px !important;
  }
  .l-lg-8rem {
    left: 8rem !important;
  }
  .l-lg-9px {
    left: 9px !important;
  }
  .l-lg-9rem {
    left: 9rem !important;
  }
  .l-lg-10px {
    left: 10px !important;
  }
  .l-lg-10rem {
    left: 10rem !important;
  }
  .l-lg-11px {
    left: 11px !important;
  }
  .l-lg-11rem {
    left: 11rem !important;
  }
  .l-lg-12px {
    left: 12px !important;
  }
  .l-lg-12rem {
    left: 12rem !important;
  }
  .l-lg-13px {
    left: 13px !important;
  }
  .l-lg-13rem {
    left: 13rem !important;
  }
  .l-lg-14px {
    left: 14px !important;
  }
  .l-lg-14rem {
    left: 14rem !important;
  }
  .l-lg-15px {
    left: 15px !important;
  }
  .l-lg-15rem {
    left: 15rem !important;
  }
  .l-lg-16px {
    left: 16px !important;
  }
  .l-lg-16rem {
    left: 16rem !important;
  }
  .l-lg-17px {
    left: 17px !important;
  }
  .l-lg-17rem {
    left: 17rem !important;
  }
  .l-lg-18px {
    left: 18px !important;
  }
  .l-lg-18rem {
    left: 18rem !important;
  }
  .l-lg-19px {
    left: 19px !important;
  }
  .l-lg-19rem {
    left: 19rem !important;
  }
  .l-lg-20px {
    left: 20px !important;
  }
  .l-lg-20rem {
    left: 20rem !important;
  }
  .l-lg-21px {
    left: 21px !important;
  }
  .l-lg-21rem {
    left: 21rem !important;
  }
  .l-lg-22px {
    left: 22px !important;
  }
  .l-lg-22rem {
    left: 22rem !important;
  }
  .l-lg-23px {
    left: 23px !important;
  }
  .l-lg-23rem {
    left: 23rem !important;
  }
  .l-lg-24px {
    left: 24px !important;
  }
  .l-lg-24rem {
    left: 24rem !important;
  }
  .l-lg-25px {
    left: 25px !important;
  }
  .l-lg-25rem {
    left: 25rem !important;
  }
  .l-lg-26px {
    left: 26px !important;
  }
  .l-lg-26rem {
    left: 26rem !important;
  }
  .l-lg-27px {
    left: 27px !important;
  }
  .l-lg-27rem {
    left: 27rem !important;
  }
  .l-lg-28px {
    left: 28px !important;
  }
  .l-lg-28rem {
    left: 28rem !important;
  }
  .l-lg-29px {
    left: 29px !important;
  }
  .l-lg-29rem {
    left: 29rem !important;
  }
  .l-lg-30px {
    left: 30px !important;
  }
  .l-lg-30rem {
    left: 30rem !important;
  }
  .l-lg-31px {
    left: 31px !important;
  }
  .l-lg-31rem {
    left: 31rem !important;
  }
  .l-lg-32px {
    left: 32px !important;
  }
  .l-lg-32rem {
    left: 32rem !important;
  }
  .l-lg-33px {
    left: 33px !important;
  }
  .l-lg-33rem {
    left: 33rem !important;
  }
  .l-lg-34px {
    left: 34px !important;
  }
  .l-lg-34rem {
    left: 34rem !important;
  }
  .l-lg-35px {
    left: 35px !important;
  }
  .l-lg-35rem {
    left: 35rem !important;
  }
  .l-lg-36px {
    left: 36px !important;
  }
  .l-lg-36rem {
    left: 36rem !important;
  }
  .l-lg-37px {
    left: 37px !important;
  }
  .l-lg-37rem {
    left: 37rem !important;
  }
  .l-lg-38px {
    left: 38px !important;
  }
  .l-lg-38rem {
    left: 38rem !important;
  }
  .l-lg-39px {
    left: 39px !important;
  }
  .l-lg-39rem {
    left: 39rem !important;
  }
  .l-lg-40px {
    left: 40px !important;
  }
  .l-lg-40rem {
    left: 40rem !important;
  }
  .l-lg-41px {
    left: 41px !important;
  }
  .l-lg-41rem {
    left: 41rem !important;
  }
  .l-lg-42px {
    left: 42px !important;
  }
  .l-lg-42rem {
    left: 42rem !important;
  }
  .l-lg-43px {
    left: 43px !important;
  }
  .l-lg-43rem {
    left: 43rem !important;
  }
  .l-lg-44px {
    left: 44px !important;
  }
  .l-lg-44rem {
    left: 44rem !important;
  }
  .l-lg-45px {
    left: 45px !important;
  }
  .l-lg-45rem {
    left: 45rem !important;
  }
  .l-lg-46px {
    left: 46px !important;
  }
  .l-lg-46rem {
    left: 46rem !important;
  }
  .l-lg-47px {
    left: 47px !important;
  }
  .l-lg-47rem {
    left: 47rem !important;
  }
  .l-lg-48px {
    left: 48px !important;
  }
  .l-lg-48rem {
    left: 48rem !important;
  }
  .l-lg-49px {
    left: 49px !important;
  }
  .l-lg-49rem {
    left: 49rem !important;
  }
  .l-lg-50px {
    left: 50px !important;
  }
  .l-lg-50rem {
    left: 50rem !important;
  }
  .l-lg-51px {
    left: 51px !important;
  }
  .l-lg-51rem {
    left: 51rem !important;
  }
  .l-lg-52px {
    left: 52px !important;
  }
  .l-lg-52rem {
    left: 52rem !important;
  }
  .l-lg-53px {
    left: 53px !important;
  }
  .l-lg-53rem {
    left: 53rem !important;
  }
  .l-lg-54px {
    left: 54px !important;
  }
  .l-lg-54rem {
    left: 54rem !important;
  }
  .l-lg-55px {
    left: 55px !important;
  }
  .l-lg-55rem {
    left: 55rem !important;
  }
  .l-lg-56px {
    left: 56px !important;
  }
  .l-lg-56rem {
    left: 56rem !important;
  }
  .l-lg-57px {
    left: 57px !important;
  }
  .l-lg-57rem {
    left: 57rem !important;
  }
  .l-lg-58px {
    left: 58px !important;
  }
  .l-lg-58rem {
    left: 58rem !important;
  }
  .l-lg-59px {
    left: 59px !important;
  }
  .l-lg-59rem {
    left: 59rem !important;
  }
  .l-lg-60px {
    left: 60px !important;
  }
  .l-lg-60rem {
    left: 60rem !important;
  }
  .l-lg-61px {
    left: 61px !important;
  }
  .l-lg-61rem {
    left: 61rem !important;
  }
  .l-lg-62px {
    left: 62px !important;
  }
  .l-lg-62rem {
    left: 62rem !important;
  }
  .l-lg-63px {
    left: 63px !important;
  }
  .l-lg-63rem {
    left: 63rem !important;
  }
  .l-lg-64px {
    left: 64px !important;
  }
  .l-lg-64rem {
    left: 64rem !important;
  }
  .l-lg-65px {
    left: 65px !important;
  }
  .l-lg-65rem {
    left: 65rem !important;
  }
  .l-lg-66px {
    left: 66px !important;
  }
  .l-lg-66rem {
    left: 66rem !important;
  }
  .l-lg-67px {
    left: 67px !important;
  }
  .l-lg-67rem {
    left: 67rem !important;
  }
  .l-lg-68px {
    left: 68px !important;
  }
  .l-lg-68rem {
    left: 68rem !important;
  }
  .l-lg-69px {
    left: 69px !important;
  }
  .l-lg-69rem {
    left: 69rem !important;
  }
  .l-lg-70px {
    left: 70px !important;
  }
  .l-lg-70rem {
    left: 70rem !important;
  }
  .l-lg-71px {
    left: 71px !important;
  }
  .l-lg-71rem {
    left: 71rem !important;
  }
  .l-lg-72px {
    left: 72px !important;
  }
  .l-lg-72rem {
    left: 72rem !important;
  }
  .l-lg-73px {
    left: 73px !important;
  }
  .l-lg-73rem {
    left: 73rem !important;
  }
  .l-lg-74px {
    left: 74px !important;
  }
  .l-lg-74rem {
    left: 74rem !important;
  }
  .l-lg-75px {
    left: 75px !important;
  }
  .l-lg-75rem {
    left: 75rem !important;
  }
  .l-lg-76px {
    left: 76px !important;
  }
  .l-lg-76rem {
    left: 76rem !important;
  }
  .l-lg-77px {
    left: 77px !important;
  }
  .l-lg-77rem {
    left: 77rem !important;
  }
  .l-lg-78px {
    left: 78px !important;
  }
  .l-lg-78rem {
    left: 78rem !important;
  }
  .l-lg-79px {
    left: 79px !important;
  }
  .l-lg-79rem {
    left: 79rem !important;
  }
  .l-lg-80px {
    left: 80px !important;
  }
  .l-lg-80rem {
    left: 80rem !important;
  }
  .l-lg-81px {
    left: 81px !important;
  }
  .l-lg-81rem {
    left: 81rem !important;
  }
  .l-lg-82px {
    left: 82px !important;
  }
  .l-lg-82rem {
    left: 82rem !important;
  }
  .l-lg-83px {
    left: 83px !important;
  }
  .l-lg-83rem {
    left: 83rem !important;
  }
  .l-lg-84px {
    left: 84px !important;
  }
  .l-lg-84rem {
    left: 84rem !important;
  }
  .l-lg-85px {
    left: 85px !important;
  }
  .l-lg-85rem {
    left: 85rem !important;
  }
  .l-lg-86px {
    left: 86px !important;
  }
  .l-lg-86rem {
    left: 86rem !important;
  }
  .l-lg-87px {
    left: 87px !important;
  }
  .l-lg-87rem {
    left: 87rem !important;
  }
  .l-lg-88px {
    left: 88px !important;
  }
  .l-lg-88rem {
    left: 88rem !important;
  }
  .l-lg-89px {
    left: 89px !important;
  }
  .l-lg-89rem {
    left: 89rem !important;
  }
  .l-lg-90px {
    left: 90px !important;
  }
  .l-lg-90rem {
    left: 90rem !important;
  }
  .l-lg-91px {
    left: 91px !important;
  }
  .l-lg-91rem {
    left: 91rem !important;
  }
  .l-lg-92px {
    left: 92px !important;
  }
  .l-lg-92rem {
    left: 92rem !important;
  }
  .l-lg-93px {
    left: 93px !important;
  }
  .l-lg-93rem {
    left: 93rem !important;
  }
  .l-lg-94px {
    left: 94px !important;
  }
  .l-lg-94rem {
    left: 94rem !important;
  }
  .l-lg-95px {
    left: 95px !important;
  }
  .l-lg-95rem {
    left: 95rem !important;
  }
  .l-lg-96px {
    left: 96px !important;
  }
  .l-lg-96rem {
    left: 96rem !important;
  }
  .l-lg-97px {
    left: 97px !important;
  }
  .l-lg-97rem {
    left: 97rem !important;
  }
  .l-lg-98px {
    left: 98px !important;
  }
  .l-lg-98rem {
    left: 98rem !important;
  }
  .l-lg-99px {
    left: 99px !important;
  }
  .l-lg-99rem {
    left: 99rem !important;
  }
  .l-lg-100px {
    left: 100px !important;
  }
  .l-lg-100rem {
    left: 100rem !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .pt-lg-0px {
    padding-top: 0px !important;
  }
  .pb-lg-0px {
    padding-bottom: 0px !important;
  }
  .pt-lg-5px {
    padding-top: 5px !important;
  }
  .pb-lg-5px {
    padding-bottom: 5px !important;
  }
  .pt-lg-10px {
    padding-top: 10px !important;
  }
  .pb-lg-10px {
    padding-bottom: 10px !important;
  }
  .pt-lg-15px {
    padding-top: 15px !important;
  }
  .pb-lg-15px {
    padding-bottom: 15px !important;
  }
  .pt-lg-20px {
    padding-top: 20px !important;
  }
  .pb-lg-20px {
    padding-bottom: 20px !important;
  }
  .pt-lg-25px {
    padding-top: 25px !important;
  }
  .pb-lg-25px {
    padding-bottom: 25px !important;
  }
  .pt-lg-30px {
    padding-top: 30px !important;
  }
  .pb-lg-30px {
    padding-bottom: 30px !important;
  }
  .pt-lg-35px {
    padding-top: 35px !important;
  }
  .pb-lg-35px {
    padding-bottom: 35px !important;
  }
  .pt-lg-40px {
    padding-top: 40px !important;
  }
  .pb-lg-40px {
    padding-bottom: 40px !important;
  }
  .pt-lg-45px {
    padding-top: 45px !important;
  }
  .pb-lg-45px {
    padding-bottom: 45px !important;
  }
  .pt-lg-50px {
    padding-top: 50px !important;
  }
  .pb-lg-50px {
    padding-bottom: 50px !important;
  }
  .pt-lg-55px {
    padding-top: 55px !important;
  }
  .pb-lg-55px {
    padding-bottom: 55px !important;
  }
  .pt-lg-60px {
    padding-top: 60px !important;
  }
  .pb-lg-60px {
    padding-bottom: 60px !important;
  }
  .pt-lg-65px {
    padding-top: 65px !important;
  }
  .pb-lg-65px {
    padding-bottom: 65px !important;
  }
  .pt-lg-70px {
    padding-top: 70px !important;
  }
  .pb-lg-70px {
    padding-bottom: 70px !important;
  }
  .pt-lg-75px {
    padding-top: 75px !important;
  }
  .pb-lg-75px {
    padding-bottom: 75px !important;
  }
  .pt-lg-80px {
    padding-top: 80px !important;
  }
  .pb-lg-80px {
    padding-bottom: 80px !important;
  }
  .pt-lg-85px {
    padding-top: 85px !important;
  }
  .pb-lg-85px {
    padding-bottom: 85px !important;
  }
  .pt-lg-90px {
    padding-top: 90px !important;
  }
  .pb-lg-90px {
    padding-bottom: 90px !important;
  }
  .pt-lg-95px {
    padding-top: 95px !important;
  }
  .pb-lg-95px {
    padding-bottom: 95px !important;
  }
  .pt-lg-100px {
    padding-top: 100px !important;
  }
  .pb-lg-100px {
    padding-bottom: 100px !important;
  }
  .mt-lg-0px {
    margin-top: 0px !important;
  }
  .mb-lg-0px {
    margin-bottom: 0px !important;
  }
  .mt-lg-5px {
    margin-top: 5px !important;
  }
  .mb-lg-5px {
    margin-bottom: 5px !important;
  }
  .mt-lg-10px {
    margin-top: 10px !important;
  }
  .mb-lg-10px {
    margin-bottom: 10px !important;
  }
  .mt-lg-15px {
    margin-top: 15px !important;
  }
  .mb-lg-15px {
    margin-bottom: 15px !important;
  }
  .mt-lg-20px {
    margin-top: 20px !important;
  }
  .mb-lg-20px {
    margin-bottom: 20px !important;
  }
  .mt-lg-25px {
    margin-top: 25px !important;
  }
  .mb-lg-25px {
    margin-bottom: 25px !important;
  }
  .mt-lg-30px {
    margin-top: 30px !important;
  }
  .mb-lg-30px {
    margin-bottom: 30px !important;
  }
  .mt-lg-35px {
    margin-top: 35px !important;
  }
  .mb-lg-35px {
    margin-bottom: 35px !important;
  }
  .mt-lg-40px {
    margin-top: 40px !important;
  }
  .mb-lg-40px {
    margin-bottom: 40px !important;
  }
  .mt-lg-45px {
    margin-top: 45px !important;
  }
  .mb-lg-45px {
    margin-bottom: 45px !important;
  }
  .mt-lg-50px {
    margin-top: 50px !important;
  }
  .mb-lg-50px {
    margin-bottom: 50px !important;
  }
  .mt-lg-55px {
    margin-top: 55px !important;
  }
  .mb-lg-55px {
    margin-bottom: 55px !important;
  }
  .mt-lg-60px {
    margin-top: 60px !important;
  }
  .mb-lg-60px {
    margin-bottom: 60px !important;
  }
  .mt-lg-65px {
    margin-top: 65px !important;
  }
  .mb-lg-65px {
    margin-bottom: 65px !important;
  }
  .mt-lg-70px {
    margin-top: 70px !important;
  }
  .mb-lg-70px {
    margin-bottom: 70px !important;
  }
  .mt-lg-75px {
    margin-top: 75px !important;
  }
  .mb-lg-75px {
    margin-bottom: 75px !important;
  }
  .mt-lg-80px {
    margin-top: 80px !important;
  }
  .mb-lg-80px {
    margin-bottom: 80px !important;
  }
  .mt-lg-85px {
    margin-top: 85px !important;
  }
  .mb-lg-85px {
    margin-bottom: 85px !important;
  }
  .mt-lg-90px {
    margin-top: 90px !important;
  }
  .mb-lg-90px {
    margin-bottom: 90px !important;
  }
  .mt-lg-95px {
    margin-top: 95px !important;
  }
  .mb-lg-95px {
    margin-bottom: 95px !important;
  }
  .mt-lg-100px {
    margin-top: 100px !important;
  }
  .mb-lg-100px {
    margin-bottom: 100px !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-top-lg-1px-s-2f2f2f {
    border-top: 1px solid #2f2f2f !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-table-row {
    display: table-row !important;
  }
  .fixed-xl-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .order-xl-initial {
    -ms-flex-order: initial;
        order: initial;
  }
  .w-xl-0px {
    width: 0px !important;
  }
  .w-xl-0rem {
    width: 0rem !important;
  }
  .w-xl-1px {
    width: 1px !important;
  }
  .w-xl-1rem {
    width: 1rem !important;
  }
  .w-xl-2px {
    width: 2px !important;
  }
  .w-xl-2rem {
    width: 2rem !important;
  }
  .w-xl-3px {
    width: 3px !important;
  }
  .w-xl-3rem {
    width: 3rem !important;
  }
  .w-xl-4px {
    width: 4px !important;
  }
  .w-xl-4rem {
    width: 4rem !important;
  }
  .w-xl-5px {
    width: 5px !important;
  }
  .w-xl-5rem {
    width: 5rem !important;
  }
  .w-xl-6px {
    width: 6px !important;
  }
  .w-xl-6rem {
    width: 6rem !important;
  }
  .w-xl-7px {
    width: 7px !important;
  }
  .w-xl-7rem {
    width: 7rem !important;
  }
  .w-xl-8px {
    width: 8px !important;
  }
  .w-xl-8rem {
    width: 8rem !important;
  }
  .w-xl-9px {
    width: 9px !important;
  }
  .w-xl-9rem {
    width: 9rem !important;
  }
  .w-xl-10px {
    width: 10px !important;
  }
  .w-xl-10rem {
    width: 10rem !important;
  }
  .w-xl-11px {
    width: 11px !important;
  }
  .w-xl-11rem {
    width: 11rem !important;
  }
  .w-xl-12px {
    width: 12px !important;
  }
  .w-xl-12rem {
    width: 12rem !important;
  }
  .w-xl-13px {
    width: 13px !important;
  }
  .w-xl-13rem {
    width: 13rem !important;
  }
  .w-xl-14px {
    width: 14px !important;
  }
  .w-xl-14rem {
    width: 14rem !important;
  }
  .w-xl-15px {
    width: 15px !important;
  }
  .w-xl-15rem {
    width: 15rem !important;
  }
  .w-xl-16px {
    width: 16px !important;
  }
  .w-xl-16rem {
    width: 16rem !important;
  }
  .w-xl-17px {
    width: 17px !important;
  }
  .w-xl-17rem {
    width: 17rem !important;
  }
  .w-xl-18px {
    width: 18px !important;
  }
  .w-xl-18rem {
    width: 18rem !important;
  }
  .w-xl-19px {
    width: 19px !important;
  }
  .w-xl-19rem {
    width: 19rem !important;
  }
  .w-xl-20px {
    width: 20px !important;
  }
  .w-xl-20rem {
    width: 20rem !important;
  }
  .w-xl-21px {
    width: 21px !important;
  }
  .w-xl-21rem {
    width: 21rem !important;
  }
  .w-xl-22px {
    width: 22px !important;
  }
  .w-xl-22rem {
    width: 22rem !important;
  }
  .w-xl-23px {
    width: 23px !important;
  }
  .w-xl-23rem {
    width: 23rem !important;
  }
  .w-xl-24px {
    width: 24px !important;
  }
  .w-xl-24rem {
    width: 24rem !important;
  }
  .w-xl-25px {
    width: 25px !important;
  }
  .w-xl-25rem {
    width: 25rem !important;
  }
  .w-xl-26px {
    width: 26px !important;
  }
  .w-xl-26rem {
    width: 26rem !important;
  }
  .w-xl-27px {
    width: 27px !important;
  }
  .w-xl-27rem {
    width: 27rem !important;
  }
  .w-xl-28px {
    width: 28px !important;
  }
  .w-xl-28rem {
    width: 28rem !important;
  }
  .w-xl-29px {
    width: 29px !important;
  }
  .w-xl-29rem {
    width: 29rem !important;
  }
  .w-xl-30px {
    width: 30px !important;
  }
  .w-xl-30rem {
    width: 30rem !important;
  }
  .w-xl-31px {
    width: 31px !important;
  }
  .w-xl-31rem {
    width: 31rem !important;
  }
  .w-xl-32px {
    width: 32px !important;
  }
  .w-xl-32rem {
    width: 32rem !important;
  }
  .w-xl-33px {
    width: 33px !important;
  }
  .w-xl-33rem {
    width: 33rem !important;
  }
  .w-xl-34px {
    width: 34px !important;
  }
  .w-xl-34rem {
    width: 34rem !important;
  }
  .w-xl-35px {
    width: 35px !important;
  }
  .w-xl-35rem {
    width: 35rem !important;
  }
  .w-xl-36px {
    width: 36px !important;
  }
  .w-xl-36rem {
    width: 36rem !important;
  }
  .w-xl-37px {
    width: 37px !important;
  }
  .w-xl-37rem {
    width: 37rem !important;
  }
  .w-xl-38px {
    width: 38px !important;
  }
  .w-xl-38rem {
    width: 38rem !important;
  }
  .w-xl-39px {
    width: 39px !important;
  }
  .w-xl-39rem {
    width: 39rem !important;
  }
  .w-xl-40px {
    width: 40px !important;
  }
  .w-xl-40rem {
    width: 40rem !important;
  }
  .w-xl-41px {
    width: 41px !important;
  }
  .w-xl-41rem {
    width: 41rem !important;
  }
  .w-xl-42px {
    width: 42px !important;
  }
  .w-xl-42rem {
    width: 42rem !important;
  }
  .w-xl-43px {
    width: 43px !important;
  }
  .w-xl-43rem {
    width: 43rem !important;
  }
  .w-xl-44px {
    width: 44px !important;
  }
  .w-xl-44rem {
    width: 44rem !important;
  }
  .w-xl-45px {
    width: 45px !important;
  }
  .w-xl-45rem {
    width: 45rem !important;
  }
  .w-xl-46px {
    width: 46px !important;
  }
  .w-xl-46rem {
    width: 46rem !important;
  }
  .w-xl-47px {
    width: 47px !important;
  }
  .w-xl-47rem {
    width: 47rem !important;
  }
  .w-xl-48px {
    width: 48px !important;
  }
  .w-xl-48rem {
    width: 48rem !important;
  }
  .w-xl-49px {
    width: 49px !important;
  }
  .w-xl-49rem {
    width: 49rem !important;
  }
  .w-xl-50px {
    width: 50px !important;
  }
  .w-xl-50rem {
    width: 50rem !important;
  }
  .w-xl-51px {
    width: 51px !important;
  }
  .w-xl-51rem {
    width: 51rem !important;
  }
  .w-xl-52px {
    width: 52px !important;
  }
  .w-xl-52rem {
    width: 52rem !important;
  }
  .w-xl-53px {
    width: 53px !important;
  }
  .w-xl-53rem {
    width: 53rem !important;
  }
  .w-xl-54px {
    width: 54px !important;
  }
  .w-xl-54rem {
    width: 54rem !important;
  }
  .w-xl-55px {
    width: 55px !important;
  }
  .w-xl-55rem {
    width: 55rem !important;
  }
  .w-xl-56px {
    width: 56px !important;
  }
  .w-xl-56rem {
    width: 56rem !important;
  }
  .w-xl-57px {
    width: 57px !important;
  }
  .w-xl-57rem {
    width: 57rem !important;
  }
  .w-xl-58px {
    width: 58px !important;
  }
  .w-xl-58rem {
    width: 58rem !important;
  }
  .w-xl-59px {
    width: 59px !important;
  }
  .w-xl-59rem {
    width: 59rem !important;
  }
  .w-xl-60px {
    width: 60px !important;
  }
  .w-xl-60rem {
    width: 60rem !important;
  }
  .w-xl-61px {
    width: 61px !important;
  }
  .w-xl-61rem {
    width: 61rem !important;
  }
  .w-xl-62px {
    width: 62px !important;
  }
  .w-xl-62rem {
    width: 62rem !important;
  }
  .w-xl-63px {
    width: 63px !important;
  }
  .w-xl-63rem {
    width: 63rem !important;
  }
  .w-xl-64px {
    width: 64px !important;
  }
  .w-xl-64rem {
    width: 64rem !important;
  }
  .w-xl-65px {
    width: 65px !important;
  }
  .w-xl-65rem {
    width: 65rem !important;
  }
  .w-xl-66px {
    width: 66px !important;
  }
  .w-xl-66rem {
    width: 66rem !important;
  }
  .w-xl-67px {
    width: 67px !important;
  }
  .w-xl-67rem {
    width: 67rem !important;
  }
  .w-xl-68px {
    width: 68px !important;
  }
  .w-xl-68rem {
    width: 68rem !important;
  }
  .w-xl-69px {
    width: 69px !important;
  }
  .w-xl-69rem {
    width: 69rem !important;
  }
  .w-xl-70px {
    width: 70px !important;
  }
  .w-xl-70rem {
    width: 70rem !important;
  }
  .w-xl-71px {
    width: 71px !important;
  }
  .w-xl-71rem {
    width: 71rem !important;
  }
  .w-xl-72px {
    width: 72px !important;
  }
  .w-xl-72rem {
    width: 72rem !important;
  }
  .w-xl-73px {
    width: 73px !important;
  }
  .w-xl-73rem {
    width: 73rem !important;
  }
  .w-xl-74px {
    width: 74px !important;
  }
  .w-xl-74rem {
    width: 74rem !important;
  }
  .w-xl-75px {
    width: 75px !important;
  }
  .w-xl-75rem {
    width: 75rem !important;
  }
  .w-xl-76px {
    width: 76px !important;
  }
  .w-xl-76rem {
    width: 76rem !important;
  }
  .w-xl-77px {
    width: 77px !important;
  }
  .w-xl-77rem {
    width: 77rem !important;
  }
  .w-xl-78px {
    width: 78px !important;
  }
  .w-xl-78rem {
    width: 78rem !important;
  }
  .w-xl-79px {
    width: 79px !important;
  }
  .w-xl-79rem {
    width: 79rem !important;
  }
  .w-xl-80px {
    width: 80px !important;
  }
  .w-xl-80rem {
    width: 80rem !important;
  }
  .w-xl-81px {
    width: 81px !important;
  }
  .w-xl-81rem {
    width: 81rem !important;
  }
  .w-xl-82px {
    width: 82px !important;
  }
  .w-xl-82rem {
    width: 82rem !important;
  }
  .w-xl-83px {
    width: 83px !important;
  }
  .w-xl-83rem {
    width: 83rem !important;
  }
  .w-xl-84px {
    width: 84px !important;
  }
  .w-xl-84rem {
    width: 84rem !important;
  }
  .w-xl-85px {
    width: 85px !important;
  }
  .w-xl-85rem {
    width: 85rem !important;
  }
  .w-xl-86px {
    width: 86px !important;
  }
  .w-xl-86rem {
    width: 86rem !important;
  }
  .w-xl-87px {
    width: 87px !important;
  }
  .w-xl-87rem {
    width: 87rem !important;
  }
  .w-xl-88px {
    width: 88px !important;
  }
  .w-xl-88rem {
    width: 88rem !important;
  }
  .w-xl-89px {
    width: 89px !important;
  }
  .w-xl-89rem {
    width: 89rem !important;
  }
  .w-xl-90px {
    width: 90px !important;
  }
  .w-xl-90rem {
    width: 90rem !important;
  }
  .w-xl-91px {
    width: 91px !important;
  }
  .w-xl-91rem {
    width: 91rem !important;
  }
  .w-xl-92px {
    width: 92px !important;
  }
  .w-xl-92rem {
    width: 92rem !important;
  }
  .w-xl-93px {
    width: 93px !important;
  }
  .w-xl-93rem {
    width: 93rem !important;
  }
  .w-xl-94px {
    width: 94px !important;
  }
  .w-xl-94rem {
    width: 94rem !important;
  }
  .w-xl-95px {
    width: 95px !important;
  }
  .w-xl-95rem {
    width: 95rem !important;
  }
  .w-xl-96px {
    width: 96px !important;
  }
  .w-xl-96rem {
    width: 96rem !important;
  }
  .w-xl-97px {
    width: 97px !important;
  }
  .w-xl-97rem {
    width: 97rem !important;
  }
  .w-xl-98px {
    width: 98px !important;
  }
  .w-xl-98rem {
    width: 98rem !important;
  }
  .w-xl-99px {
    width: 99px !important;
  }
  .w-xl-99rem {
    width: 99rem !important;
  }
  .w-xl-100px {
    width: 100px !important;
  }
  .w-xl-100rem {
    width: 100rem !important;
  }
  .h-xl-0px {
    height: 0px !important;
  }
  .h-xl-0rem {
    height: 0rem !important;
  }
  .h-xl-1px {
    height: 1px !important;
  }
  .h-xl-1rem {
    height: 1rem !important;
  }
  .h-xl-2px {
    height: 2px !important;
  }
  .h-xl-2rem {
    height: 2rem !important;
  }
  .h-xl-3px {
    height: 3px !important;
  }
  .h-xl-3rem {
    height: 3rem !important;
  }
  .h-xl-4px {
    height: 4px !important;
  }
  .h-xl-4rem {
    height: 4rem !important;
  }
  .h-xl-5px {
    height: 5px !important;
  }
  .h-xl-5rem {
    height: 5rem !important;
  }
  .h-xl-6px {
    height: 6px !important;
  }
  .h-xl-6rem {
    height: 6rem !important;
  }
  .h-xl-7px {
    height: 7px !important;
  }
  .h-xl-7rem {
    height: 7rem !important;
  }
  .h-xl-8px {
    height: 8px !important;
  }
  .h-xl-8rem {
    height: 8rem !important;
  }
  .h-xl-9px {
    height: 9px !important;
  }
  .h-xl-9rem {
    height: 9rem !important;
  }
  .h-xl-10px {
    height: 10px !important;
  }
  .h-xl-10rem {
    height: 10rem !important;
  }
  .h-xl-11px {
    height: 11px !important;
  }
  .h-xl-11rem {
    height: 11rem !important;
  }
  .h-xl-12px {
    height: 12px !important;
  }
  .h-xl-12rem {
    height: 12rem !important;
  }
  .h-xl-13px {
    height: 13px !important;
  }
  .h-xl-13rem {
    height: 13rem !important;
  }
  .h-xl-14px {
    height: 14px !important;
  }
  .h-xl-14rem {
    height: 14rem !important;
  }
  .h-xl-15px {
    height: 15px !important;
  }
  .h-xl-15rem {
    height: 15rem !important;
  }
  .h-xl-16px {
    height: 16px !important;
  }
  .h-xl-16rem {
    height: 16rem !important;
  }
  .h-xl-17px {
    height: 17px !important;
  }
  .h-xl-17rem {
    height: 17rem !important;
  }
  .h-xl-18px {
    height: 18px !important;
  }
  .h-xl-18rem {
    height: 18rem !important;
  }
  .h-xl-19px {
    height: 19px !important;
  }
  .h-xl-19rem {
    height: 19rem !important;
  }
  .h-xl-20px {
    height: 20px !important;
  }
  .h-xl-20rem {
    height: 20rem !important;
  }
  .h-xl-21px {
    height: 21px !important;
  }
  .h-xl-21rem {
    height: 21rem !important;
  }
  .h-xl-22px {
    height: 22px !important;
  }
  .h-xl-22rem {
    height: 22rem !important;
  }
  .h-xl-23px {
    height: 23px !important;
  }
  .h-xl-23rem {
    height: 23rem !important;
  }
  .h-xl-24px {
    height: 24px !important;
  }
  .h-xl-24rem {
    height: 24rem !important;
  }
  .h-xl-25px {
    height: 25px !important;
  }
  .h-xl-25rem {
    height: 25rem !important;
  }
  .h-xl-26px {
    height: 26px !important;
  }
  .h-xl-26rem {
    height: 26rem !important;
  }
  .h-xl-27px {
    height: 27px !important;
  }
  .h-xl-27rem {
    height: 27rem !important;
  }
  .h-xl-28px {
    height: 28px !important;
  }
  .h-xl-28rem {
    height: 28rem !important;
  }
  .h-xl-29px {
    height: 29px !important;
  }
  .h-xl-29rem {
    height: 29rem !important;
  }
  .h-xl-30px {
    height: 30px !important;
  }
  .h-xl-30rem {
    height: 30rem !important;
  }
  .h-xl-31px {
    height: 31px !important;
  }
  .h-xl-31rem {
    height: 31rem !important;
  }
  .h-xl-32px {
    height: 32px !important;
  }
  .h-xl-32rem {
    height: 32rem !important;
  }
  .h-xl-33px {
    height: 33px !important;
  }
  .h-xl-33rem {
    height: 33rem !important;
  }
  .h-xl-34px {
    height: 34px !important;
  }
  .h-xl-34rem {
    height: 34rem !important;
  }
  .h-xl-35px {
    height: 35px !important;
  }
  .h-xl-35rem {
    height: 35rem !important;
  }
  .h-xl-36px {
    height: 36px !important;
  }
  .h-xl-36rem {
    height: 36rem !important;
  }
  .h-xl-37px {
    height: 37px !important;
  }
  .h-xl-37rem {
    height: 37rem !important;
  }
  .h-xl-38px {
    height: 38px !important;
  }
  .h-xl-38rem {
    height: 38rem !important;
  }
  .h-xl-39px {
    height: 39px !important;
  }
  .h-xl-39rem {
    height: 39rem !important;
  }
  .h-xl-40px {
    height: 40px !important;
  }
  .h-xl-40rem {
    height: 40rem !important;
  }
  .h-xl-41px {
    height: 41px !important;
  }
  .h-xl-41rem {
    height: 41rem !important;
  }
  .h-xl-42px {
    height: 42px !important;
  }
  .h-xl-42rem {
    height: 42rem !important;
  }
  .h-xl-43px {
    height: 43px !important;
  }
  .h-xl-43rem {
    height: 43rem !important;
  }
  .h-xl-44px {
    height: 44px !important;
  }
  .h-xl-44rem {
    height: 44rem !important;
  }
  .h-xl-45px {
    height: 45px !important;
  }
  .h-xl-45rem {
    height: 45rem !important;
  }
  .h-xl-46px {
    height: 46px !important;
  }
  .h-xl-46rem {
    height: 46rem !important;
  }
  .h-xl-47px {
    height: 47px !important;
  }
  .h-xl-47rem {
    height: 47rem !important;
  }
  .h-xl-48px {
    height: 48px !important;
  }
  .h-xl-48rem {
    height: 48rem !important;
  }
  .h-xl-49px {
    height: 49px !important;
  }
  .h-xl-49rem {
    height: 49rem !important;
  }
  .h-xl-50px {
    height: 50px !important;
  }
  .h-xl-50rem {
    height: 50rem !important;
  }
  .h-xl-51px {
    height: 51px !important;
  }
  .h-xl-51rem {
    height: 51rem !important;
  }
  .h-xl-52px {
    height: 52px !important;
  }
  .h-xl-52rem {
    height: 52rem !important;
  }
  .h-xl-53px {
    height: 53px !important;
  }
  .h-xl-53rem {
    height: 53rem !important;
  }
  .h-xl-54px {
    height: 54px !important;
  }
  .h-xl-54rem {
    height: 54rem !important;
  }
  .h-xl-55px {
    height: 55px !important;
  }
  .h-xl-55rem {
    height: 55rem !important;
  }
  .h-xl-56px {
    height: 56px !important;
  }
  .h-xl-56rem {
    height: 56rem !important;
  }
  .h-xl-57px {
    height: 57px !important;
  }
  .h-xl-57rem {
    height: 57rem !important;
  }
  .h-xl-58px {
    height: 58px !important;
  }
  .h-xl-58rem {
    height: 58rem !important;
  }
  .h-xl-59px {
    height: 59px !important;
  }
  .h-xl-59rem {
    height: 59rem !important;
  }
  .h-xl-60px {
    height: 60px !important;
  }
  .h-xl-60rem {
    height: 60rem !important;
  }
  .h-xl-61px {
    height: 61px !important;
  }
  .h-xl-61rem {
    height: 61rem !important;
  }
  .h-xl-62px {
    height: 62px !important;
  }
  .h-xl-62rem {
    height: 62rem !important;
  }
  .h-xl-63px {
    height: 63px !important;
  }
  .h-xl-63rem {
    height: 63rem !important;
  }
  .h-xl-64px {
    height: 64px !important;
  }
  .h-xl-64rem {
    height: 64rem !important;
  }
  .h-xl-65px {
    height: 65px !important;
  }
  .h-xl-65rem {
    height: 65rem !important;
  }
  .h-xl-66px {
    height: 66px !important;
  }
  .h-xl-66rem {
    height: 66rem !important;
  }
  .h-xl-67px {
    height: 67px !important;
  }
  .h-xl-67rem {
    height: 67rem !important;
  }
  .h-xl-68px {
    height: 68px !important;
  }
  .h-xl-68rem {
    height: 68rem !important;
  }
  .h-xl-69px {
    height: 69px !important;
  }
  .h-xl-69rem {
    height: 69rem !important;
  }
  .h-xl-70px {
    height: 70px !important;
  }
  .h-xl-70rem {
    height: 70rem !important;
  }
  .h-xl-71px {
    height: 71px !important;
  }
  .h-xl-71rem {
    height: 71rem !important;
  }
  .h-xl-72px {
    height: 72px !important;
  }
  .h-xl-72rem {
    height: 72rem !important;
  }
  .h-xl-73px {
    height: 73px !important;
  }
  .h-xl-73rem {
    height: 73rem !important;
  }
  .h-xl-74px {
    height: 74px !important;
  }
  .h-xl-74rem {
    height: 74rem !important;
  }
  .h-xl-75px {
    height: 75px !important;
  }
  .h-xl-75rem {
    height: 75rem !important;
  }
  .h-xl-76px {
    height: 76px !important;
  }
  .h-xl-76rem {
    height: 76rem !important;
  }
  .h-xl-77px {
    height: 77px !important;
  }
  .h-xl-77rem {
    height: 77rem !important;
  }
  .h-xl-78px {
    height: 78px !important;
  }
  .h-xl-78rem {
    height: 78rem !important;
  }
  .h-xl-79px {
    height: 79px !important;
  }
  .h-xl-79rem {
    height: 79rem !important;
  }
  .h-xl-80px {
    height: 80px !important;
  }
  .h-xl-80rem {
    height: 80rem !important;
  }
  .h-xl-81px {
    height: 81px !important;
  }
  .h-xl-81rem {
    height: 81rem !important;
  }
  .h-xl-82px {
    height: 82px !important;
  }
  .h-xl-82rem {
    height: 82rem !important;
  }
  .h-xl-83px {
    height: 83px !important;
  }
  .h-xl-83rem {
    height: 83rem !important;
  }
  .h-xl-84px {
    height: 84px !important;
  }
  .h-xl-84rem {
    height: 84rem !important;
  }
  .h-xl-85px {
    height: 85px !important;
  }
  .h-xl-85rem {
    height: 85rem !important;
  }
  .h-xl-86px {
    height: 86px !important;
  }
  .h-xl-86rem {
    height: 86rem !important;
  }
  .h-xl-87px {
    height: 87px !important;
  }
  .h-xl-87rem {
    height: 87rem !important;
  }
  .h-xl-88px {
    height: 88px !important;
  }
  .h-xl-88rem {
    height: 88rem !important;
  }
  .h-xl-89px {
    height: 89px !important;
  }
  .h-xl-89rem {
    height: 89rem !important;
  }
  .h-xl-90px {
    height: 90px !important;
  }
  .h-xl-90rem {
    height: 90rem !important;
  }
  .h-xl-91px {
    height: 91px !important;
  }
  .h-xl-91rem {
    height: 91rem !important;
  }
  .h-xl-92px {
    height: 92px !important;
  }
  .h-xl-92rem {
    height: 92rem !important;
  }
  .h-xl-93px {
    height: 93px !important;
  }
  .h-xl-93rem {
    height: 93rem !important;
  }
  .h-xl-94px {
    height: 94px !important;
  }
  .h-xl-94rem {
    height: 94rem !important;
  }
  .h-xl-95px {
    height: 95px !important;
  }
  .h-xl-95rem {
    height: 95rem !important;
  }
  .h-xl-96px {
    height: 96px !important;
  }
  .h-xl-96rem {
    height: 96rem !important;
  }
  .h-xl-97px {
    height: 97px !important;
  }
  .h-xl-97rem {
    height: 97rem !important;
  }
  .h-xl-98px {
    height: 98px !important;
  }
  .h-xl-98rem {
    height: 98rem !important;
  }
  .h-xl-99px {
    height: 99px !important;
  }
  .h-xl-99rem {
    height: 99rem !important;
  }
  .h-xl-100px {
    height: 100px !important;
  }
  .h-xl-100rem {
    height: 100rem !important;
  }
  .t-xl-0px {
    top: 0px !important;
  }
  .t-xl-0rem {
    top: 0rem !important;
  }
  .t-xl-1px {
    top: 1px !important;
  }
  .t-xl-1rem {
    top: 1rem !important;
  }
  .t-xl-2px {
    top: 2px !important;
  }
  .t-xl-2rem {
    top: 2rem !important;
  }
  .t-xl-3px {
    top: 3px !important;
  }
  .t-xl-3rem {
    top: 3rem !important;
  }
  .t-xl-4px {
    top: 4px !important;
  }
  .t-xl-4rem {
    top: 4rem !important;
  }
  .t-xl-5px {
    top: 5px !important;
  }
  .t-xl-5rem {
    top: 5rem !important;
  }
  .t-xl-6px {
    top: 6px !important;
  }
  .t-xl-6rem {
    top: 6rem !important;
  }
  .t-xl-7px {
    top: 7px !important;
  }
  .t-xl-7rem {
    top: 7rem !important;
  }
  .t-xl-8px {
    top: 8px !important;
  }
  .t-xl-8rem {
    top: 8rem !important;
  }
  .t-xl-9px {
    top: 9px !important;
  }
  .t-xl-9rem {
    top: 9rem !important;
  }
  .t-xl-10px {
    top: 10px !important;
  }
  .t-xl-10rem {
    top: 10rem !important;
  }
  .t-xl-11px {
    top: 11px !important;
  }
  .t-xl-11rem {
    top: 11rem !important;
  }
  .t-xl-12px {
    top: 12px !important;
  }
  .t-xl-12rem {
    top: 12rem !important;
  }
  .t-xl-13px {
    top: 13px !important;
  }
  .t-xl-13rem {
    top: 13rem !important;
  }
  .t-xl-14px {
    top: 14px !important;
  }
  .t-xl-14rem {
    top: 14rem !important;
  }
  .t-xl-15px {
    top: 15px !important;
  }
  .t-xl-15rem {
    top: 15rem !important;
  }
  .t-xl-16px {
    top: 16px !important;
  }
  .t-xl-16rem {
    top: 16rem !important;
  }
  .t-xl-17px {
    top: 17px !important;
  }
  .t-xl-17rem {
    top: 17rem !important;
  }
  .t-xl-18px {
    top: 18px !important;
  }
  .t-xl-18rem {
    top: 18rem !important;
  }
  .t-xl-19px {
    top: 19px !important;
  }
  .t-xl-19rem {
    top: 19rem !important;
  }
  .t-xl-20px {
    top: 20px !important;
  }
  .t-xl-20rem {
    top: 20rem !important;
  }
  .t-xl-21px {
    top: 21px !important;
  }
  .t-xl-21rem {
    top: 21rem !important;
  }
  .t-xl-22px {
    top: 22px !important;
  }
  .t-xl-22rem {
    top: 22rem !important;
  }
  .t-xl-23px {
    top: 23px !important;
  }
  .t-xl-23rem {
    top: 23rem !important;
  }
  .t-xl-24px {
    top: 24px !important;
  }
  .t-xl-24rem {
    top: 24rem !important;
  }
  .t-xl-25px {
    top: 25px !important;
  }
  .t-xl-25rem {
    top: 25rem !important;
  }
  .t-xl-26px {
    top: 26px !important;
  }
  .t-xl-26rem {
    top: 26rem !important;
  }
  .t-xl-27px {
    top: 27px !important;
  }
  .t-xl-27rem {
    top: 27rem !important;
  }
  .t-xl-28px {
    top: 28px !important;
  }
  .t-xl-28rem {
    top: 28rem !important;
  }
  .t-xl-29px {
    top: 29px !important;
  }
  .t-xl-29rem {
    top: 29rem !important;
  }
  .t-xl-30px {
    top: 30px !important;
  }
  .t-xl-30rem {
    top: 30rem !important;
  }
  .t-xl-31px {
    top: 31px !important;
  }
  .t-xl-31rem {
    top: 31rem !important;
  }
  .t-xl-32px {
    top: 32px !important;
  }
  .t-xl-32rem {
    top: 32rem !important;
  }
  .t-xl-33px {
    top: 33px !important;
  }
  .t-xl-33rem {
    top: 33rem !important;
  }
  .t-xl-34px {
    top: 34px !important;
  }
  .t-xl-34rem {
    top: 34rem !important;
  }
  .t-xl-35px {
    top: 35px !important;
  }
  .t-xl-35rem {
    top: 35rem !important;
  }
  .t-xl-36px {
    top: 36px !important;
  }
  .t-xl-36rem {
    top: 36rem !important;
  }
  .t-xl-37px {
    top: 37px !important;
  }
  .t-xl-37rem {
    top: 37rem !important;
  }
  .t-xl-38px {
    top: 38px !important;
  }
  .t-xl-38rem {
    top: 38rem !important;
  }
  .t-xl-39px {
    top: 39px !important;
  }
  .t-xl-39rem {
    top: 39rem !important;
  }
  .t-xl-40px {
    top: 40px !important;
  }
  .t-xl-40rem {
    top: 40rem !important;
  }
  .t-xl-41px {
    top: 41px !important;
  }
  .t-xl-41rem {
    top: 41rem !important;
  }
  .t-xl-42px {
    top: 42px !important;
  }
  .t-xl-42rem {
    top: 42rem !important;
  }
  .t-xl-43px {
    top: 43px !important;
  }
  .t-xl-43rem {
    top: 43rem !important;
  }
  .t-xl-44px {
    top: 44px !important;
  }
  .t-xl-44rem {
    top: 44rem !important;
  }
  .t-xl-45px {
    top: 45px !important;
  }
  .t-xl-45rem {
    top: 45rem !important;
  }
  .t-xl-46px {
    top: 46px !important;
  }
  .t-xl-46rem {
    top: 46rem !important;
  }
  .t-xl-47px {
    top: 47px !important;
  }
  .t-xl-47rem {
    top: 47rem !important;
  }
  .t-xl-48px {
    top: 48px !important;
  }
  .t-xl-48rem {
    top: 48rem !important;
  }
  .t-xl-49px {
    top: 49px !important;
  }
  .t-xl-49rem {
    top: 49rem !important;
  }
  .t-xl-50px {
    top: 50px !important;
  }
  .t-xl-50rem {
    top: 50rem !important;
  }
  .t-xl-51px {
    top: 51px !important;
  }
  .t-xl-51rem {
    top: 51rem !important;
  }
  .t-xl-52px {
    top: 52px !important;
  }
  .t-xl-52rem {
    top: 52rem !important;
  }
  .t-xl-53px {
    top: 53px !important;
  }
  .t-xl-53rem {
    top: 53rem !important;
  }
  .t-xl-54px {
    top: 54px !important;
  }
  .t-xl-54rem {
    top: 54rem !important;
  }
  .t-xl-55px {
    top: 55px !important;
  }
  .t-xl-55rem {
    top: 55rem !important;
  }
  .t-xl-56px {
    top: 56px !important;
  }
  .t-xl-56rem {
    top: 56rem !important;
  }
  .t-xl-57px {
    top: 57px !important;
  }
  .t-xl-57rem {
    top: 57rem !important;
  }
  .t-xl-58px {
    top: 58px !important;
  }
  .t-xl-58rem {
    top: 58rem !important;
  }
  .t-xl-59px {
    top: 59px !important;
  }
  .t-xl-59rem {
    top: 59rem !important;
  }
  .t-xl-60px {
    top: 60px !important;
  }
  .t-xl-60rem {
    top: 60rem !important;
  }
  .t-xl-61px {
    top: 61px !important;
  }
  .t-xl-61rem {
    top: 61rem !important;
  }
  .t-xl-62px {
    top: 62px !important;
  }
  .t-xl-62rem {
    top: 62rem !important;
  }
  .t-xl-63px {
    top: 63px !important;
  }
  .t-xl-63rem {
    top: 63rem !important;
  }
  .t-xl-64px {
    top: 64px !important;
  }
  .t-xl-64rem {
    top: 64rem !important;
  }
  .t-xl-65px {
    top: 65px !important;
  }
  .t-xl-65rem {
    top: 65rem !important;
  }
  .t-xl-66px {
    top: 66px !important;
  }
  .t-xl-66rem {
    top: 66rem !important;
  }
  .t-xl-67px {
    top: 67px !important;
  }
  .t-xl-67rem {
    top: 67rem !important;
  }
  .t-xl-68px {
    top: 68px !important;
  }
  .t-xl-68rem {
    top: 68rem !important;
  }
  .t-xl-69px {
    top: 69px !important;
  }
  .t-xl-69rem {
    top: 69rem !important;
  }
  .t-xl-70px {
    top: 70px !important;
  }
  .t-xl-70rem {
    top: 70rem !important;
  }
  .t-xl-71px {
    top: 71px !important;
  }
  .t-xl-71rem {
    top: 71rem !important;
  }
  .t-xl-72px {
    top: 72px !important;
  }
  .t-xl-72rem {
    top: 72rem !important;
  }
  .t-xl-73px {
    top: 73px !important;
  }
  .t-xl-73rem {
    top: 73rem !important;
  }
  .t-xl-74px {
    top: 74px !important;
  }
  .t-xl-74rem {
    top: 74rem !important;
  }
  .t-xl-75px {
    top: 75px !important;
  }
  .t-xl-75rem {
    top: 75rem !important;
  }
  .t-xl-76px {
    top: 76px !important;
  }
  .t-xl-76rem {
    top: 76rem !important;
  }
  .t-xl-77px {
    top: 77px !important;
  }
  .t-xl-77rem {
    top: 77rem !important;
  }
  .t-xl-78px {
    top: 78px !important;
  }
  .t-xl-78rem {
    top: 78rem !important;
  }
  .t-xl-79px {
    top: 79px !important;
  }
  .t-xl-79rem {
    top: 79rem !important;
  }
  .t-xl-80px {
    top: 80px !important;
  }
  .t-xl-80rem {
    top: 80rem !important;
  }
  .t-xl-81px {
    top: 81px !important;
  }
  .t-xl-81rem {
    top: 81rem !important;
  }
  .t-xl-82px {
    top: 82px !important;
  }
  .t-xl-82rem {
    top: 82rem !important;
  }
  .t-xl-83px {
    top: 83px !important;
  }
  .t-xl-83rem {
    top: 83rem !important;
  }
  .t-xl-84px {
    top: 84px !important;
  }
  .t-xl-84rem {
    top: 84rem !important;
  }
  .t-xl-85px {
    top: 85px !important;
  }
  .t-xl-85rem {
    top: 85rem !important;
  }
  .t-xl-86px {
    top: 86px !important;
  }
  .t-xl-86rem {
    top: 86rem !important;
  }
  .t-xl-87px {
    top: 87px !important;
  }
  .t-xl-87rem {
    top: 87rem !important;
  }
  .t-xl-88px {
    top: 88px !important;
  }
  .t-xl-88rem {
    top: 88rem !important;
  }
  .t-xl-89px {
    top: 89px !important;
  }
  .t-xl-89rem {
    top: 89rem !important;
  }
  .t-xl-90px {
    top: 90px !important;
  }
  .t-xl-90rem {
    top: 90rem !important;
  }
  .t-xl-91px {
    top: 91px !important;
  }
  .t-xl-91rem {
    top: 91rem !important;
  }
  .t-xl-92px {
    top: 92px !important;
  }
  .t-xl-92rem {
    top: 92rem !important;
  }
  .t-xl-93px {
    top: 93px !important;
  }
  .t-xl-93rem {
    top: 93rem !important;
  }
  .t-xl-94px {
    top: 94px !important;
  }
  .t-xl-94rem {
    top: 94rem !important;
  }
  .t-xl-95px {
    top: 95px !important;
  }
  .t-xl-95rem {
    top: 95rem !important;
  }
  .t-xl-96px {
    top: 96px !important;
  }
  .t-xl-96rem {
    top: 96rem !important;
  }
  .t-xl-97px {
    top: 97px !important;
  }
  .t-xl-97rem {
    top: 97rem !important;
  }
  .t-xl-98px {
    top: 98px !important;
  }
  .t-xl-98rem {
    top: 98rem !important;
  }
  .t-xl-99px {
    top: 99px !important;
  }
  .t-xl-99rem {
    top: 99rem !important;
  }
  .t-xl-100px {
    top: 100px !important;
  }
  .t-xl-100rem {
    top: 100rem !important;
  }
  .l-xl-0px {
    left: 0px !important;
  }
  .l-xl-0rem {
    left: 0rem !important;
  }
  .l-xl-1px {
    left: 1px !important;
  }
  .l-xl-1rem {
    left: 1rem !important;
  }
  .l-xl-2px {
    left: 2px !important;
  }
  .l-xl-2rem {
    left: 2rem !important;
  }
  .l-xl-3px {
    left: 3px !important;
  }
  .l-xl-3rem {
    left: 3rem !important;
  }
  .l-xl-4px {
    left: 4px !important;
  }
  .l-xl-4rem {
    left: 4rem !important;
  }
  .l-xl-5px {
    left: 5px !important;
  }
  .l-xl-5rem {
    left: 5rem !important;
  }
  .l-xl-6px {
    left: 6px !important;
  }
  .l-xl-6rem {
    left: 6rem !important;
  }
  .l-xl-7px {
    left: 7px !important;
  }
  .l-xl-7rem {
    left: 7rem !important;
  }
  .l-xl-8px {
    left: 8px !important;
  }
  .l-xl-8rem {
    left: 8rem !important;
  }
  .l-xl-9px {
    left: 9px !important;
  }
  .l-xl-9rem {
    left: 9rem !important;
  }
  .l-xl-10px {
    left: 10px !important;
  }
  .l-xl-10rem {
    left: 10rem !important;
  }
  .l-xl-11px {
    left: 11px !important;
  }
  .l-xl-11rem {
    left: 11rem !important;
  }
  .l-xl-12px {
    left: 12px !important;
  }
  .l-xl-12rem {
    left: 12rem !important;
  }
  .l-xl-13px {
    left: 13px !important;
  }
  .l-xl-13rem {
    left: 13rem !important;
  }
  .l-xl-14px {
    left: 14px !important;
  }
  .l-xl-14rem {
    left: 14rem !important;
  }
  .l-xl-15px {
    left: 15px !important;
  }
  .l-xl-15rem {
    left: 15rem !important;
  }
  .l-xl-16px {
    left: 16px !important;
  }
  .l-xl-16rem {
    left: 16rem !important;
  }
  .l-xl-17px {
    left: 17px !important;
  }
  .l-xl-17rem {
    left: 17rem !important;
  }
  .l-xl-18px {
    left: 18px !important;
  }
  .l-xl-18rem {
    left: 18rem !important;
  }
  .l-xl-19px {
    left: 19px !important;
  }
  .l-xl-19rem {
    left: 19rem !important;
  }
  .l-xl-20px {
    left: 20px !important;
  }
  .l-xl-20rem {
    left: 20rem !important;
  }
  .l-xl-21px {
    left: 21px !important;
  }
  .l-xl-21rem {
    left: 21rem !important;
  }
  .l-xl-22px {
    left: 22px !important;
  }
  .l-xl-22rem {
    left: 22rem !important;
  }
  .l-xl-23px {
    left: 23px !important;
  }
  .l-xl-23rem {
    left: 23rem !important;
  }
  .l-xl-24px {
    left: 24px !important;
  }
  .l-xl-24rem {
    left: 24rem !important;
  }
  .l-xl-25px {
    left: 25px !important;
  }
  .l-xl-25rem {
    left: 25rem !important;
  }
  .l-xl-26px {
    left: 26px !important;
  }
  .l-xl-26rem {
    left: 26rem !important;
  }
  .l-xl-27px {
    left: 27px !important;
  }
  .l-xl-27rem {
    left: 27rem !important;
  }
  .l-xl-28px {
    left: 28px !important;
  }
  .l-xl-28rem {
    left: 28rem !important;
  }
  .l-xl-29px {
    left: 29px !important;
  }
  .l-xl-29rem {
    left: 29rem !important;
  }
  .l-xl-30px {
    left: 30px !important;
  }
  .l-xl-30rem {
    left: 30rem !important;
  }
  .l-xl-31px {
    left: 31px !important;
  }
  .l-xl-31rem {
    left: 31rem !important;
  }
  .l-xl-32px {
    left: 32px !important;
  }
  .l-xl-32rem {
    left: 32rem !important;
  }
  .l-xl-33px {
    left: 33px !important;
  }
  .l-xl-33rem {
    left: 33rem !important;
  }
  .l-xl-34px {
    left: 34px !important;
  }
  .l-xl-34rem {
    left: 34rem !important;
  }
  .l-xl-35px {
    left: 35px !important;
  }
  .l-xl-35rem {
    left: 35rem !important;
  }
  .l-xl-36px {
    left: 36px !important;
  }
  .l-xl-36rem {
    left: 36rem !important;
  }
  .l-xl-37px {
    left: 37px !important;
  }
  .l-xl-37rem {
    left: 37rem !important;
  }
  .l-xl-38px {
    left: 38px !important;
  }
  .l-xl-38rem {
    left: 38rem !important;
  }
  .l-xl-39px {
    left: 39px !important;
  }
  .l-xl-39rem {
    left: 39rem !important;
  }
  .l-xl-40px {
    left: 40px !important;
  }
  .l-xl-40rem {
    left: 40rem !important;
  }
  .l-xl-41px {
    left: 41px !important;
  }
  .l-xl-41rem {
    left: 41rem !important;
  }
  .l-xl-42px {
    left: 42px !important;
  }
  .l-xl-42rem {
    left: 42rem !important;
  }
  .l-xl-43px {
    left: 43px !important;
  }
  .l-xl-43rem {
    left: 43rem !important;
  }
  .l-xl-44px {
    left: 44px !important;
  }
  .l-xl-44rem {
    left: 44rem !important;
  }
  .l-xl-45px {
    left: 45px !important;
  }
  .l-xl-45rem {
    left: 45rem !important;
  }
  .l-xl-46px {
    left: 46px !important;
  }
  .l-xl-46rem {
    left: 46rem !important;
  }
  .l-xl-47px {
    left: 47px !important;
  }
  .l-xl-47rem {
    left: 47rem !important;
  }
  .l-xl-48px {
    left: 48px !important;
  }
  .l-xl-48rem {
    left: 48rem !important;
  }
  .l-xl-49px {
    left: 49px !important;
  }
  .l-xl-49rem {
    left: 49rem !important;
  }
  .l-xl-50px {
    left: 50px !important;
  }
  .l-xl-50rem {
    left: 50rem !important;
  }
  .l-xl-51px {
    left: 51px !important;
  }
  .l-xl-51rem {
    left: 51rem !important;
  }
  .l-xl-52px {
    left: 52px !important;
  }
  .l-xl-52rem {
    left: 52rem !important;
  }
  .l-xl-53px {
    left: 53px !important;
  }
  .l-xl-53rem {
    left: 53rem !important;
  }
  .l-xl-54px {
    left: 54px !important;
  }
  .l-xl-54rem {
    left: 54rem !important;
  }
  .l-xl-55px {
    left: 55px !important;
  }
  .l-xl-55rem {
    left: 55rem !important;
  }
  .l-xl-56px {
    left: 56px !important;
  }
  .l-xl-56rem {
    left: 56rem !important;
  }
  .l-xl-57px {
    left: 57px !important;
  }
  .l-xl-57rem {
    left: 57rem !important;
  }
  .l-xl-58px {
    left: 58px !important;
  }
  .l-xl-58rem {
    left: 58rem !important;
  }
  .l-xl-59px {
    left: 59px !important;
  }
  .l-xl-59rem {
    left: 59rem !important;
  }
  .l-xl-60px {
    left: 60px !important;
  }
  .l-xl-60rem {
    left: 60rem !important;
  }
  .l-xl-61px {
    left: 61px !important;
  }
  .l-xl-61rem {
    left: 61rem !important;
  }
  .l-xl-62px {
    left: 62px !important;
  }
  .l-xl-62rem {
    left: 62rem !important;
  }
  .l-xl-63px {
    left: 63px !important;
  }
  .l-xl-63rem {
    left: 63rem !important;
  }
  .l-xl-64px {
    left: 64px !important;
  }
  .l-xl-64rem {
    left: 64rem !important;
  }
  .l-xl-65px {
    left: 65px !important;
  }
  .l-xl-65rem {
    left: 65rem !important;
  }
  .l-xl-66px {
    left: 66px !important;
  }
  .l-xl-66rem {
    left: 66rem !important;
  }
  .l-xl-67px {
    left: 67px !important;
  }
  .l-xl-67rem {
    left: 67rem !important;
  }
  .l-xl-68px {
    left: 68px !important;
  }
  .l-xl-68rem {
    left: 68rem !important;
  }
  .l-xl-69px {
    left: 69px !important;
  }
  .l-xl-69rem {
    left: 69rem !important;
  }
  .l-xl-70px {
    left: 70px !important;
  }
  .l-xl-70rem {
    left: 70rem !important;
  }
  .l-xl-71px {
    left: 71px !important;
  }
  .l-xl-71rem {
    left: 71rem !important;
  }
  .l-xl-72px {
    left: 72px !important;
  }
  .l-xl-72rem {
    left: 72rem !important;
  }
  .l-xl-73px {
    left: 73px !important;
  }
  .l-xl-73rem {
    left: 73rem !important;
  }
  .l-xl-74px {
    left: 74px !important;
  }
  .l-xl-74rem {
    left: 74rem !important;
  }
  .l-xl-75px {
    left: 75px !important;
  }
  .l-xl-75rem {
    left: 75rem !important;
  }
  .l-xl-76px {
    left: 76px !important;
  }
  .l-xl-76rem {
    left: 76rem !important;
  }
  .l-xl-77px {
    left: 77px !important;
  }
  .l-xl-77rem {
    left: 77rem !important;
  }
  .l-xl-78px {
    left: 78px !important;
  }
  .l-xl-78rem {
    left: 78rem !important;
  }
  .l-xl-79px {
    left: 79px !important;
  }
  .l-xl-79rem {
    left: 79rem !important;
  }
  .l-xl-80px {
    left: 80px !important;
  }
  .l-xl-80rem {
    left: 80rem !important;
  }
  .l-xl-81px {
    left: 81px !important;
  }
  .l-xl-81rem {
    left: 81rem !important;
  }
  .l-xl-82px {
    left: 82px !important;
  }
  .l-xl-82rem {
    left: 82rem !important;
  }
  .l-xl-83px {
    left: 83px !important;
  }
  .l-xl-83rem {
    left: 83rem !important;
  }
  .l-xl-84px {
    left: 84px !important;
  }
  .l-xl-84rem {
    left: 84rem !important;
  }
  .l-xl-85px {
    left: 85px !important;
  }
  .l-xl-85rem {
    left: 85rem !important;
  }
  .l-xl-86px {
    left: 86px !important;
  }
  .l-xl-86rem {
    left: 86rem !important;
  }
  .l-xl-87px {
    left: 87px !important;
  }
  .l-xl-87rem {
    left: 87rem !important;
  }
  .l-xl-88px {
    left: 88px !important;
  }
  .l-xl-88rem {
    left: 88rem !important;
  }
  .l-xl-89px {
    left: 89px !important;
  }
  .l-xl-89rem {
    left: 89rem !important;
  }
  .l-xl-90px {
    left: 90px !important;
  }
  .l-xl-90rem {
    left: 90rem !important;
  }
  .l-xl-91px {
    left: 91px !important;
  }
  .l-xl-91rem {
    left: 91rem !important;
  }
  .l-xl-92px {
    left: 92px !important;
  }
  .l-xl-92rem {
    left: 92rem !important;
  }
  .l-xl-93px {
    left: 93px !important;
  }
  .l-xl-93rem {
    left: 93rem !important;
  }
  .l-xl-94px {
    left: 94px !important;
  }
  .l-xl-94rem {
    left: 94rem !important;
  }
  .l-xl-95px {
    left: 95px !important;
  }
  .l-xl-95rem {
    left: 95rem !important;
  }
  .l-xl-96px {
    left: 96px !important;
  }
  .l-xl-96rem {
    left: 96rem !important;
  }
  .l-xl-97px {
    left: 97px !important;
  }
  .l-xl-97rem {
    left: 97rem !important;
  }
  .l-xl-98px {
    left: 98px !important;
  }
  .l-xl-98rem {
    left: 98rem !important;
  }
  .l-xl-99px {
    left: 99px !important;
  }
  .l-xl-99rem {
    left: 99rem !important;
  }
  .l-xl-100px {
    left: 100px !important;
  }
  .l-xl-100rem {
    left: 100rem !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .pt-xl-0px {
    padding-top: 0px !important;
  }
  .pb-xl-0px {
    padding-bottom: 0px !important;
  }
  .pt-xl-5px {
    padding-top: 5px !important;
  }
  .pb-xl-5px {
    padding-bottom: 5px !important;
  }
  .pt-xl-10px {
    padding-top: 10px !important;
  }
  .pb-xl-10px {
    padding-bottom: 10px !important;
  }
  .pt-xl-15px {
    padding-top: 15px !important;
  }
  .pb-xl-15px {
    padding-bottom: 15px !important;
  }
  .pt-xl-20px {
    padding-top: 20px !important;
  }
  .pb-xl-20px {
    padding-bottom: 20px !important;
  }
  .pt-xl-25px {
    padding-top: 25px !important;
  }
  .pb-xl-25px {
    padding-bottom: 25px !important;
  }
  .pt-xl-30px {
    padding-top: 30px !important;
  }
  .pb-xl-30px {
    padding-bottom: 30px !important;
  }
  .pt-xl-35px {
    padding-top: 35px !important;
  }
  .pb-xl-35px {
    padding-bottom: 35px !important;
  }
  .pt-xl-40px {
    padding-top: 40px !important;
  }
  .pb-xl-40px {
    padding-bottom: 40px !important;
  }
  .pt-xl-45px {
    padding-top: 45px !important;
  }
  .pb-xl-45px {
    padding-bottom: 45px !important;
  }
  .pt-xl-50px {
    padding-top: 50px !important;
  }
  .pb-xl-50px {
    padding-bottom: 50px !important;
  }
  .pt-xl-55px {
    padding-top: 55px !important;
  }
  .pb-xl-55px {
    padding-bottom: 55px !important;
  }
  .pt-xl-60px {
    padding-top: 60px !important;
  }
  .pb-xl-60px {
    padding-bottom: 60px !important;
  }
  .pt-xl-65px {
    padding-top: 65px !important;
  }
  .pb-xl-65px {
    padding-bottom: 65px !important;
  }
  .pt-xl-70px {
    padding-top: 70px !important;
  }
  .pb-xl-70px {
    padding-bottom: 70px !important;
  }
  .pt-xl-75px {
    padding-top: 75px !important;
  }
  .pb-xl-75px {
    padding-bottom: 75px !important;
  }
  .pt-xl-80px {
    padding-top: 80px !important;
  }
  .pb-xl-80px {
    padding-bottom: 80px !important;
  }
  .pt-xl-85px {
    padding-top: 85px !important;
  }
  .pb-xl-85px {
    padding-bottom: 85px !important;
  }
  .pt-xl-90px {
    padding-top: 90px !important;
  }
  .pb-xl-90px {
    padding-bottom: 90px !important;
  }
  .pt-xl-95px {
    padding-top: 95px !important;
  }
  .pb-xl-95px {
    padding-bottom: 95px !important;
  }
  .pt-xl-100px {
    padding-top: 100px !important;
  }
  .pb-xl-100px {
    padding-bottom: 100px !important;
  }
  .mt-xl-0px {
    margin-top: 0px !important;
  }
  .mb-xl-0px {
    margin-bottom: 0px !important;
  }
  .mt-xl-5px {
    margin-top: 5px !important;
  }
  .mb-xl-5px {
    margin-bottom: 5px !important;
  }
  .mt-xl-10px {
    margin-top: 10px !important;
  }
  .mb-xl-10px {
    margin-bottom: 10px !important;
  }
  .mt-xl-15px {
    margin-top: 15px !important;
  }
  .mb-xl-15px {
    margin-bottom: 15px !important;
  }
  .mt-xl-20px {
    margin-top: 20px !important;
  }
  .mb-xl-20px {
    margin-bottom: 20px !important;
  }
  .mt-xl-25px {
    margin-top: 25px !important;
  }
  .mb-xl-25px {
    margin-bottom: 25px !important;
  }
  .mt-xl-30px {
    margin-top: 30px !important;
  }
  .mb-xl-30px {
    margin-bottom: 30px !important;
  }
  .mt-xl-35px {
    margin-top: 35px !important;
  }
  .mb-xl-35px {
    margin-bottom: 35px !important;
  }
  .mt-xl-40px {
    margin-top: 40px !important;
  }
  .mb-xl-40px {
    margin-bottom: 40px !important;
  }
  .mt-xl-45px {
    margin-top: 45px !important;
  }
  .mb-xl-45px {
    margin-bottom: 45px !important;
  }
  .mt-xl-50px {
    margin-top: 50px !important;
  }
  .mb-xl-50px {
    margin-bottom: 50px !important;
  }
  .mt-xl-55px {
    margin-top: 55px !important;
  }
  .mb-xl-55px {
    margin-bottom: 55px !important;
  }
  .mt-xl-60px {
    margin-top: 60px !important;
  }
  .mb-xl-60px {
    margin-bottom: 60px !important;
  }
  .mt-xl-65px {
    margin-top: 65px !important;
  }
  .mb-xl-65px {
    margin-bottom: 65px !important;
  }
  .mt-xl-70px {
    margin-top: 70px !important;
  }
  .mb-xl-70px {
    margin-bottom: 70px !important;
  }
  .mt-xl-75px {
    margin-top: 75px !important;
  }
  .mb-xl-75px {
    margin-bottom: 75px !important;
  }
  .mt-xl-80px {
    margin-top: 80px !important;
  }
  .mb-xl-80px {
    margin-bottom: 80px !important;
  }
  .mt-xl-85px {
    margin-top: 85px !important;
  }
  .mb-xl-85px {
    margin-bottom: 85px !important;
  }
  .mt-xl-90px {
    margin-top: 90px !important;
  }
  .mb-xl-90px {
    margin-bottom: 90px !important;
  }
  .mt-xl-95px {
    margin-top: 95px !important;
  }
  .mb-xl-95px {
    margin-bottom: 95px !important;
  }
  .mt-xl-100px {
    margin-top: 100px !important;
  }
  .mb-xl-100px {
    margin-bottom: 100px !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-top-xl-1px-s-2f2f2f {
    border-top: 1px solid #2f2f2f !important;
  }
}

@media (min-width: 1600px) {
  .d-xxl-table-row {
    display: table-row !important;
  }
  .fixed-xxl-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .order-xxl-initial {
    -ms-flex-order: initial;
        order: initial;
  }
  .w-xxl-0px {
    width: 0px !important;
  }
  .w-xxl-0rem {
    width: 0rem !important;
  }
  .w-xxl-1px {
    width: 1px !important;
  }
  .w-xxl-1rem {
    width: 1rem !important;
  }
  .w-xxl-2px {
    width: 2px !important;
  }
  .w-xxl-2rem {
    width: 2rem !important;
  }
  .w-xxl-3px {
    width: 3px !important;
  }
  .w-xxl-3rem {
    width: 3rem !important;
  }
  .w-xxl-4px {
    width: 4px !important;
  }
  .w-xxl-4rem {
    width: 4rem !important;
  }
  .w-xxl-5px {
    width: 5px !important;
  }
  .w-xxl-5rem {
    width: 5rem !important;
  }
  .w-xxl-6px {
    width: 6px !important;
  }
  .w-xxl-6rem {
    width: 6rem !important;
  }
  .w-xxl-7px {
    width: 7px !important;
  }
  .w-xxl-7rem {
    width: 7rem !important;
  }
  .w-xxl-8px {
    width: 8px !important;
  }
  .w-xxl-8rem {
    width: 8rem !important;
  }
  .w-xxl-9px {
    width: 9px !important;
  }
  .w-xxl-9rem {
    width: 9rem !important;
  }
  .w-xxl-10px {
    width: 10px !important;
  }
  .w-xxl-10rem {
    width: 10rem !important;
  }
  .w-xxl-11px {
    width: 11px !important;
  }
  .w-xxl-11rem {
    width: 11rem !important;
  }
  .w-xxl-12px {
    width: 12px !important;
  }
  .w-xxl-12rem {
    width: 12rem !important;
  }
  .w-xxl-13px {
    width: 13px !important;
  }
  .w-xxl-13rem {
    width: 13rem !important;
  }
  .w-xxl-14px {
    width: 14px !important;
  }
  .w-xxl-14rem {
    width: 14rem !important;
  }
  .w-xxl-15px {
    width: 15px !important;
  }
  .w-xxl-15rem {
    width: 15rem !important;
  }
  .w-xxl-16px {
    width: 16px !important;
  }
  .w-xxl-16rem {
    width: 16rem !important;
  }
  .w-xxl-17px {
    width: 17px !important;
  }
  .w-xxl-17rem {
    width: 17rem !important;
  }
  .w-xxl-18px {
    width: 18px !important;
  }
  .w-xxl-18rem {
    width: 18rem !important;
  }
  .w-xxl-19px {
    width: 19px !important;
  }
  .w-xxl-19rem {
    width: 19rem !important;
  }
  .w-xxl-20px {
    width: 20px !important;
  }
  .w-xxl-20rem {
    width: 20rem !important;
  }
  .w-xxl-21px {
    width: 21px !important;
  }
  .w-xxl-21rem {
    width: 21rem !important;
  }
  .w-xxl-22px {
    width: 22px !important;
  }
  .w-xxl-22rem {
    width: 22rem !important;
  }
  .w-xxl-23px {
    width: 23px !important;
  }
  .w-xxl-23rem {
    width: 23rem !important;
  }
  .w-xxl-24px {
    width: 24px !important;
  }
  .w-xxl-24rem {
    width: 24rem !important;
  }
  .w-xxl-25px {
    width: 25px !important;
  }
  .w-xxl-25rem {
    width: 25rem !important;
  }
  .w-xxl-26px {
    width: 26px !important;
  }
  .w-xxl-26rem {
    width: 26rem !important;
  }
  .w-xxl-27px {
    width: 27px !important;
  }
  .w-xxl-27rem {
    width: 27rem !important;
  }
  .w-xxl-28px {
    width: 28px !important;
  }
  .w-xxl-28rem {
    width: 28rem !important;
  }
  .w-xxl-29px {
    width: 29px !important;
  }
  .w-xxl-29rem {
    width: 29rem !important;
  }
  .w-xxl-30px {
    width: 30px !important;
  }
  .w-xxl-30rem {
    width: 30rem !important;
  }
  .w-xxl-31px {
    width: 31px !important;
  }
  .w-xxl-31rem {
    width: 31rem !important;
  }
  .w-xxl-32px {
    width: 32px !important;
  }
  .w-xxl-32rem {
    width: 32rem !important;
  }
  .w-xxl-33px {
    width: 33px !important;
  }
  .w-xxl-33rem {
    width: 33rem !important;
  }
  .w-xxl-34px {
    width: 34px !important;
  }
  .w-xxl-34rem {
    width: 34rem !important;
  }
  .w-xxl-35px {
    width: 35px !important;
  }
  .w-xxl-35rem {
    width: 35rem !important;
  }
  .w-xxl-36px {
    width: 36px !important;
  }
  .w-xxl-36rem {
    width: 36rem !important;
  }
  .w-xxl-37px {
    width: 37px !important;
  }
  .w-xxl-37rem {
    width: 37rem !important;
  }
  .w-xxl-38px {
    width: 38px !important;
  }
  .w-xxl-38rem {
    width: 38rem !important;
  }
  .w-xxl-39px {
    width: 39px !important;
  }
  .w-xxl-39rem {
    width: 39rem !important;
  }
  .w-xxl-40px {
    width: 40px !important;
  }
  .w-xxl-40rem {
    width: 40rem !important;
  }
  .w-xxl-41px {
    width: 41px !important;
  }
  .w-xxl-41rem {
    width: 41rem !important;
  }
  .w-xxl-42px {
    width: 42px !important;
  }
  .w-xxl-42rem {
    width: 42rem !important;
  }
  .w-xxl-43px {
    width: 43px !important;
  }
  .w-xxl-43rem {
    width: 43rem !important;
  }
  .w-xxl-44px {
    width: 44px !important;
  }
  .w-xxl-44rem {
    width: 44rem !important;
  }
  .w-xxl-45px {
    width: 45px !important;
  }
  .w-xxl-45rem {
    width: 45rem !important;
  }
  .w-xxl-46px {
    width: 46px !important;
  }
  .w-xxl-46rem {
    width: 46rem !important;
  }
  .w-xxl-47px {
    width: 47px !important;
  }
  .w-xxl-47rem {
    width: 47rem !important;
  }
  .w-xxl-48px {
    width: 48px !important;
  }
  .w-xxl-48rem {
    width: 48rem !important;
  }
  .w-xxl-49px {
    width: 49px !important;
  }
  .w-xxl-49rem {
    width: 49rem !important;
  }
  .w-xxl-50px {
    width: 50px !important;
  }
  .w-xxl-50rem {
    width: 50rem !important;
  }
  .w-xxl-51px {
    width: 51px !important;
  }
  .w-xxl-51rem {
    width: 51rem !important;
  }
  .w-xxl-52px {
    width: 52px !important;
  }
  .w-xxl-52rem {
    width: 52rem !important;
  }
  .w-xxl-53px {
    width: 53px !important;
  }
  .w-xxl-53rem {
    width: 53rem !important;
  }
  .w-xxl-54px {
    width: 54px !important;
  }
  .w-xxl-54rem {
    width: 54rem !important;
  }
  .w-xxl-55px {
    width: 55px !important;
  }
  .w-xxl-55rem {
    width: 55rem !important;
  }
  .w-xxl-56px {
    width: 56px !important;
  }
  .w-xxl-56rem {
    width: 56rem !important;
  }
  .w-xxl-57px {
    width: 57px !important;
  }
  .w-xxl-57rem {
    width: 57rem !important;
  }
  .w-xxl-58px {
    width: 58px !important;
  }
  .w-xxl-58rem {
    width: 58rem !important;
  }
  .w-xxl-59px {
    width: 59px !important;
  }
  .w-xxl-59rem {
    width: 59rem !important;
  }
  .w-xxl-60px {
    width: 60px !important;
  }
  .w-xxl-60rem {
    width: 60rem !important;
  }
  .w-xxl-61px {
    width: 61px !important;
  }
  .w-xxl-61rem {
    width: 61rem !important;
  }
  .w-xxl-62px {
    width: 62px !important;
  }
  .w-xxl-62rem {
    width: 62rem !important;
  }
  .w-xxl-63px {
    width: 63px !important;
  }
  .w-xxl-63rem {
    width: 63rem !important;
  }
  .w-xxl-64px {
    width: 64px !important;
  }
  .w-xxl-64rem {
    width: 64rem !important;
  }
  .w-xxl-65px {
    width: 65px !important;
  }
  .w-xxl-65rem {
    width: 65rem !important;
  }
  .w-xxl-66px {
    width: 66px !important;
  }
  .w-xxl-66rem {
    width: 66rem !important;
  }
  .w-xxl-67px {
    width: 67px !important;
  }
  .w-xxl-67rem {
    width: 67rem !important;
  }
  .w-xxl-68px {
    width: 68px !important;
  }
  .w-xxl-68rem {
    width: 68rem !important;
  }
  .w-xxl-69px {
    width: 69px !important;
  }
  .w-xxl-69rem {
    width: 69rem !important;
  }
  .w-xxl-70px {
    width: 70px !important;
  }
  .w-xxl-70rem {
    width: 70rem !important;
  }
  .w-xxl-71px {
    width: 71px !important;
  }
  .w-xxl-71rem {
    width: 71rem !important;
  }
  .w-xxl-72px {
    width: 72px !important;
  }
  .w-xxl-72rem {
    width: 72rem !important;
  }
  .w-xxl-73px {
    width: 73px !important;
  }
  .w-xxl-73rem {
    width: 73rem !important;
  }
  .w-xxl-74px {
    width: 74px !important;
  }
  .w-xxl-74rem {
    width: 74rem !important;
  }
  .w-xxl-75px {
    width: 75px !important;
  }
  .w-xxl-75rem {
    width: 75rem !important;
  }
  .w-xxl-76px {
    width: 76px !important;
  }
  .w-xxl-76rem {
    width: 76rem !important;
  }
  .w-xxl-77px {
    width: 77px !important;
  }
  .w-xxl-77rem {
    width: 77rem !important;
  }
  .w-xxl-78px {
    width: 78px !important;
  }
  .w-xxl-78rem {
    width: 78rem !important;
  }
  .w-xxl-79px {
    width: 79px !important;
  }
  .w-xxl-79rem {
    width: 79rem !important;
  }
  .w-xxl-80px {
    width: 80px !important;
  }
  .w-xxl-80rem {
    width: 80rem !important;
  }
  .w-xxl-81px {
    width: 81px !important;
  }
  .w-xxl-81rem {
    width: 81rem !important;
  }
  .w-xxl-82px {
    width: 82px !important;
  }
  .w-xxl-82rem {
    width: 82rem !important;
  }
  .w-xxl-83px {
    width: 83px !important;
  }
  .w-xxl-83rem {
    width: 83rem !important;
  }
  .w-xxl-84px {
    width: 84px !important;
  }
  .w-xxl-84rem {
    width: 84rem !important;
  }
  .w-xxl-85px {
    width: 85px !important;
  }
  .w-xxl-85rem {
    width: 85rem !important;
  }
  .w-xxl-86px {
    width: 86px !important;
  }
  .w-xxl-86rem {
    width: 86rem !important;
  }
  .w-xxl-87px {
    width: 87px !important;
  }
  .w-xxl-87rem {
    width: 87rem !important;
  }
  .w-xxl-88px {
    width: 88px !important;
  }
  .w-xxl-88rem {
    width: 88rem !important;
  }
  .w-xxl-89px {
    width: 89px !important;
  }
  .w-xxl-89rem {
    width: 89rem !important;
  }
  .w-xxl-90px {
    width: 90px !important;
  }
  .w-xxl-90rem {
    width: 90rem !important;
  }
  .w-xxl-91px {
    width: 91px !important;
  }
  .w-xxl-91rem {
    width: 91rem !important;
  }
  .w-xxl-92px {
    width: 92px !important;
  }
  .w-xxl-92rem {
    width: 92rem !important;
  }
  .w-xxl-93px {
    width: 93px !important;
  }
  .w-xxl-93rem {
    width: 93rem !important;
  }
  .w-xxl-94px {
    width: 94px !important;
  }
  .w-xxl-94rem {
    width: 94rem !important;
  }
  .w-xxl-95px {
    width: 95px !important;
  }
  .w-xxl-95rem {
    width: 95rem !important;
  }
  .w-xxl-96px {
    width: 96px !important;
  }
  .w-xxl-96rem {
    width: 96rem !important;
  }
  .w-xxl-97px {
    width: 97px !important;
  }
  .w-xxl-97rem {
    width: 97rem !important;
  }
  .w-xxl-98px {
    width: 98px !important;
  }
  .w-xxl-98rem {
    width: 98rem !important;
  }
  .w-xxl-99px {
    width: 99px !important;
  }
  .w-xxl-99rem {
    width: 99rem !important;
  }
  .w-xxl-100px {
    width: 100px !important;
  }
  .w-xxl-100rem {
    width: 100rem !important;
  }
  .h-xxl-0px {
    height: 0px !important;
  }
  .h-xxl-0rem {
    height: 0rem !important;
  }
  .h-xxl-1px {
    height: 1px !important;
  }
  .h-xxl-1rem {
    height: 1rem !important;
  }
  .h-xxl-2px {
    height: 2px !important;
  }
  .h-xxl-2rem {
    height: 2rem !important;
  }
  .h-xxl-3px {
    height: 3px !important;
  }
  .h-xxl-3rem {
    height: 3rem !important;
  }
  .h-xxl-4px {
    height: 4px !important;
  }
  .h-xxl-4rem {
    height: 4rem !important;
  }
  .h-xxl-5px {
    height: 5px !important;
  }
  .h-xxl-5rem {
    height: 5rem !important;
  }
  .h-xxl-6px {
    height: 6px !important;
  }
  .h-xxl-6rem {
    height: 6rem !important;
  }
  .h-xxl-7px {
    height: 7px !important;
  }
  .h-xxl-7rem {
    height: 7rem !important;
  }
  .h-xxl-8px {
    height: 8px !important;
  }
  .h-xxl-8rem {
    height: 8rem !important;
  }
  .h-xxl-9px {
    height: 9px !important;
  }
  .h-xxl-9rem {
    height: 9rem !important;
  }
  .h-xxl-10px {
    height: 10px !important;
  }
  .h-xxl-10rem {
    height: 10rem !important;
  }
  .h-xxl-11px {
    height: 11px !important;
  }
  .h-xxl-11rem {
    height: 11rem !important;
  }
  .h-xxl-12px {
    height: 12px !important;
  }
  .h-xxl-12rem {
    height: 12rem !important;
  }
  .h-xxl-13px {
    height: 13px !important;
  }
  .h-xxl-13rem {
    height: 13rem !important;
  }
  .h-xxl-14px {
    height: 14px !important;
  }
  .h-xxl-14rem {
    height: 14rem !important;
  }
  .h-xxl-15px {
    height: 15px !important;
  }
  .h-xxl-15rem {
    height: 15rem !important;
  }
  .h-xxl-16px {
    height: 16px !important;
  }
  .h-xxl-16rem {
    height: 16rem !important;
  }
  .h-xxl-17px {
    height: 17px !important;
  }
  .h-xxl-17rem {
    height: 17rem !important;
  }
  .h-xxl-18px {
    height: 18px !important;
  }
  .h-xxl-18rem {
    height: 18rem !important;
  }
  .h-xxl-19px {
    height: 19px !important;
  }
  .h-xxl-19rem {
    height: 19rem !important;
  }
  .h-xxl-20px {
    height: 20px !important;
  }
  .h-xxl-20rem {
    height: 20rem !important;
  }
  .h-xxl-21px {
    height: 21px !important;
  }
  .h-xxl-21rem {
    height: 21rem !important;
  }
  .h-xxl-22px {
    height: 22px !important;
  }
  .h-xxl-22rem {
    height: 22rem !important;
  }
  .h-xxl-23px {
    height: 23px !important;
  }
  .h-xxl-23rem {
    height: 23rem !important;
  }
  .h-xxl-24px {
    height: 24px !important;
  }
  .h-xxl-24rem {
    height: 24rem !important;
  }
  .h-xxl-25px {
    height: 25px !important;
  }
  .h-xxl-25rem {
    height: 25rem !important;
  }
  .h-xxl-26px {
    height: 26px !important;
  }
  .h-xxl-26rem {
    height: 26rem !important;
  }
  .h-xxl-27px {
    height: 27px !important;
  }
  .h-xxl-27rem {
    height: 27rem !important;
  }
  .h-xxl-28px {
    height: 28px !important;
  }
  .h-xxl-28rem {
    height: 28rem !important;
  }
  .h-xxl-29px {
    height: 29px !important;
  }
  .h-xxl-29rem {
    height: 29rem !important;
  }
  .h-xxl-30px {
    height: 30px !important;
  }
  .h-xxl-30rem {
    height: 30rem !important;
  }
  .h-xxl-31px {
    height: 31px !important;
  }
  .h-xxl-31rem {
    height: 31rem !important;
  }
  .h-xxl-32px {
    height: 32px !important;
  }
  .h-xxl-32rem {
    height: 32rem !important;
  }
  .h-xxl-33px {
    height: 33px !important;
  }
  .h-xxl-33rem {
    height: 33rem !important;
  }
  .h-xxl-34px {
    height: 34px !important;
  }
  .h-xxl-34rem {
    height: 34rem !important;
  }
  .h-xxl-35px {
    height: 35px !important;
  }
  .h-xxl-35rem {
    height: 35rem !important;
  }
  .h-xxl-36px {
    height: 36px !important;
  }
  .h-xxl-36rem {
    height: 36rem !important;
  }
  .h-xxl-37px {
    height: 37px !important;
  }
  .h-xxl-37rem {
    height: 37rem !important;
  }
  .h-xxl-38px {
    height: 38px !important;
  }
  .h-xxl-38rem {
    height: 38rem !important;
  }
  .h-xxl-39px {
    height: 39px !important;
  }
  .h-xxl-39rem {
    height: 39rem !important;
  }
  .h-xxl-40px {
    height: 40px !important;
  }
  .h-xxl-40rem {
    height: 40rem !important;
  }
  .h-xxl-41px {
    height: 41px !important;
  }
  .h-xxl-41rem {
    height: 41rem !important;
  }
  .h-xxl-42px {
    height: 42px !important;
  }
  .h-xxl-42rem {
    height: 42rem !important;
  }
  .h-xxl-43px {
    height: 43px !important;
  }
  .h-xxl-43rem {
    height: 43rem !important;
  }
  .h-xxl-44px {
    height: 44px !important;
  }
  .h-xxl-44rem {
    height: 44rem !important;
  }
  .h-xxl-45px {
    height: 45px !important;
  }
  .h-xxl-45rem {
    height: 45rem !important;
  }
  .h-xxl-46px {
    height: 46px !important;
  }
  .h-xxl-46rem {
    height: 46rem !important;
  }
  .h-xxl-47px {
    height: 47px !important;
  }
  .h-xxl-47rem {
    height: 47rem !important;
  }
  .h-xxl-48px {
    height: 48px !important;
  }
  .h-xxl-48rem {
    height: 48rem !important;
  }
  .h-xxl-49px {
    height: 49px !important;
  }
  .h-xxl-49rem {
    height: 49rem !important;
  }
  .h-xxl-50px {
    height: 50px !important;
  }
  .h-xxl-50rem {
    height: 50rem !important;
  }
  .h-xxl-51px {
    height: 51px !important;
  }
  .h-xxl-51rem {
    height: 51rem !important;
  }
  .h-xxl-52px {
    height: 52px !important;
  }
  .h-xxl-52rem {
    height: 52rem !important;
  }
  .h-xxl-53px {
    height: 53px !important;
  }
  .h-xxl-53rem {
    height: 53rem !important;
  }
  .h-xxl-54px {
    height: 54px !important;
  }
  .h-xxl-54rem {
    height: 54rem !important;
  }
  .h-xxl-55px {
    height: 55px !important;
  }
  .h-xxl-55rem {
    height: 55rem !important;
  }
  .h-xxl-56px {
    height: 56px !important;
  }
  .h-xxl-56rem {
    height: 56rem !important;
  }
  .h-xxl-57px {
    height: 57px !important;
  }
  .h-xxl-57rem {
    height: 57rem !important;
  }
  .h-xxl-58px {
    height: 58px !important;
  }
  .h-xxl-58rem {
    height: 58rem !important;
  }
  .h-xxl-59px {
    height: 59px !important;
  }
  .h-xxl-59rem {
    height: 59rem !important;
  }
  .h-xxl-60px {
    height: 60px !important;
  }
  .h-xxl-60rem {
    height: 60rem !important;
  }
  .h-xxl-61px {
    height: 61px !important;
  }
  .h-xxl-61rem {
    height: 61rem !important;
  }
  .h-xxl-62px {
    height: 62px !important;
  }
  .h-xxl-62rem {
    height: 62rem !important;
  }
  .h-xxl-63px {
    height: 63px !important;
  }
  .h-xxl-63rem {
    height: 63rem !important;
  }
  .h-xxl-64px {
    height: 64px !important;
  }
  .h-xxl-64rem {
    height: 64rem !important;
  }
  .h-xxl-65px {
    height: 65px !important;
  }
  .h-xxl-65rem {
    height: 65rem !important;
  }
  .h-xxl-66px {
    height: 66px !important;
  }
  .h-xxl-66rem {
    height: 66rem !important;
  }
  .h-xxl-67px {
    height: 67px !important;
  }
  .h-xxl-67rem {
    height: 67rem !important;
  }
  .h-xxl-68px {
    height: 68px !important;
  }
  .h-xxl-68rem {
    height: 68rem !important;
  }
  .h-xxl-69px {
    height: 69px !important;
  }
  .h-xxl-69rem {
    height: 69rem !important;
  }
  .h-xxl-70px {
    height: 70px !important;
  }
  .h-xxl-70rem {
    height: 70rem !important;
  }
  .h-xxl-71px {
    height: 71px !important;
  }
  .h-xxl-71rem {
    height: 71rem !important;
  }
  .h-xxl-72px {
    height: 72px !important;
  }
  .h-xxl-72rem {
    height: 72rem !important;
  }
  .h-xxl-73px {
    height: 73px !important;
  }
  .h-xxl-73rem {
    height: 73rem !important;
  }
  .h-xxl-74px {
    height: 74px !important;
  }
  .h-xxl-74rem {
    height: 74rem !important;
  }
  .h-xxl-75px {
    height: 75px !important;
  }
  .h-xxl-75rem {
    height: 75rem !important;
  }
  .h-xxl-76px {
    height: 76px !important;
  }
  .h-xxl-76rem {
    height: 76rem !important;
  }
  .h-xxl-77px {
    height: 77px !important;
  }
  .h-xxl-77rem {
    height: 77rem !important;
  }
  .h-xxl-78px {
    height: 78px !important;
  }
  .h-xxl-78rem {
    height: 78rem !important;
  }
  .h-xxl-79px {
    height: 79px !important;
  }
  .h-xxl-79rem {
    height: 79rem !important;
  }
  .h-xxl-80px {
    height: 80px !important;
  }
  .h-xxl-80rem {
    height: 80rem !important;
  }
  .h-xxl-81px {
    height: 81px !important;
  }
  .h-xxl-81rem {
    height: 81rem !important;
  }
  .h-xxl-82px {
    height: 82px !important;
  }
  .h-xxl-82rem {
    height: 82rem !important;
  }
  .h-xxl-83px {
    height: 83px !important;
  }
  .h-xxl-83rem {
    height: 83rem !important;
  }
  .h-xxl-84px {
    height: 84px !important;
  }
  .h-xxl-84rem {
    height: 84rem !important;
  }
  .h-xxl-85px {
    height: 85px !important;
  }
  .h-xxl-85rem {
    height: 85rem !important;
  }
  .h-xxl-86px {
    height: 86px !important;
  }
  .h-xxl-86rem {
    height: 86rem !important;
  }
  .h-xxl-87px {
    height: 87px !important;
  }
  .h-xxl-87rem {
    height: 87rem !important;
  }
  .h-xxl-88px {
    height: 88px !important;
  }
  .h-xxl-88rem {
    height: 88rem !important;
  }
  .h-xxl-89px {
    height: 89px !important;
  }
  .h-xxl-89rem {
    height: 89rem !important;
  }
  .h-xxl-90px {
    height: 90px !important;
  }
  .h-xxl-90rem {
    height: 90rem !important;
  }
  .h-xxl-91px {
    height: 91px !important;
  }
  .h-xxl-91rem {
    height: 91rem !important;
  }
  .h-xxl-92px {
    height: 92px !important;
  }
  .h-xxl-92rem {
    height: 92rem !important;
  }
  .h-xxl-93px {
    height: 93px !important;
  }
  .h-xxl-93rem {
    height: 93rem !important;
  }
  .h-xxl-94px {
    height: 94px !important;
  }
  .h-xxl-94rem {
    height: 94rem !important;
  }
  .h-xxl-95px {
    height: 95px !important;
  }
  .h-xxl-95rem {
    height: 95rem !important;
  }
  .h-xxl-96px {
    height: 96px !important;
  }
  .h-xxl-96rem {
    height: 96rem !important;
  }
  .h-xxl-97px {
    height: 97px !important;
  }
  .h-xxl-97rem {
    height: 97rem !important;
  }
  .h-xxl-98px {
    height: 98px !important;
  }
  .h-xxl-98rem {
    height: 98rem !important;
  }
  .h-xxl-99px {
    height: 99px !important;
  }
  .h-xxl-99rem {
    height: 99rem !important;
  }
  .h-xxl-100px {
    height: 100px !important;
  }
  .h-xxl-100rem {
    height: 100rem !important;
  }
  .t-xxl-0px {
    top: 0px !important;
  }
  .t-xxl-0rem {
    top: 0rem !important;
  }
  .t-xxl-1px {
    top: 1px !important;
  }
  .t-xxl-1rem {
    top: 1rem !important;
  }
  .t-xxl-2px {
    top: 2px !important;
  }
  .t-xxl-2rem {
    top: 2rem !important;
  }
  .t-xxl-3px {
    top: 3px !important;
  }
  .t-xxl-3rem {
    top: 3rem !important;
  }
  .t-xxl-4px {
    top: 4px !important;
  }
  .t-xxl-4rem {
    top: 4rem !important;
  }
  .t-xxl-5px {
    top: 5px !important;
  }
  .t-xxl-5rem {
    top: 5rem !important;
  }
  .t-xxl-6px {
    top: 6px !important;
  }
  .t-xxl-6rem {
    top: 6rem !important;
  }
  .t-xxl-7px {
    top: 7px !important;
  }
  .t-xxl-7rem {
    top: 7rem !important;
  }
  .t-xxl-8px {
    top: 8px !important;
  }
  .t-xxl-8rem {
    top: 8rem !important;
  }
  .t-xxl-9px {
    top: 9px !important;
  }
  .t-xxl-9rem {
    top: 9rem !important;
  }
  .t-xxl-10px {
    top: 10px !important;
  }
  .t-xxl-10rem {
    top: 10rem !important;
  }
  .t-xxl-11px {
    top: 11px !important;
  }
  .t-xxl-11rem {
    top: 11rem !important;
  }
  .t-xxl-12px {
    top: 12px !important;
  }
  .t-xxl-12rem {
    top: 12rem !important;
  }
  .t-xxl-13px {
    top: 13px !important;
  }
  .t-xxl-13rem {
    top: 13rem !important;
  }
  .t-xxl-14px {
    top: 14px !important;
  }
  .t-xxl-14rem {
    top: 14rem !important;
  }
  .t-xxl-15px {
    top: 15px !important;
  }
  .t-xxl-15rem {
    top: 15rem !important;
  }
  .t-xxl-16px {
    top: 16px !important;
  }
  .t-xxl-16rem {
    top: 16rem !important;
  }
  .t-xxl-17px {
    top: 17px !important;
  }
  .t-xxl-17rem {
    top: 17rem !important;
  }
  .t-xxl-18px {
    top: 18px !important;
  }
  .t-xxl-18rem {
    top: 18rem !important;
  }
  .t-xxl-19px {
    top: 19px !important;
  }
  .t-xxl-19rem {
    top: 19rem !important;
  }
  .t-xxl-20px {
    top: 20px !important;
  }
  .t-xxl-20rem {
    top: 20rem !important;
  }
  .t-xxl-21px {
    top: 21px !important;
  }
  .t-xxl-21rem {
    top: 21rem !important;
  }
  .t-xxl-22px {
    top: 22px !important;
  }
  .t-xxl-22rem {
    top: 22rem !important;
  }
  .t-xxl-23px {
    top: 23px !important;
  }
  .t-xxl-23rem {
    top: 23rem !important;
  }
  .t-xxl-24px {
    top: 24px !important;
  }
  .t-xxl-24rem {
    top: 24rem !important;
  }
  .t-xxl-25px {
    top: 25px !important;
  }
  .t-xxl-25rem {
    top: 25rem !important;
  }
  .t-xxl-26px {
    top: 26px !important;
  }
  .t-xxl-26rem {
    top: 26rem !important;
  }
  .t-xxl-27px {
    top: 27px !important;
  }
  .t-xxl-27rem {
    top: 27rem !important;
  }
  .t-xxl-28px {
    top: 28px !important;
  }
  .t-xxl-28rem {
    top: 28rem !important;
  }
  .t-xxl-29px {
    top: 29px !important;
  }
  .t-xxl-29rem {
    top: 29rem !important;
  }
  .t-xxl-30px {
    top: 30px !important;
  }
  .t-xxl-30rem {
    top: 30rem !important;
  }
  .t-xxl-31px {
    top: 31px !important;
  }
  .t-xxl-31rem {
    top: 31rem !important;
  }
  .t-xxl-32px {
    top: 32px !important;
  }
  .t-xxl-32rem {
    top: 32rem !important;
  }
  .t-xxl-33px {
    top: 33px !important;
  }
  .t-xxl-33rem {
    top: 33rem !important;
  }
  .t-xxl-34px {
    top: 34px !important;
  }
  .t-xxl-34rem {
    top: 34rem !important;
  }
  .t-xxl-35px {
    top: 35px !important;
  }
  .t-xxl-35rem {
    top: 35rem !important;
  }
  .t-xxl-36px {
    top: 36px !important;
  }
  .t-xxl-36rem {
    top: 36rem !important;
  }
  .t-xxl-37px {
    top: 37px !important;
  }
  .t-xxl-37rem {
    top: 37rem !important;
  }
  .t-xxl-38px {
    top: 38px !important;
  }
  .t-xxl-38rem {
    top: 38rem !important;
  }
  .t-xxl-39px {
    top: 39px !important;
  }
  .t-xxl-39rem {
    top: 39rem !important;
  }
  .t-xxl-40px {
    top: 40px !important;
  }
  .t-xxl-40rem {
    top: 40rem !important;
  }
  .t-xxl-41px {
    top: 41px !important;
  }
  .t-xxl-41rem {
    top: 41rem !important;
  }
  .t-xxl-42px {
    top: 42px !important;
  }
  .t-xxl-42rem {
    top: 42rem !important;
  }
  .t-xxl-43px {
    top: 43px !important;
  }
  .t-xxl-43rem {
    top: 43rem !important;
  }
  .t-xxl-44px {
    top: 44px !important;
  }
  .t-xxl-44rem {
    top: 44rem !important;
  }
  .t-xxl-45px {
    top: 45px !important;
  }
  .t-xxl-45rem {
    top: 45rem !important;
  }
  .t-xxl-46px {
    top: 46px !important;
  }
  .t-xxl-46rem {
    top: 46rem !important;
  }
  .t-xxl-47px {
    top: 47px !important;
  }
  .t-xxl-47rem {
    top: 47rem !important;
  }
  .t-xxl-48px {
    top: 48px !important;
  }
  .t-xxl-48rem {
    top: 48rem !important;
  }
  .t-xxl-49px {
    top: 49px !important;
  }
  .t-xxl-49rem {
    top: 49rem !important;
  }
  .t-xxl-50px {
    top: 50px !important;
  }
  .t-xxl-50rem {
    top: 50rem !important;
  }
  .t-xxl-51px {
    top: 51px !important;
  }
  .t-xxl-51rem {
    top: 51rem !important;
  }
  .t-xxl-52px {
    top: 52px !important;
  }
  .t-xxl-52rem {
    top: 52rem !important;
  }
  .t-xxl-53px {
    top: 53px !important;
  }
  .t-xxl-53rem {
    top: 53rem !important;
  }
  .t-xxl-54px {
    top: 54px !important;
  }
  .t-xxl-54rem {
    top: 54rem !important;
  }
  .t-xxl-55px {
    top: 55px !important;
  }
  .t-xxl-55rem {
    top: 55rem !important;
  }
  .t-xxl-56px {
    top: 56px !important;
  }
  .t-xxl-56rem {
    top: 56rem !important;
  }
  .t-xxl-57px {
    top: 57px !important;
  }
  .t-xxl-57rem {
    top: 57rem !important;
  }
  .t-xxl-58px {
    top: 58px !important;
  }
  .t-xxl-58rem {
    top: 58rem !important;
  }
  .t-xxl-59px {
    top: 59px !important;
  }
  .t-xxl-59rem {
    top: 59rem !important;
  }
  .t-xxl-60px {
    top: 60px !important;
  }
  .t-xxl-60rem {
    top: 60rem !important;
  }
  .t-xxl-61px {
    top: 61px !important;
  }
  .t-xxl-61rem {
    top: 61rem !important;
  }
  .t-xxl-62px {
    top: 62px !important;
  }
  .t-xxl-62rem {
    top: 62rem !important;
  }
  .t-xxl-63px {
    top: 63px !important;
  }
  .t-xxl-63rem {
    top: 63rem !important;
  }
  .t-xxl-64px {
    top: 64px !important;
  }
  .t-xxl-64rem {
    top: 64rem !important;
  }
  .t-xxl-65px {
    top: 65px !important;
  }
  .t-xxl-65rem {
    top: 65rem !important;
  }
  .t-xxl-66px {
    top: 66px !important;
  }
  .t-xxl-66rem {
    top: 66rem !important;
  }
  .t-xxl-67px {
    top: 67px !important;
  }
  .t-xxl-67rem {
    top: 67rem !important;
  }
  .t-xxl-68px {
    top: 68px !important;
  }
  .t-xxl-68rem {
    top: 68rem !important;
  }
  .t-xxl-69px {
    top: 69px !important;
  }
  .t-xxl-69rem {
    top: 69rem !important;
  }
  .t-xxl-70px {
    top: 70px !important;
  }
  .t-xxl-70rem {
    top: 70rem !important;
  }
  .t-xxl-71px {
    top: 71px !important;
  }
  .t-xxl-71rem {
    top: 71rem !important;
  }
  .t-xxl-72px {
    top: 72px !important;
  }
  .t-xxl-72rem {
    top: 72rem !important;
  }
  .t-xxl-73px {
    top: 73px !important;
  }
  .t-xxl-73rem {
    top: 73rem !important;
  }
  .t-xxl-74px {
    top: 74px !important;
  }
  .t-xxl-74rem {
    top: 74rem !important;
  }
  .t-xxl-75px {
    top: 75px !important;
  }
  .t-xxl-75rem {
    top: 75rem !important;
  }
  .t-xxl-76px {
    top: 76px !important;
  }
  .t-xxl-76rem {
    top: 76rem !important;
  }
  .t-xxl-77px {
    top: 77px !important;
  }
  .t-xxl-77rem {
    top: 77rem !important;
  }
  .t-xxl-78px {
    top: 78px !important;
  }
  .t-xxl-78rem {
    top: 78rem !important;
  }
  .t-xxl-79px {
    top: 79px !important;
  }
  .t-xxl-79rem {
    top: 79rem !important;
  }
  .t-xxl-80px {
    top: 80px !important;
  }
  .t-xxl-80rem {
    top: 80rem !important;
  }
  .t-xxl-81px {
    top: 81px !important;
  }
  .t-xxl-81rem {
    top: 81rem !important;
  }
  .t-xxl-82px {
    top: 82px !important;
  }
  .t-xxl-82rem {
    top: 82rem !important;
  }
  .t-xxl-83px {
    top: 83px !important;
  }
  .t-xxl-83rem {
    top: 83rem !important;
  }
  .t-xxl-84px {
    top: 84px !important;
  }
  .t-xxl-84rem {
    top: 84rem !important;
  }
  .t-xxl-85px {
    top: 85px !important;
  }
  .t-xxl-85rem {
    top: 85rem !important;
  }
  .t-xxl-86px {
    top: 86px !important;
  }
  .t-xxl-86rem {
    top: 86rem !important;
  }
  .t-xxl-87px {
    top: 87px !important;
  }
  .t-xxl-87rem {
    top: 87rem !important;
  }
  .t-xxl-88px {
    top: 88px !important;
  }
  .t-xxl-88rem {
    top: 88rem !important;
  }
  .t-xxl-89px {
    top: 89px !important;
  }
  .t-xxl-89rem {
    top: 89rem !important;
  }
  .t-xxl-90px {
    top: 90px !important;
  }
  .t-xxl-90rem {
    top: 90rem !important;
  }
  .t-xxl-91px {
    top: 91px !important;
  }
  .t-xxl-91rem {
    top: 91rem !important;
  }
  .t-xxl-92px {
    top: 92px !important;
  }
  .t-xxl-92rem {
    top: 92rem !important;
  }
  .t-xxl-93px {
    top: 93px !important;
  }
  .t-xxl-93rem {
    top: 93rem !important;
  }
  .t-xxl-94px {
    top: 94px !important;
  }
  .t-xxl-94rem {
    top: 94rem !important;
  }
  .t-xxl-95px {
    top: 95px !important;
  }
  .t-xxl-95rem {
    top: 95rem !important;
  }
  .t-xxl-96px {
    top: 96px !important;
  }
  .t-xxl-96rem {
    top: 96rem !important;
  }
  .t-xxl-97px {
    top: 97px !important;
  }
  .t-xxl-97rem {
    top: 97rem !important;
  }
  .t-xxl-98px {
    top: 98px !important;
  }
  .t-xxl-98rem {
    top: 98rem !important;
  }
  .t-xxl-99px {
    top: 99px !important;
  }
  .t-xxl-99rem {
    top: 99rem !important;
  }
  .t-xxl-100px {
    top: 100px !important;
  }
  .t-xxl-100rem {
    top: 100rem !important;
  }
  .l-xxl-0px {
    left: 0px !important;
  }
  .l-xxl-0rem {
    left: 0rem !important;
  }
  .l-xxl-1px {
    left: 1px !important;
  }
  .l-xxl-1rem {
    left: 1rem !important;
  }
  .l-xxl-2px {
    left: 2px !important;
  }
  .l-xxl-2rem {
    left: 2rem !important;
  }
  .l-xxl-3px {
    left: 3px !important;
  }
  .l-xxl-3rem {
    left: 3rem !important;
  }
  .l-xxl-4px {
    left: 4px !important;
  }
  .l-xxl-4rem {
    left: 4rem !important;
  }
  .l-xxl-5px {
    left: 5px !important;
  }
  .l-xxl-5rem {
    left: 5rem !important;
  }
  .l-xxl-6px {
    left: 6px !important;
  }
  .l-xxl-6rem {
    left: 6rem !important;
  }
  .l-xxl-7px {
    left: 7px !important;
  }
  .l-xxl-7rem {
    left: 7rem !important;
  }
  .l-xxl-8px {
    left: 8px !important;
  }
  .l-xxl-8rem {
    left: 8rem !important;
  }
  .l-xxl-9px {
    left: 9px !important;
  }
  .l-xxl-9rem {
    left: 9rem !important;
  }
  .l-xxl-10px {
    left: 10px !important;
  }
  .l-xxl-10rem {
    left: 10rem !important;
  }
  .l-xxl-11px {
    left: 11px !important;
  }
  .l-xxl-11rem {
    left: 11rem !important;
  }
  .l-xxl-12px {
    left: 12px !important;
  }
  .l-xxl-12rem {
    left: 12rem !important;
  }
  .l-xxl-13px {
    left: 13px !important;
  }
  .l-xxl-13rem {
    left: 13rem !important;
  }
  .l-xxl-14px {
    left: 14px !important;
  }
  .l-xxl-14rem {
    left: 14rem !important;
  }
  .l-xxl-15px {
    left: 15px !important;
  }
  .l-xxl-15rem {
    left: 15rem !important;
  }
  .l-xxl-16px {
    left: 16px !important;
  }
  .l-xxl-16rem {
    left: 16rem !important;
  }
  .l-xxl-17px {
    left: 17px !important;
  }
  .l-xxl-17rem {
    left: 17rem !important;
  }
  .l-xxl-18px {
    left: 18px !important;
  }
  .l-xxl-18rem {
    left: 18rem !important;
  }
  .l-xxl-19px {
    left: 19px !important;
  }
  .l-xxl-19rem {
    left: 19rem !important;
  }
  .l-xxl-20px {
    left: 20px !important;
  }
  .l-xxl-20rem {
    left: 20rem !important;
  }
  .l-xxl-21px {
    left: 21px !important;
  }
  .l-xxl-21rem {
    left: 21rem !important;
  }
  .l-xxl-22px {
    left: 22px !important;
  }
  .l-xxl-22rem {
    left: 22rem !important;
  }
  .l-xxl-23px {
    left: 23px !important;
  }
  .l-xxl-23rem {
    left: 23rem !important;
  }
  .l-xxl-24px {
    left: 24px !important;
  }
  .l-xxl-24rem {
    left: 24rem !important;
  }
  .l-xxl-25px {
    left: 25px !important;
  }
  .l-xxl-25rem {
    left: 25rem !important;
  }
  .l-xxl-26px {
    left: 26px !important;
  }
  .l-xxl-26rem {
    left: 26rem !important;
  }
  .l-xxl-27px {
    left: 27px !important;
  }
  .l-xxl-27rem {
    left: 27rem !important;
  }
  .l-xxl-28px {
    left: 28px !important;
  }
  .l-xxl-28rem {
    left: 28rem !important;
  }
  .l-xxl-29px {
    left: 29px !important;
  }
  .l-xxl-29rem {
    left: 29rem !important;
  }
  .l-xxl-30px {
    left: 30px !important;
  }
  .l-xxl-30rem {
    left: 30rem !important;
  }
  .l-xxl-31px {
    left: 31px !important;
  }
  .l-xxl-31rem {
    left: 31rem !important;
  }
  .l-xxl-32px {
    left: 32px !important;
  }
  .l-xxl-32rem {
    left: 32rem !important;
  }
  .l-xxl-33px {
    left: 33px !important;
  }
  .l-xxl-33rem {
    left: 33rem !important;
  }
  .l-xxl-34px {
    left: 34px !important;
  }
  .l-xxl-34rem {
    left: 34rem !important;
  }
  .l-xxl-35px {
    left: 35px !important;
  }
  .l-xxl-35rem {
    left: 35rem !important;
  }
  .l-xxl-36px {
    left: 36px !important;
  }
  .l-xxl-36rem {
    left: 36rem !important;
  }
  .l-xxl-37px {
    left: 37px !important;
  }
  .l-xxl-37rem {
    left: 37rem !important;
  }
  .l-xxl-38px {
    left: 38px !important;
  }
  .l-xxl-38rem {
    left: 38rem !important;
  }
  .l-xxl-39px {
    left: 39px !important;
  }
  .l-xxl-39rem {
    left: 39rem !important;
  }
  .l-xxl-40px {
    left: 40px !important;
  }
  .l-xxl-40rem {
    left: 40rem !important;
  }
  .l-xxl-41px {
    left: 41px !important;
  }
  .l-xxl-41rem {
    left: 41rem !important;
  }
  .l-xxl-42px {
    left: 42px !important;
  }
  .l-xxl-42rem {
    left: 42rem !important;
  }
  .l-xxl-43px {
    left: 43px !important;
  }
  .l-xxl-43rem {
    left: 43rem !important;
  }
  .l-xxl-44px {
    left: 44px !important;
  }
  .l-xxl-44rem {
    left: 44rem !important;
  }
  .l-xxl-45px {
    left: 45px !important;
  }
  .l-xxl-45rem {
    left: 45rem !important;
  }
  .l-xxl-46px {
    left: 46px !important;
  }
  .l-xxl-46rem {
    left: 46rem !important;
  }
  .l-xxl-47px {
    left: 47px !important;
  }
  .l-xxl-47rem {
    left: 47rem !important;
  }
  .l-xxl-48px {
    left: 48px !important;
  }
  .l-xxl-48rem {
    left: 48rem !important;
  }
  .l-xxl-49px {
    left: 49px !important;
  }
  .l-xxl-49rem {
    left: 49rem !important;
  }
  .l-xxl-50px {
    left: 50px !important;
  }
  .l-xxl-50rem {
    left: 50rem !important;
  }
  .l-xxl-51px {
    left: 51px !important;
  }
  .l-xxl-51rem {
    left: 51rem !important;
  }
  .l-xxl-52px {
    left: 52px !important;
  }
  .l-xxl-52rem {
    left: 52rem !important;
  }
  .l-xxl-53px {
    left: 53px !important;
  }
  .l-xxl-53rem {
    left: 53rem !important;
  }
  .l-xxl-54px {
    left: 54px !important;
  }
  .l-xxl-54rem {
    left: 54rem !important;
  }
  .l-xxl-55px {
    left: 55px !important;
  }
  .l-xxl-55rem {
    left: 55rem !important;
  }
  .l-xxl-56px {
    left: 56px !important;
  }
  .l-xxl-56rem {
    left: 56rem !important;
  }
  .l-xxl-57px {
    left: 57px !important;
  }
  .l-xxl-57rem {
    left: 57rem !important;
  }
  .l-xxl-58px {
    left: 58px !important;
  }
  .l-xxl-58rem {
    left: 58rem !important;
  }
  .l-xxl-59px {
    left: 59px !important;
  }
  .l-xxl-59rem {
    left: 59rem !important;
  }
  .l-xxl-60px {
    left: 60px !important;
  }
  .l-xxl-60rem {
    left: 60rem !important;
  }
  .l-xxl-61px {
    left: 61px !important;
  }
  .l-xxl-61rem {
    left: 61rem !important;
  }
  .l-xxl-62px {
    left: 62px !important;
  }
  .l-xxl-62rem {
    left: 62rem !important;
  }
  .l-xxl-63px {
    left: 63px !important;
  }
  .l-xxl-63rem {
    left: 63rem !important;
  }
  .l-xxl-64px {
    left: 64px !important;
  }
  .l-xxl-64rem {
    left: 64rem !important;
  }
  .l-xxl-65px {
    left: 65px !important;
  }
  .l-xxl-65rem {
    left: 65rem !important;
  }
  .l-xxl-66px {
    left: 66px !important;
  }
  .l-xxl-66rem {
    left: 66rem !important;
  }
  .l-xxl-67px {
    left: 67px !important;
  }
  .l-xxl-67rem {
    left: 67rem !important;
  }
  .l-xxl-68px {
    left: 68px !important;
  }
  .l-xxl-68rem {
    left: 68rem !important;
  }
  .l-xxl-69px {
    left: 69px !important;
  }
  .l-xxl-69rem {
    left: 69rem !important;
  }
  .l-xxl-70px {
    left: 70px !important;
  }
  .l-xxl-70rem {
    left: 70rem !important;
  }
  .l-xxl-71px {
    left: 71px !important;
  }
  .l-xxl-71rem {
    left: 71rem !important;
  }
  .l-xxl-72px {
    left: 72px !important;
  }
  .l-xxl-72rem {
    left: 72rem !important;
  }
  .l-xxl-73px {
    left: 73px !important;
  }
  .l-xxl-73rem {
    left: 73rem !important;
  }
  .l-xxl-74px {
    left: 74px !important;
  }
  .l-xxl-74rem {
    left: 74rem !important;
  }
  .l-xxl-75px {
    left: 75px !important;
  }
  .l-xxl-75rem {
    left: 75rem !important;
  }
  .l-xxl-76px {
    left: 76px !important;
  }
  .l-xxl-76rem {
    left: 76rem !important;
  }
  .l-xxl-77px {
    left: 77px !important;
  }
  .l-xxl-77rem {
    left: 77rem !important;
  }
  .l-xxl-78px {
    left: 78px !important;
  }
  .l-xxl-78rem {
    left: 78rem !important;
  }
  .l-xxl-79px {
    left: 79px !important;
  }
  .l-xxl-79rem {
    left: 79rem !important;
  }
  .l-xxl-80px {
    left: 80px !important;
  }
  .l-xxl-80rem {
    left: 80rem !important;
  }
  .l-xxl-81px {
    left: 81px !important;
  }
  .l-xxl-81rem {
    left: 81rem !important;
  }
  .l-xxl-82px {
    left: 82px !important;
  }
  .l-xxl-82rem {
    left: 82rem !important;
  }
  .l-xxl-83px {
    left: 83px !important;
  }
  .l-xxl-83rem {
    left: 83rem !important;
  }
  .l-xxl-84px {
    left: 84px !important;
  }
  .l-xxl-84rem {
    left: 84rem !important;
  }
  .l-xxl-85px {
    left: 85px !important;
  }
  .l-xxl-85rem {
    left: 85rem !important;
  }
  .l-xxl-86px {
    left: 86px !important;
  }
  .l-xxl-86rem {
    left: 86rem !important;
  }
  .l-xxl-87px {
    left: 87px !important;
  }
  .l-xxl-87rem {
    left: 87rem !important;
  }
  .l-xxl-88px {
    left: 88px !important;
  }
  .l-xxl-88rem {
    left: 88rem !important;
  }
  .l-xxl-89px {
    left: 89px !important;
  }
  .l-xxl-89rem {
    left: 89rem !important;
  }
  .l-xxl-90px {
    left: 90px !important;
  }
  .l-xxl-90rem {
    left: 90rem !important;
  }
  .l-xxl-91px {
    left: 91px !important;
  }
  .l-xxl-91rem {
    left: 91rem !important;
  }
  .l-xxl-92px {
    left: 92px !important;
  }
  .l-xxl-92rem {
    left: 92rem !important;
  }
  .l-xxl-93px {
    left: 93px !important;
  }
  .l-xxl-93rem {
    left: 93rem !important;
  }
  .l-xxl-94px {
    left: 94px !important;
  }
  .l-xxl-94rem {
    left: 94rem !important;
  }
  .l-xxl-95px {
    left: 95px !important;
  }
  .l-xxl-95rem {
    left: 95rem !important;
  }
  .l-xxl-96px {
    left: 96px !important;
  }
  .l-xxl-96rem {
    left: 96rem !important;
  }
  .l-xxl-97px {
    left: 97px !important;
  }
  .l-xxl-97rem {
    left: 97rem !important;
  }
  .l-xxl-98px {
    left: 98px !important;
  }
  .l-xxl-98rem {
    left: 98rem !important;
  }
  .l-xxl-99px {
    left: 99px !important;
  }
  .l-xxl-99rem {
    left: 99rem !important;
  }
  .l-xxl-100px {
    left: 100px !important;
  }
  .l-xxl-100rem {
    left: 100rem !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .pt-xxl-0px {
    padding-top: 0px !important;
  }
  .pb-xxl-0px {
    padding-bottom: 0px !important;
  }
  .pt-xxl-5px {
    padding-top: 5px !important;
  }
  .pb-xxl-5px {
    padding-bottom: 5px !important;
  }
  .pt-xxl-10px {
    padding-top: 10px !important;
  }
  .pb-xxl-10px {
    padding-bottom: 10px !important;
  }
  .pt-xxl-15px {
    padding-top: 15px !important;
  }
  .pb-xxl-15px {
    padding-bottom: 15px !important;
  }
  .pt-xxl-20px {
    padding-top: 20px !important;
  }
  .pb-xxl-20px {
    padding-bottom: 20px !important;
  }
  .pt-xxl-25px {
    padding-top: 25px !important;
  }
  .pb-xxl-25px {
    padding-bottom: 25px !important;
  }
  .pt-xxl-30px {
    padding-top: 30px !important;
  }
  .pb-xxl-30px {
    padding-bottom: 30px !important;
  }
  .pt-xxl-35px {
    padding-top: 35px !important;
  }
  .pb-xxl-35px {
    padding-bottom: 35px !important;
  }
  .pt-xxl-40px {
    padding-top: 40px !important;
  }
  .pb-xxl-40px {
    padding-bottom: 40px !important;
  }
  .pt-xxl-45px {
    padding-top: 45px !important;
  }
  .pb-xxl-45px {
    padding-bottom: 45px !important;
  }
  .pt-xxl-50px {
    padding-top: 50px !important;
  }
  .pb-xxl-50px {
    padding-bottom: 50px !important;
  }
  .pt-xxl-55px {
    padding-top: 55px !important;
  }
  .pb-xxl-55px {
    padding-bottom: 55px !important;
  }
  .pt-xxl-60px {
    padding-top: 60px !important;
  }
  .pb-xxl-60px {
    padding-bottom: 60px !important;
  }
  .pt-xxl-65px {
    padding-top: 65px !important;
  }
  .pb-xxl-65px {
    padding-bottom: 65px !important;
  }
  .pt-xxl-70px {
    padding-top: 70px !important;
  }
  .pb-xxl-70px {
    padding-bottom: 70px !important;
  }
  .pt-xxl-75px {
    padding-top: 75px !important;
  }
  .pb-xxl-75px {
    padding-bottom: 75px !important;
  }
  .pt-xxl-80px {
    padding-top: 80px !important;
  }
  .pb-xxl-80px {
    padding-bottom: 80px !important;
  }
  .pt-xxl-85px {
    padding-top: 85px !important;
  }
  .pb-xxl-85px {
    padding-bottom: 85px !important;
  }
  .pt-xxl-90px {
    padding-top: 90px !important;
  }
  .pb-xxl-90px {
    padding-bottom: 90px !important;
  }
  .pt-xxl-95px {
    padding-top: 95px !important;
  }
  .pb-xxl-95px {
    padding-bottom: 95px !important;
  }
  .pt-xxl-100px {
    padding-top: 100px !important;
  }
  .pb-xxl-100px {
    padding-bottom: 100px !important;
  }
  .mt-xxl-0px {
    margin-top: 0px !important;
  }
  .mb-xxl-0px {
    margin-bottom: 0px !important;
  }
  .mt-xxl-5px {
    margin-top: 5px !important;
  }
  .mb-xxl-5px {
    margin-bottom: 5px !important;
  }
  .mt-xxl-10px {
    margin-top: 10px !important;
  }
  .mb-xxl-10px {
    margin-bottom: 10px !important;
  }
  .mt-xxl-15px {
    margin-top: 15px !important;
  }
  .mb-xxl-15px {
    margin-bottom: 15px !important;
  }
  .mt-xxl-20px {
    margin-top: 20px !important;
  }
  .mb-xxl-20px {
    margin-bottom: 20px !important;
  }
  .mt-xxl-25px {
    margin-top: 25px !important;
  }
  .mb-xxl-25px {
    margin-bottom: 25px !important;
  }
  .mt-xxl-30px {
    margin-top: 30px !important;
  }
  .mb-xxl-30px {
    margin-bottom: 30px !important;
  }
  .mt-xxl-35px {
    margin-top: 35px !important;
  }
  .mb-xxl-35px {
    margin-bottom: 35px !important;
  }
  .mt-xxl-40px {
    margin-top: 40px !important;
  }
  .mb-xxl-40px {
    margin-bottom: 40px !important;
  }
  .mt-xxl-45px {
    margin-top: 45px !important;
  }
  .mb-xxl-45px {
    margin-bottom: 45px !important;
  }
  .mt-xxl-50px {
    margin-top: 50px !important;
  }
  .mb-xxl-50px {
    margin-bottom: 50px !important;
  }
  .mt-xxl-55px {
    margin-top: 55px !important;
  }
  .mb-xxl-55px {
    margin-bottom: 55px !important;
  }
  .mt-xxl-60px {
    margin-top: 60px !important;
  }
  .mb-xxl-60px {
    margin-bottom: 60px !important;
  }
  .mt-xxl-65px {
    margin-top: 65px !important;
  }
  .mb-xxl-65px {
    margin-bottom: 65px !important;
  }
  .mt-xxl-70px {
    margin-top: 70px !important;
  }
  .mb-xxl-70px {
    margin-bottom: 70px !important;
  }
  .mt-xxl-75px {
    margin-top: 75px !important;
  }
  .mb-xxl-75px {
    margin-bottom: 75px !important;
  }
  .mt-xxl-80px {
    margin-top: 80px !important;
  }
  .mb-xxl-80px {
    margin-bottom: 80px !important;
  }
  .mt-xxl-85px {
    margin-top: 85px !important;
  }
  .mb-xxl-85px {
    margin-bottom: 85px !important;
  }
  .mt-xxl-90px {
    margin-top: 90px !important;
  }
  .mb-xxl-90px {
    margin-bottom: 90px !important;
  }
  .mt-xxl-95px {
    margin-top: 95px !important;
  }
  .mb-xxl-95px {
    margin-bottom: 95px !important;
  }
  .mt-xxl-100px {
    margin-top: 100px !important;
  }
  .mb-xxl-100px {
    margin-bottom: 100px !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-top-xxl-1px-s-2f2f2f {
    border-top: 1px solid #2f2f2f !important;
  }
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

@media (max-width: 1599px) {
  .hidden-xl-down {
    display: none !important;
  }
}

@media (min-width: 1600px) {
  .hidden-xxl-up {
    display: none !important;
  }
}

.hidden-xxl-down {
  display: none !important;
}

.small-xs-up {
  font-size: 80%;
  font-weight: normal;
}

@media (max-width: 575px) {
  .small-xs-down {
    font-size: 80%;
    font-weight: normal;
  }
}

.px-0-xs-up {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media (max-width: 575px) {
  .px-0-xs-down {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 576px) {
  .small-sm-up {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (max-width: 767px) {
  .small-sm-down {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (min-width: 576px) {
  .px-0-sm-up {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 767px) {
  .px-0-sm-down {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .small-md-up {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (max-width: 991px) {
  .small-md-down {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (min-width: 768px) {
  .px-0-md-up {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .px-0-md-down {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .small-lg-up {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (max-width: 1199px) {
  .small-lg-down {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (min-width: 992px) {
  .px-0-lg-up {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .px-0-lg-down {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .small-xl-up {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (max-width: 1599px) {
  .small-xl-down {
    font-size: 80%;
    font-weight: normal;
  }
}

@media (min-width: 1200px) {
  .px-0-xl-up {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 1599px) {
  .px-0-xl-down {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 1600px) {
  .small-xxl-up {
    font-size: 80%;
    font-weight: normal;
  }
}

.small-xxl-down {
  font-size: 80%;
  font-weight: normal;
}

@media (min-width: 1600px) {
  .px-0-xxl-up {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.px-0-xxl-down {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.container-xs-up {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container-xs-up {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-xs-up {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-xs-up {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xs-up {
    max-width: 1140px;
  }
}

@media (min-width: 1600px) {
  .container-xs-up {
    max-width: 1528px;
  }
}

@media (max-width: 575px) {
  .container-xs-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 575px) and (min-width: 576px) {
  .container-xs-down {
    max-width: 540px;
  }
}

@media (max-width: 575px) and (min-width: 768px) {
  .container-xs-down {
    max-width: 720px;
  }
}

@media (max-width: 575px) and (min-width: 992px) {
  .container-xs-down {
    max-width: 960px;
  }
}

@media (max-width: 575px) and (min-width: 1200px) {
  .container-xs-down {
    max-width: 1140px;
  }
}

@media (max-width: 575px) and (min-width: 1600px) {
  .container-xs-down {
    max-width: 1528px;
  }
}

.container-fluid-xs-up {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (max-width: 575px) {
  .container-fluid-xs-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    width: auto;
  }
}

@media (min-width: 576px) {
  .container-sm-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (min-width: 576px) and (min-width: 576px) {
  .container-sm-up {
    max-width: 540px;
  }
}

@media (min-width: 576px) and (min-width: 768px) {
  .container-sm-up {
    max-width: 720px;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  .container-sm-up {
    max-width: 960px;
  }
}

@media (min-width: 576px) and (min-width: 1200px) {
  .container-sm-up {
    max-width: 1140px;
  }
}

@media (min-width: 576px) and (min-width: 1600px) {
  .container-sm-up {
    max-width: 1528px;
  }
}

@media (max-width: 767px) {
  .container-sm-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .container-sm-down {
    max-width: 540px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .container-sm-down {
    max-width: 720px;
  }
}

@media (max-width: 767px) and (min-width: 992px) {
  .container-sm-down {
    max-width: 960px;
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .container-sm-down {
    max-width: 1140px;
  }
}

@media (max-width: 767px) and (min-width: 1600px) {
  .container-sm-down {
    max-width: 1528px;
  }
}

@media (min-width: 576px) {
  .container-fluid-sm-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .container-fluid-sm-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    width: auto;
  }
}

@media (min-width: 768px) {
  .container-md-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (min-width: 768px) and (min-width: 576px) {
  .container-md-up {
    max-width: 540px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .container-md-up {
    max-width: 720px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .container-md-up {
    max-width: 960px;
  }
}

@media (min-width: 768px) and (min-width: 1200px) {
  .container-md-up {
    max-width: 1140px;
  }
}

@media (min-width: 768px) and (min-width: 1600px) {
  .container-md-up {
    max-width: 1528px;
  }
}

@media (max-width: 991px) {
  .container-md-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 991px) and (min-width: 576px) {
  .container-md-down {
    max-width: 540px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .container-md-down {
    max-width: 720px;
  }
}

@media (max-width: 991px) and (min-width: 992px) {
  .container-md-down {
    max-width: 960px;
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .container-md-down {
    max-width: 1140px;
  }
}

@media (max-width: 991px) and (min-width: 1600px) {
  .container-md-down {
    max-width: 1528px;
  }
}

@media (min-width: 768px) {
  .container-fluid-md-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .container-fluid-md-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    width: auto;
  }
}

@media (min-width: 992px) {
  .container-lg-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (min-width: 992px) and (min-width: 576px) {
  .container-lg-up {
    max-width: 540px;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .container-lg-up {
    max-width: 720px;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .container-lg-up {
    max-width: 960px;
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .container-lg-up {
    max-width: 1140px;
  }
}

@media (min-width: 992px) and (min-width: 1600px) {
  .container-lg-up {
    max-width: 1528px;
  }
}

@media (max-width: 1199px) {
  .container-lg-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 1199px) and (min-width: 576px) {
  .container-lg-down {
    max-width: 540px;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .container-lg-down {
    max-width: 720px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .container-lg-down {
    max-width: 960px;
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .container-lg-down {
    max-width: 1140px;
  }
}

@media (max-width: 1199px) and (min-width: 1600px) {
  .container-lg-down {
    max-width: 1528px;
  }
}

@media (min-width: 992px) {
  .container-fluid-lg-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .container-fluid-lg-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .container-xl-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (min-width: 1200px) and (min-width: 576px) {
  .container-xl-up {
    max-width: 540px;
  }
}

@media (min-width: 1200px) and (min-width: 768px) {
  .container-xl-up {
    max-width: 720px;
  }
}

@media (min-width: 1200px) and (min-width: 992px) {
  .container-xl-up {
    max-width: 960px;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .container-xl-up {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) and (min-width: 1600px) {
  .container-xl-up {
    max-width: 1528px;
  }
}

@media (max-width: 1599px) {
  .container-xl-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 1599px) and (min-width: 576px) {
  .container-xl-down {
    max-width: 540px;
  }
}

@media (max-width: 1599px) and (min-width: 768px) {
  .container-xl-down {
    max-width: 720px;
  }
}

@media (max-width: 1599px) and (min-width: 992px) {
  .container-xl-down {
    max-width: 960px;
  }
}

@media (max-width: 1599px) and (min-width: 1200px) {
  .container-xl-down {
    max-width: 1140px;
  }
}

@media (max-width: 1599px) and (min-width: 1600px) {
  .container-xl-down {
    max-width: 1528px;
  }
}

@media (min-width: 1200px) {
  .container-fluid-xl-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (max-width: 1599px) {
  .container-fluid-xl-down {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    width: auto;
  }
}

@media (min-width: 1600px) {
  .container-xxl-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

@media (min-width: 1600px) and (min-width: 576px) {
  .container-xxl-up {
    max-width: 540px;
  }
}

@media (min-width: 1600px) and (min-width: 768px) {
  .container-xxl-up {
    max-width: 720px;
  }
}

@media (min-width: 1600px) and (min-width: 992px) {
  .container-xxl-up {
    max-width: 960px;
  }
}

@media (min-width: 1600px) and (min-width: 1200px) {
  .container-xxl-up {
    max-width: 1140px;
  }
}

@media (min-width: 1600px) and (min-width: 1600px) {
  .container-xxl-up {
    max-width: 1528px;
  }
}

.container-xxl-down {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container-xxl-down {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-xxl-down {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-xxl-down {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xxl-down {
    max-width: 1140px;
  }
}

@media (min-width: 1600px) {
  .container-xxl-down {
    max-width: 1528px;
  }
}

@media (min-width: 1600px) {
  .container-fluid-xxl-up {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
}

.container-fluid-xxl-down {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  width: auto;
}

.fs-0 {
  font-size: 0rem !important;
}

.fs-1 {
  font-size: 0.25rem !important;
}

.fs-2 {
  font-size: 0.5rem !important;
}

.fs-3 {
  font-size: 0.75rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.5rem !important;
}

.fs-7 {
  font-size: 1.75rem !important;
}

.fs-8 {
  font-size: 2rem !important;
}

.fs-9 {
  font-size: 2.25rem !important;
}

.fs-10 {
  font-size: 2.5rem !important;
}

.fs-11 {
  font-size: 2.75rem !important;
}

.fs-12 {
  font-size: 3rem !important;
}

.fs-13 {
  font-size: 3.25rem !important;
}

.fs-14 {
  font-size: 3.5rem !important;
}

.fs-15 {
  font-size: 3.75rem !important;
}

.fs-16 {
  font-size: 4rem !important;
}

@media (min-width: 576px) {
  .fs-sm-0 {
    font-size: 0rem !important;
  }
  .fs-sm-1 {
    font-size: 0.25rem !important;
  }
  .fs-sm-2 {
    font-size: 0.5rem !important;
  }
  .fs-sm-3 {
    font-size: 0.75rem !important;
  }
  .fs-sm-4 {
    font-size: 1rem !important;
  }
  .fs-sm-5 {
    font-size: 1.25rem !important;
  }
  .fs-sm-6 {
    font-size: 1.5rem !important;
  }
  .fs-sm-7 {
    font-size: 1.75rem !important;
  }
  .fs-sm-8 {
    font-size: 2rem !important;
  }
  .fs-sm-9 {
    font-size: 2.25rem !important;
  }
  .fs-sm-10 {
    font-size: 2.5rem !important;
  }
  .fs-sm-11 {
    font-size: 2.75rem !important;
  }
  .fs-sm-12 {
    font-size: 3rem !important;
  }
  .fs-sm-13 {
    font-size: 3.25rem !important;
  }
  .fs-sm-14 {
    font-size: 3.5rem !important;
  }
  .fs-sm-15 {
    font-size: 3.75rem !important;
  }
  .fs-sm-16 {
    font-size: 4rem !important;
  }
}

@media (min-width: 768px) {
  .fs-md-0 {
    font-size: 0rem !important;
  }
  .fs-md-1 {
    font-size: 0.25rem !important;
  }
  .fs-md-2 {
    font-size: 0.5rem !important;
  }
  .fs-md-3 {
    font-size: 0.75rem !important;
  }
  .fs-md-4 {
    font-size: 1rem !important;
  }
  .fs-md-5 {
    font-size: 1.25rem !important;
  }
  .fs-md-6 {
    font-size: 1.5rem !important;
  }
  .fs-md-7 {
    font-size: 1.75rem !important;
  }
  .fs-md-8 {
    font-size: 2rem !important;
  }
  .fs-md-9 {
    font-size: 2.25rem !important;
  }
  .fs-md-10 {
    font-size: 2.5rem !important;
  }
  .fs-md-11 {
    font-size: 2.75rem !important;
  }
  .fs-md-12 {
    font-size: 3rem !important;
  }
  .fs-md-13 {
    font-size: 3.25rem !important;
  }
  .fs-md-14 {
    font-size: 3.5rem !important;
  }
  .fs-md-15 {
    font-size: 3.75rem !important;
  }
  .fs-md-16 {
    font-size: 4rem !important;
  }
}

@media (min-width: 992px) {
  .fs-lg-0 {
    font-size: 0rem !important;
  }
  .fs-lg-1 {
    font-size: 0.25rem !important;
  }
  .fs-lg-2 {
    font-size: 0.5rem !important;
  }
  .fs-lg-3 {
    font-size: 0.75rem !important;
  }
  .fs-lg-4 {
    font-size: 1rem !important;
  }
  .fs-lg-5 {
    font-size: 1.25rem !important;
  }
  .fs-lg-6 {
    font-size: 1.5rem !important;
  }
  .fs-lg-7 {
    font-size: 1.75rem !important;
  }
  .fs-lg-8 {
    font-size: 2rem !important;
  }
  .fs-lg-9 {
    font-size: 2.25rem !important;
  }
  .fs-lg-10 {
    font-size: 2.5rem !important;
  }
  .fs-lg-11 {
    font-size: 2.75rem !important;
  }
  .fs-lg-12 {
    font-size: 3rem !important;
  }
  .fs-lg-13 {
    font-size: 3.25rem !important;
  }
  .fs-lg-14 {
    font-size: 3.5rem !important;
  }
  .fs-lg-15 {
    font-size: 3.75rem !important;
  }
  .fs-lg-16 {
    font-size: 4rem !important;
  }
}

@media (min-width: 1200px) {
  .fs-xl-0 {
    font-size: 0rem !important;
  }
  .fs-xl-1 {
    font-size: 0.25rem !important;
  }
  .fs-xl-2 {
    font-size: 0.5rem !important;
  }
  .fs-xl-3 {
    font-size: 0.75rem !important;
  }
  .fs-xl-4 {
    font-size: 1rem !important;
  }
  .fs-xl-5 {
    font-size: 1.25rem !important;
  }
  .fs-xl-6 {
    font-size: 1.5rem !important;
  }
  .fs-xl-7 {
    font-size: 1.75rem !important;
  }
  .fs-xl-8 {
    font-size: 2rem !important;
  }
  .fs-xl-9 {
    font-size: 2.25rem !important;
  }
  .fs-xl-10 {
    font-size: 2.5rem !important;
  }
  .fs-xl-11 {
    font-size: 2.75rem !important;
  }
  .fs-xl-12 {
    font-size: 3rem !important;
  }
  .fs-xl-13 {
    font-size: 3.25rem !important;
  }
  .fs-xl-14 {
    font-size: 3.5rem !important;
  }
  .fs-xl-15 {
    font-size: 3.75rem !important;
  }
  .fs-xl-16 {
    font-size: 4rem !important;
  }
}

@media (min-width: 1600px) {
  .fs-xxl-0 {
    font-size: 0rem !important;
  }
  .fs-xxl-1 {
    font-size: 0.25rem !important;
  }
  .fs-xxl-2 {
    font-size: 0.5rem !important;
  }
  .fs-xxl-3 {
    font-size: 0.75rem !important;
  }
  .fs-xxl-4 {
    font-size: 1rem !important;
  }
  .fs-xxl-5 {
    font-size: 1.25rem !important;
  }
  .fs-xxl-6 {
    font-size: 1.5rem !important;
  }
  .fs-xxl-7 {
    font-size: 1.75rem !important;
  }
  .fs-xxl-8 {
    font-size: 2rem !important;
  }
  .fs-xxl-9 {
    font-size: 2.25rem !important;
  }
  .fs-xxl-10 {
    font-size: 2.5rem !important;
  }
  .fs-xxl-11 {
    font-size: 2.75rem !important;
  }
  .fs-xxl-12 {
    font-size: 3rem !important;
  }
  .fs-xxl-13 {
    font-size: 3.25rem !important;
  }
  .fs-xxl-14 {
    font-size: 3.5rem !important;
  }
  .fs-xxl-15 {
    font-size: 3.75rem !important;
  }
  .fs-xxl-16 {
    font-size: 4rem !important;
  }
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-6 {
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-7 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-8 {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-9 {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-10 {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-11 {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-1-xs-up {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-1-xs-down {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-2-xs-up {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-2-xs-down {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-3-xs-up {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-3-xs-down {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-4-xs-up {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-4-xs-down {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-5-xs-up {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-5-xs-down {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-6-xs-up {
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-6-xs-down {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-7-xs-up {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-7-xs-down {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-8-xs-up {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-8-xs-down {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-9-xs-up {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-9-xs-down {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-10-xs-up {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-10-xs-down {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-11-xs-up {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (max-width: 575px) {
  .display-11-xs-down {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-1-sm-up {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-1-sm-down {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-2-sm-up {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-2-sm-down {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-3-sm-up {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-3-sm-down {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-4-sm-up {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-4-sm-down {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-5-sm-up {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-5-sm-down {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-6-sm-up {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-6-sm-down {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-7-sm-up {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-7-sm-down {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-8-sm-up {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-8-sm-down {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-9-sm-up {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-9-sm-down {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-10-sm-up {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-10-sm-down {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 576px) {
  .display-11-sm-up {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  .display-11-sm-down {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-1-md-up {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-1-md-down {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-2-md-up {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-2-md-down {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-3-md-up {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-3-md-down {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-4-md-up {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-4-md-down {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-5-md-up {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-5-md-down {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-6-md-up {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-6-md-down {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-7-md-up {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-7-md-down {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-8-md-up {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-8-md-down {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-9-md-up {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-9-md-down {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-10-md-up {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-10-md-down {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 768px) {
  .display-11-md-up {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 991px) {
  .display-11-md-down {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-1-lg-up {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-1-lg-down {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-2-lg-up {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-2-lg-down {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-3-lg-up {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-3-lg-down {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-4-lg-up {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-4-lg-down {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-5-lg-up {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-5-lg-down {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-6-lg-up {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-6-lg-down {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-7-lg-up {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-7-lg-down {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-8-lg-up {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-8-lg-down {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-9-lg-up {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-9-lg-down {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-10-lg-up {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-10-lg-down {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 992px) {
  .display-11-lg-up {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1199px) {
  .display-11-lg-down {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-1-xl-up {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-1-xl-down {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-2-xl-up {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-2-xl-down {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-3-xl-up {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-3-xl-down {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-4-xl-up {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-4-xl-down {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-5-xl-up {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-5-xl-down {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-6-xl-up {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-6-xl-down {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-7-xl-up {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-7-xl-down {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-8-xl-up {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-8-xl-down {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-9-xl-up {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-9-xl-down {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-10-xl-up {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-10-xl-down {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .display-11-xl-up {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (max-width: 1599px) {
  .display-11-xl-down {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

@media (min-width: 1600px) {
  .display-1-xxl-up {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-1-xxl-down {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-2-xxl-up {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-2-xxl-down {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-3-xxl-up {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-3-xxl-down {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-4-xxl-up {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-4-xxl-down {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-5-xxl-up {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-5-xxl-down {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-6-xxl-up {
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-6-xxl-down {
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-7-xxl-up {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-7-xxl-down {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-8-xxl-up {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-8-xxl-down {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-9-xxl-up {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-9-xxl-down {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-10-xxl-up {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-10-xxl-down {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.1;
}

@media (min-width: 1600px) {
  .display-11-xxl-up {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.1;
  }
}

.display-11-xxl-down {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.1;
}

.btn-block-xs-up {
  display: block;
  width: 100%;
}

.btn-block-xs-up + .btn-block-xs-up {
  margin-top: 0.5rem;
}

.btn-block-xs-up + .btn-block-xs-down {
  margin-top: 0.5rem;
}

@media (max-width: 575px) {
  .btn-block-xs-down {
    display: block;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .btn-block-xs-down + .btn-block-xs-up {
    margin-top: 0.5rem;
  }
}

@media (max-width: 575px) {
  .btn-block-xs-down + .btn-block-xs-down {
    margin-top: 0.5rem;
  }
}

@media (min-width: 576px) {
  .btn-block-sm-up {
    display: block;
    width: 100%;
  }
}

@media (min-width: 576px) {
  .btn-block-sm-up + .btn-block-sm-up {
    margin-top: 0.5rem;
  }
}

@media (min-width: 576px) {
  .btn-block-sm-up + .btn-block-sm-down {
    margin-top: 0.5rem;
  }
}

@media (max-width: 767px) {
  .btn-block-sm-down {
    display: block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .btn-block-sm-down + .btn-block-sm-up {
    margin-top: 0.5rem;
  }
}

@media (max-width: 767px) {
  .btn-block-sm-down + .btn-block-sm-down {
    margin-top: 0.5rem;
  }
}

@media (min-width: 768px) {
  .btn-block-md-up {
    display: block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .btn-block-md-up + .btn-block-md-up {
    margin-top: 0.5rem;
  }
}

@media (min-width: 768px) {
  .btn-block-md-up + .btn-block-md-down {
    margin-top: 0.5rem;
  }
}

@media (max-width: 991px) {
  .btn-block-md-down {
    display: block;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .btn-block-md-down + .btn-block-md-up {
    margin-top: 0.5rem;
  }
}

@media (max-width: 991px) {
  .btn-block-md-down + .btn-block-md-down {
    margin-top: 0.5rem;
  }
}

@media (min-width: 992px) {
  .btn-block-lg-up {
    display: block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .btn-block-lg-up + .btn-block-lg-up {
    margin-top: 0.5rem;
  }
}

@media (min-width: 992px) {
  .btn-block-lg-up + .btn-block-lg-down {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1199px) {
  .btn-block-lg-down {
    display: block;
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .btn-block-lg-down + .btn-block-lg-up {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1199px) {
  .btn-block-lg-down + .btn-block-lg-down {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .btn-block-xl-up {
    display: block;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .btn-block-xl-up + .btn-block-xl-up {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .btn-block-xl-up + .btn-block-xl-down {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1599px) {
  .btn-block-xl-down {
    display: block;
    width: 100%;
  }
}

@media (max-width: 1599px) {
  .btn-block-xl-down + .btn-block-xl-up {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1599px) {
  .btn-block-xl-down + .btn-block-xl-down {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1600px) {
  .btn-block-xxl-up {
    display: block;
    width: 100%;
  }
}

@media (min-width: 1600px) {
  .btn-block-xxl-up + .btn-block-xxl-up {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1600px) {
  .btn-block-xxl-up + .btn-block-xxl-down {
    margin-top: 0.5rem;
  }
}

.btn-block-xxl-down {
  display: block;
  width: 100%;
}

.btn-block-xxl-down + .btn-block-xxl-up {
  margin-top: 0.5rem;
}

.btn-block-xxl-down + .btn-block-xxl-down {
  margin-top: 0.5rem;
}

.line-height-1 {
  line-height: 1;
}

.line-height-small {
  line-height: 1.3;
}

.line-height-small-xs-up {
  line-height: 1.3;
}

.line-height-1-xs-up {
  line-height: 1;
}

@media (max-width: 575px) {
  .line-height-small-xs-down {
    line-height: 1.3;
  }
}

@media (max-width: 575px) {
  .line-height-1-xs-down {
    line-height: 1;
  }
}

@media (min-width: 576px) {
  .line-height-small-sm-up {
    line-height: 1.3;
  }
}

@media (min-width: 576px) {
  .line-height-1-sm-up {
    line-height: 1;
  }
}

@media (max-width: 767px) {
  .line-height-small-sm-down {
    line-height: 1.3;
  }
}

@media (max-width: 767px) {
  .line-height-1-sm-down {
    line-height: 1;
  }
}

@media (min-width: 768px) {
  .line-height-small-md-up {
    line-height: 1.3;
  }
}

@media (min-width: 768px) {
  .line-height-1-md-up {
    line-height: 1;
  }
}

@media (max-width: 991px) {
  .line-height-small-md-down {
    line-height: 1.3;
  }
}

@media (max-width: 991px) {
  .line-height-1-md-down {
    line-height: 1;
  }
}

@media (min-width: 992px) {
  .line-height-small-lg-up {
    line-height: 1.3;
  }
}

@media (min-width: 992px) {
  .line-height-1-lg-up {
    line-height: 1;
  }
}

@media (max-width: 1199px) {
  .line-height-small-lg-down {
    line-height: 1.3;
  }
}

@media (max-width: 1199px) {
  .line-height-1-lg-down {
    line-height: 1;
  }
}

@media (min-width: 1200px) {
  .line-height-small-xl-up {
    line-height: 1.3;
  }
}

@media (min-width: 1200px) {
  .line-height-1-xl-up {
    line-height: 1;
  }
}

@media (max-width: 1599px) {
  .line-height-small-xl-down {
    line-height: 1.3;
  }
}

@media (max-width: 1599px) {
  .line-height-1-xl-down {
    line-height: 1;
  }
}

@media (min-width: 1600px) {
  .line-height-small-xxl-up {
    line-height: 1.3;
  }
}

@media (min-width: 1600px) {
  .line-height-1-xxl-up {
    line-height: 1;
  }
}

.line-height-small-xxl-down {
  line-height: 1.3;
}

.line-height-1-xxl-down {
  line-height: 1;
}

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-xl {
    display: none !important;
  }
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: auto;
}

@media (max-width: 768px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
}

.mw-100 {
  max-width: 100%;
}

.w-25-xs-up {
  width: 25%;
}

@media (max-width: 575px) {
  .w-25-xs-down {
    width: 25%;
  }
}

.w-50-xs-up {
  width: 50%;
}

@media (max-width: 575px) {
  .w-50-xs-down {
    width: 50%;
  }
}

.w-75-xs-up {
  width: 75%;
}

@media (max-width: 575px) {
  .w-75-xs-down {
    width: 75%;
  }
}

.w-100-xs-up {
  width: 100%;
}

@media (max-width: 575px) {
  .w-100-xs-down {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .w-25-sm-up {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .w-25-sm-down {
    width: 25%;
  }
}

@media (min-width: 576px) {
  .w-50-sm-up {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .w-50-sm-down {
    width: 50%;
  }
}

@media (min-width: 576px) {
  .w-75-sm-up {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .w-75-sm-down {
    width: 75%;
  }
}

@media (min-width: 576px) {
  .w-100-sm-up {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .w-100-sm-down {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .w-25-md-up {
    width: 25%;
  }
}

@media (max-width: 991px) {
  .w-25-md-down {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .w-50-md-up {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .w-50-md-down {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .w-75-md-up {
    width: 75%;
  }
}

@media (max-width: 991px) {
  .w-75-md-down {
    width: 75%;
  }
}

@media (min-width: 768px) {
  .w-100-md-up {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .w-100-md-down {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .w-25-lg-up {
    width: 25%;
  }
}

@media (max-width: 1199px) {
  .w-25-lg-down {
    width: 25%;
  }
}

@media (min-width: 992px) {
  .w-50-lg-up {
    width: 50%;
  }
}

@media (max-width: 1199px) {
  .w-50-lg-down {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .w-75-lg-up {
    width: 75%;
  }
}

@media (max-width: 1199px) {
  .w-75-lg-down {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .w-100-lg-up {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .w-100-lg-down {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .w-25-xl-up {
    width: 25%;
  }
}

@media (max-width: 1599px) {
  .w-25-xl-down {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .w-50-xl-up {
    width: 50%;
  }
}

@media (max-width: 1599px) {
  .w-50-xl-down {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .w-75-xl-up {
    width: 75%;
  }
}

@media (max-width: 1599px) {
  .w-75-xl-down {
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .w-100-xl-up {
    width: 100%;
  }
}

@media (max-width: 1599px) {
  .w-100-xl-down {
    width: 100%;
  }
}

@media (min-width: 1600px) {
  .w-25-xxl-up {
    width: 25%;
  }
}

.w-25-xxl-down {
  width: 25%;
}

@media (min-width: 1600px) {
  .w-50-xxl-up {
    width: 50%;
  }
}

.w-50-xxl-down {
  width: 50%;
}

@media (min-width: 1600px) {
  .w-75-xxl-up {
    width: 75%;
  }
}

.w-75-xxl-down {
  width: 75%;
}

@media (min-width: 1600px) {
  .w-100-xxl-up {
    width: 100%;
  }
}

.w-100-xxl-down {
  width: 100%;
}

.color-aliceblue {
  color: aliceblue !important;
}

.color-antiquewhite {
  color: antiquewhite !important;
}

.color-aqua {
  color: aqua !important;
}

.color-aquamarine {
  color: aquamarine !important;
}

.color-azure {
  color: azure !important;
}

.color-beige {
  color: beige !important;
}

.color-bisque {
  color: bisque !important;
}

.color-black {
  color: black !important;
}

.color-blanchedalmond {
  color: blanchedalmond !important;
}

.color-blue {
  color: blue !important;
}

.color-blueviolet {
  color: blueviolet !important;
}

.color-brown {
  color: brown !important;
}

.color-burlywood {
  color: burlywood !important;
}

.color-cadetblue {
  color: cadetblue !important;
}

.color-chartreuse {
  color: chartreuse !important;
}

.color-chocolate {
  color: chocolate !important;
}

.color-coral {
  color: coral !important;
}

.color-cornflowerblue {
  color: cornflowerblue !important;
}

.color-cornsilk {
  color: cornsilk !important;
}

.color-crimson {
  color: crimson !important;
}

.color-cyan {
  color: cyan !important;
}

.color-darkblue {
  color: darkblue !important;
}

.color-darkcyan {
  color: darkcyan !important;
}

.color-darkgoldenrod {
  color: darkgoldenrod !important;
}

.color-darkgray {
  color: darkgray !important;
}

.color-darkgrey {
  color: darkgrey !important;
}

.color-darkgreen {
  color: darkgreen !important;
}

.color-darkkhaki {
  color: darkkhaki !important;
}

.color-darkmagenta {
  color: darkmagenta !important;
}

.color-darkolivegreen {
  color: darkolivegreen !important;
}

.color-darkorange {
  color: darkorange !important;
}

.color-darkorchid {
  color: darkorchid !important;
}

.color-darkred {
  color: darkred !important;
}

.color-darksalmon {
  color: darksalmon !important;
}

.color-darkseagreen {
  color: darkseagreen !important;
}

.color-darkslateblue {
  color: darkslateblue !important;
}

.color-darkslategray {
  color: darkslategray !important;
}

.color-darkslategrey {
  color: darkslategrey !important;
}

.color-darkturquoise {
  color: darkturquoise !important;
}

.color-darkviolet {
  color: darkviolet !important;
}

.color-deeppink {
  color: deeppink !important;
}

.color-deepskyblue {
  color: deepskyblue !important;
}

.color-dimgray {
  color: dimgray !important;
}

.color-dimgrey {
  color: dimgrey !important;
}

.color-dodgerblue {
  color: dodgerblue !important;
}

.color-firebrick {
  color: firebrick !important;
}

.color-floralwhite {
  color: floralwhite !important;
}

.color-forestgreen {
  color: forestgreen !important;
}

.color-fuchsia {
  color: fuchsia !important;
}

.color-gainsboro {
  color: gainsboro !important;
}

.color-ghostwhite {
  color: ghostwhite !important;
}

.color-gold {
  color: gold !important;
}

.color-goldenrod {
  color: goldenrod !important;
}

.color-gray {
  color: gray !important;
}

.color-grey {
  color: grey !important;
}

.color-green {
  color: green !important;
}

.color-greenyellow {
  color: greenyellow !important;
}

.color-honeydew {
  color: honeydew !important;
}

.color-hotpink {
  color: hotpink !important;
}

.color-indianred {
  color: indianred  !important;
}

.color-indigo {
  color: indigo  !important;
}

.color-ivory {
  color: ivory !important;
}

.color-khaki {
  color: khaki !important;
}

.color-lavender {
  color: lavender !important;
}

.color-lavenderblush {
  color: lavenderblush !important;
}

.color-lawngreen {
  color: lawngreen !important;
}

.color-lemonchiffon {
  color: lemonchiffon !important;
}

.color-lightblue {
  color: lightblue !important;
}

.color-lightcoral {
  color: lightcoral !important;
}

.color-lightcyan {
  color: lightcyan !important;
}

.color-lightgoldenrodyellow {
  color: lightgoldenrodyellow !important;
}

.color-lightgray {
  color: lightgray !important;
}

.color-lightgrey {
  color: lightgrey !important;
}

.color-lightgreen {
  color: lightgreen !important;
}

.color-lightpink {
  color: lightpink !important;
}

.color-lightsalmon {
  color: lightsalmon !important;
}

.color-lightseagreen {
  color: lightseagreen !important;
}

.color-lightskyblue {
  color: lightskyblue !important;
}

.color-lightslategray {
  color: lightslategray !important;
}

.color-lightslategrey {
  color: lightslategrey !important;
}

.color-lightsteelblue {
  color: lightsteelblue !important;
}

.color-lightyellow {
  color: lightyellow !important;
}

.color-lime {
  color: lime !important;
}

.color-limegreen {
  color: limegreen !important;
}

.color-linen {
  color: linen !important;
}

.color-magenta {
  color: magenta !important;
}

.color-maroon {
  color: maroon !important;
}

.color-mediumaquamarine {
  color: mediumaquamarine !important;
}

.color-mediumblue {
  color: mediumblue !important;
}

.color-mediumorchid {
  color: mediumorchid !important;
}

.color-mediumpurple {
  color: mediumpurple !important;
}

.color-mediumseagreen {
  color: mediumseagreen !important;
}

.color-mediumslateblue {
  color: mediumslateblue !important;
}

.color-mediumspringgreen {
  color: mediumspringgreen !important;
}

.color-mediumturquoise {
  color: mediumturquoise !important;
}

.color-mediumvioletred {
  color: mediumvioletred !important;
}

.color-midnightblue {
  color: midnightblue !important;
}

.color-mintcream {
  color: mintcream !important;
}

.color-mistyrose {
  color: mistyrose !important;
}

.color-moccasin {
  color: moccasin !important;
}

.color-navajowhite {
  color: navajowhite !important;
}

.color-navy {
  color: navy !important;
}

.color-oldlace {
  color: oldlace !important;
}

.color-olive {
  color: olive !important;
}

.color-olivedrab {
  color: olivedrab !important;
}

.color-orange {
  color: orange !important;
}

.color-orangered {
  color: orangered !important;
}

.color-orchid {
  color: orchid !important;
}

.color-palegoldenrod {
  color: palegoldenrod !important;
}

.color-palegreen {
  color: palegreen !important;
}

.color-paleturquoise {
  color: paleturquoise !important;
}

.color-palevioletred {
  color: palevioletred !important;
}

.color-papayawhip {
  color: papayawhip !important;
}

.color-peachpuff {
  color: peachpuff !important;
}

.color-peru {
  color: peru !important;
}

.color-pink {
  color: pink !important;
}

.color-plum {
  color: plum !important;
}

.color-powderblue {
  color: powderblue !important;
}

.color-purple {
  color: purple !important;
}

.color-rebeccapurple {
  color: rebeccapurple !important;
}

.color-red {
  color: red !important;
}

.color-rosybrown {
  color: rosybrown !important;
}

.color-royalblue {
  color: royalblue !important;
}

.color-saddlebrown {
  color: saddlebrown !important;
}

.color-salmon {
  color: salmon !important;
}

.color-sandybrown {
  color: sandybrown !important;
}

.color-seagreen {
  color: seagreen !important;
}

.color-seashell {
  color: seashell !important;
}

.color-sienna {
  color: sienna !important;
}

.color-silver {
  color: silver !important;
}

.color-skyblue {
  color: skyblue !important;
}

.color-slateblue {
  color: slateblue !important;
}

.color-slategray {
  color: slategray !important;
}

.color-slategrey {
  color: slategrey !important;
}

.color-snow {
  color: snow !important;
}

.color-springgreen {
  color: springgreen !important;
}

.color-steelblue {
  color: steelblue !important;
}

.color-tan {
  color: tan !important;
}

.color-teal {
  color: teal !important;
}

.color-thistle {
  color: thistle !important;
}

.color-tomato {
  color: tomato !important;
}

.color-turquoise {
  color: turquoise !important;
}

.color-violet {
  color: violet !important;
}

.color-wheat {
  color: wheat !important;
}

.color-white {
  color: white !important;
}

.color-whitesmoke {
  color: whitesmoke !important;
}

.color-yellow {
  color: yellow !important;
}

.color-yellowgreen {
  color: yellowgreen !important;
}

.color-grey-cc {
  color: #cccccc !important;
}

.color-grey-6a {
  color: #6a6a6a !important;
}

/**
 * backgrounds
 */
.bg-aliceblue {
  background-color: aliceblue !important;
}

.bg-antiquewhite {
  background-color: antiquewhite !important;
}

.bg-aqua {
  background-color: aqua !important;
}

.bg-aquamarine {
  background-color: aquamarine !important;
}

.bg-azure {
  background-color: azure !important;
}

.bg-beige {
  background-color: beige !important;
}

.bg-bisque {
  background-color: bisque !important;
}

.bg-black {
  background-color: black !important;
}

.bg-blanchedalmond {
  background-color: blanchedalmond !important;
}

.bg-blue {
  background-color: blue !important;
}

.bg-blueviolet {
  background-color: blueviolet !important;
}

.bg-brown {
  background-color: brown !important;
}

.bg-burlywood {
  background-color: burlywood !important;
}

.bg-cadetblue {
  background-color: cadetblue !important;
}

.bg-chartreuse {
  background-color: chartreuse !important;
}

.bg-chocolate {
  background-color: chocolate !important;
}

.bg-coral {
  background-color: coral !important;
}

.bg-cornflowerblue {
  background-color: cornflowerblue !important;
}

.bg-cornsilk {
  background-color: cornsilk !important;
}

.bg-crimson {
  background-color: crimson !important;
}

.bg-cyan {
  background-color: cyan !important;
}

.bg-darkblue {
  background-color: darkblue !important;
}

.bg-darkcyan {
  background-color: darkcyan !important;
}

.bg-darkgoldenrod {
  background-color: darkgoldenrod !important;
}

.bg-darkgray {
  background-color: darkgray !important;
}

.bg-darkgrey {
  background-color: darkgrey !important;
}

.bg-darkgreen {
  background-color: darkgreen !important;
}

.bg-darkkhaki {
  background-color: darkkhaki !important;
}

.bg-darkmagenta {
  background-color: darkmagenta !important;
}

.bg-darkolivegreen {
  background-color: darkolivegreen !important;
}

.bg-darkorange {
  background-color: darkorange !important;
}

.bg-darkorchid {
  background-color: darkorchid !important;
}

.bg-darkred {
  background-color: darkred !important;
}

.bg-darksalmon {
  background-color: darksalmon !important;
}

.bg-darkseagreen {
  background-color: darkseagreen !important;
}

.bg-darkslateblue {
  background-color: darkslateblue !important;
}

.bg-darkslategray {
  background-color: darkslategray !important;
}

.bg-darkslategrey {
  background-color: darkslategrey !important;
}

.bg-darkturquoise {
  background-color: darkturquoise !important;
}

.bg-darkviolet {
  background-color: darkviolet !important;
}

.bg-deeppink {
  background-color: deeppink !important;
}

.bg-deepskyblue {
  background-color: deepskyblue !important;
}

.bg-dimgray {
  background-color: dimgray !important;
}

.bg-dimgrey {
  background-color: dimgrey !important;
}

.bg-dodgerblue {
  background-color: dodgerblue !important;
}

.bg-firebrick {
  background-color: firebrick !important;
}

.bg-floralwhite {
  background-color: floralwhite !important;
}

.bg-forestgreen {
  background-color: forestgreen !important;
}

.bg-fuchsia {
  background-color: fuchsia !important;
}

.bg-gainsboro {
  background-color: gainsboro !important;
}

.bg-ghostwhite {
  background-color: ghostwhite !important;
}

.bg-gold {
  background-color: gold !important;
}

.bg-goldenrod {
  background-color: goldenrod !important;
}

.bg-gray {
  background-color: gray !important;
}

.bg-grey {
  background-color: grey !important;
}

.bg-green {
  background-color: green !important;
}

.bg-greenyellow {
  background-color: greenyellow !important;
}

.bg-honeydew {
  background-color: honeydew !important;
}

.bg-hotpink {
  background-color: hotpink !important;
}

.bg-indianred {
  background-color: indianred  !important;
}

.bg-indigo {
  background-color: indigo  !important;
}

.bg-ivory {
  background-color: ivory !important;
}

.bg-khaki {
  background-color: khaki !important;
}

.bg-lavender {
  background-color: lavender !important;
}

.bg-lavenderblush {
  background-color: lavenderblush !important;
}

.bg-lawngreen {
  background-color: lawngreen !important;
}

.bg-lemonchiffon {
  background-color: lemonchiffon !important;
}

.bg-lightblue {
  background-color: lightblue !important;
}

.bg-lightcoral {
  background-color: lightcoral !important;
}

.bg-lightcyan {
  background-color: lightcyan !important;
}

.bg-lightgoldenrodyellow {
  background-color: lightgoldenrodyellow !important;
}

.bg-lightgray {
  background-color: lightgray !important;
}

.bg-lightgrey {
  background-color: lightgrey !important;
}

.bg-lightgreen {
  background-color: lightgreen !important;
}

.bg-lightpink {
  background-color: lightpink !important;
}

.bg-lightsalmon {
  background-color: lightsalmon !important;
}

.bg-lightseagreen {
  background-color: lightseagreen !important;
}

.bg-lightskyblue {
  background-color: lightskyblue !important;
}

.bg-lightslategray {
  background-color: lightslategray !important;
}

.bg-lightslategrey {
  background-color: lightslategrey !important;
}

.bg-lightsteelblue {
  background-color: lightsteelblue !important;
}

.bg-lightyellow {
  background-color: lightyellow !important;
}

.bg-lime {
  background-color: lime !important;
}

.bg-limegreen {
  background-color: limegreen !important;
}

.bg-linen {
  background-color: linen !important;
}

.bg-magenta {
  background-color: magenta !important;
}

.bg-maroon {
  background-color: maroon !important;
}

.bg-mediumaquamarine {
  background-color: mediumaquamarine !important;
}

.bg-mediumblue {
  background-color: mediumblue !important;
}

.bg-mediumorchid {
  background-color: mediumorchid !important;
}

.bg-mediumpurple {
  background-color: mediumpurple !important;
}

.bg-mediumseagreen {
  background-color: mediumseagreen !important;
}

.bg-mediumslateblue {
  background-color: mediumslateblue !important;
}

.bg-mediumspringgreen {
  background-color: mediumspringgreen !important;
}

.bg-mediumturquoise {
  background-color: mediumturquoise !important;
}

.bg-mediumvioletred {
  background-color: mediumvioletred !important;
}

.bg-midnightblue {
  background-color: midnightblue !important;
}

.bg-mintcream {
  background-color: mintcream !important;
}

.bg-mistyrose {
  background-color: mistyrose !important;
}

.bg-moccasin {
  background-color: moccasin !important;
}

.bg-navajowhite {
  background-color: navajowhite !important;
}

.bg-navy {
  background-color: navy !important;
}

.bg-oldlace {
  background-color: oldlace !important;
}

.bg-olive {
  background-color: olive !important;
}

.bg-olivedrab {
  background-color: olivedrab !important;
}

.bg-orange {
  background-color: orange !important;
}

.bg-orangered {
  background-color: orangered !important;
}

.bg-orchid {
  background-color: orchid !important;
}

.bg-palegoldenrod {
  background-color: palegoldenrod !important;
}

.bg-palegreen {
  background-color: palegreen !important;
}

.bg-paleturquoise {
  background-color: paleturquoise !important;
}

.bg-palevioletred {
  background-color: palevioletred !important;
}

.bg-papayawhip {
  background-color: papayawhip !important;
}

.bg-peachpuff {
  background-color: peachpuff !important;
}

.bg-peru {
  background-color: peru !important;
}

.bg-pink {
  background-color: pink !important;
}

.bg-plum {
  background-color: plum !important;
}

.bg-powderblue {
  background-color: powderblue !important;
}

.bg-purple {
  background-color: purple !important;
}

.bg-rebeccapurple {
  background-color: rebeccapurple !important;
}

.bg-red {
  background-color: red !important;
}

.bg-rosybrown {
  background-color: rosybrown !important;
}

.bg-royalblue {
  background-color: royalblue !important;
}

.bg-saddlebrown {
  background-color: saddlebrown !important;
}

.bg-salmon {
  background-color: salmon !important;
}

.bg-sandybrown {
  background-color: sandybrown !important;
}

.bg-seagreen {
  background-color: seagreen !important;
}

.bg-seashell {
  background-color: seashell !important;
}

.bg-sienna {
  background-color: sienna !important;
}

.bg-silver {
  background-color: silver !important;
}

.bg-skyblue {
  background-color: skyblue !important;
}

.bg-slateblue {
  background-color: slateblue !important;
}

.bg-slategray {
  background-color: slategray !important;
}

.bg-slategrey {
  background-color: slategrey !important;
}

.bg-snow {
  background-color: snow !important;
}

.bg-springgreen {
  background-color: springgreen !important;
}

.bg-steelblue {
  background-color: steelblue !important;
}

.bg-tan {
  background-color: tan !important;
}

.bg-teal {
  background-color: teal !important;
}

.bg-thistle {
  background-color: thistle !important;
}

.bg-tomato {
  background-color: tomato !important;
}

.bg-turquoise {
  background-color: turquoise !important;
}

.bg-violet {
  background-color: violet !important;
}

.bg-wheat {
  background-color: wheat !important;
}

.bg-white {
  background-color: white !important;
}

.bg-whitesmoke {
  background-color: whitesmoke !important;
}

.bg-yellow {
  background-color: yellow !important;
}

.bg-yellowgreen {
  background-color: yellowgreen !important;
}

.bg-none {
  background: none !important;
}

.bg-f0f3f8 {
  background-color: #f0f3f8 !important;
}
/*# sourceMappingURL=bootstrap.css.map */