
// ahora .w-100 viene en el propio core de bootstrap, estamos repitiendo pero bueno...

@each $prop, $abbrev in (width: w, height: h, top: t, left: l) {
    @for $i from 0 through 100 {
        .#{$abbrev}-#{$i} { #{$prop}: #{$i}% !important; }
    }
}

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-table-row  { display: table-row !important; }
    .fixed#{$infix}-top {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: $zindex-fixed;
    }
    .w#{$infix}-auto {
      width: auto!important;
    }
    .w#{$infix}-100 {
      width: 100%!important;
    }
    // reset de la propiedad flex-grow
    .flex#{$infix}-grow-0 {
      flex-grow: 0!important;
    }
    .flex#{$infix}-grow-1 {
      flex-grow: 1!important;
    }
    .order#{$infix}-initial {
      order: initial;
    }
    // ancho/alto/top/left responsive en pixeles
    @each $prop, $abbrev in (width: w, height: h, top: t, left: l) {
      @for $i from 0 through 100 {
        @each $unit in (px, rem) {
          .#{$abbrev}#{$infix}-#{$i}#{$unit} { #{$prop}: #{$i}#{$unit} !important; }
        }
      }
    }
    // responsive height auto
    .h#{$infix}-auto {
      height: auto!important;
    }
    // este bucle lo hago para cambiarle el padding superior al body, pero se puede usar donde se quiera
    @each $prop, $abbrev in (padding: p, margin: m) {
      @for $i from 0 through 100 {
        @if $i % 5 == 0 {
          .#{$abbrev}t#{$infix}-#{$i}px { #{$prop}-top: #{$i}px !important; }
          .#{$abbrev}b#{$infix}-#{$i}px { #{$prop}-bottom: #{$i}px !important; }
        }
      }
    }
    // borde superior responsive de la caja de busqueda de la cabecera
    .border-top#{$infix}-0 {
      border-top: 0 !important;
    }
    .border-top#{$infix}-1px-s-2f2f2f {
      border-top: 1px solid #2f2f2f !important;
    }
  }
}

@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      display: none !important;
    }
  }
  .hidden-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      display: none !important;
    }
  }
}


// Responsive small size utilities

@each $bp in map-keys($grid-breakpoints) {
  .small-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      font-size: $small-font-size;
      font-weight: normal;
    }
  }
  .small-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      font-size: $small-font-size;
      font-weight: normal;
    }
  }
  .px-0-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
  .px-0-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}

// Responsive container widths utilities

@each $bp in map-keys($grid-breakpoints) {
  .container-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      @include make-container();
      @include make-container-max-widths();
    }
  }
  .container-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      @include make-container();
      @include make-container-max-widths();
    }
  }
  .container-fluid-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      @include make-container();
    }
  }
  .container-fluid-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      @include make-container();
      width: auto;
    }
  }
}



// Responsive font size

$fs-base: 1rem !default;

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        // from 0 to 4rem step .25rem
        @for $i from 0 through 16 {
            $size: $fs-base * $i * .25;
            .fs#{$infix}-#{$i} {
                font-size: $size !important;
            }
        }
    }
}

// Type display classes
.display-5 {
    font-size: $display5-size;
    font-weight: $display5-weight;
    line-height: $display-line-height;
}
.display-6 {
    font-size: $display6-size;
    font-weight: $display6-weight;
    line-height: $display-line-height;
}
.display-7 {
    font-size: $display7-size;
    font-weight: $display7-weight;
    line-height: $display-line-height;
}
.display-8 {
    font-size: $display8-size;
    font-weight: $display8-weight;
    line-height: $display-line-height;
}
.display-9 {
    font-size: $display9-size;
    font-weight: $display9-weight;
    line-height: $display-line-height;
}
.display-10 {
    font-size: $display10-size;
    font-weight: $display10-weight;
    line-height: $display-line-height;
}
.display-11 {
    font-size: $display11-size;
    font-weight: $display11-weight;
    line-height: $display-line-height;
}


@function getDisplaySize($size) {
    @if ($size == 1) {
        @return $display1-size;
    } @else if ($size == 2) {
        @return $display2-size;
    } @else if ($size == 3) {
        @return $display3-size;
    } @else if ($size == 4) {
        @return $display4-size;
    } @else if ($size == 5) {
        @return $display5-size;
    } @else if ($size == 6) {
        @return $display6-size;
    } @else if ($size == 7) {
        @return $display7-size;
    } @else if ($size == 8) {
        @return $display8-size;
    } @else if ($size == 9) {
        @return $display9-size;
    } @else if ($size == 10) {
        @return $display10-size;
    } @else if ($size == 11) {
        @return $display11-size;
    }
}

@function getDisplayWeight($size) {
    @if ($size == 1) {
        @return $display1-weight;
    } @else if ($size == 2) {
        @return $display2-weight;
    } @else if ($size == 3) {
        @return $display3-weight;
    } @else if ($size == 4) {
        @return $display4-weight;
    } @else if ($size == 5) {
        @return $display5-weight;
    } @else if ($size == 6) {
        @return $display6-weight;
    } @else if ($size == 7) {
        @return $display7-weight;
    } @else if ($size == 8) {
        @return $display8-weight;
    } @else if ($size == 9) {
        @return $display9-weight;
    } @else if ($size == 10) {
        @return $display10-weight;
    } @else if ($size == 11) {
        @return $display11-weight;
    }
}

@each $bp in map-keys($grid-breakpoints) {
    @for $i from 1 through 11 {
        .display-#{$i}-#{$bp}-up {
            @include media-breakpoint-up($bp) {
                font-size: getDisplaySize($i);
                font-weight: getDisplayWeight($i);
                line-height: $display-line-height;
            }
        }
        .display-#{$i}-#{$bp}-down {
            @include media-breakpoint-down($bp) {
                font-size: getDisplaySize($i);
                font-weight: getDisplayWeight($i);
                line-height: $display-line-height;
            }
        }
    }
}

// Block button
// permite convertir un boton en block en funcion del breakpoint

@each $bp in map-keys($grid-breakpoints) {
    .btn-block-#{$bp}-up {
        @include media-breakpoint-up($bp) {
            display: block;
            width: 100%;
        }
    }
    // Vertically space out multiple block buttons
    .btn-block-#{$bp}-up + .btn-block-#{$bp}-up {
        @include media-breakpoint-up($bp) {
            margin-top: $btn-block-spacing-y;
        }
    }
    .btn-block-#{$bp}-up + .btn-block-#{$bp}-down {
        @include media-breakpoint-up($bp) {
            margin-top: $btn-block-spacing-y;
        }
    }
    .btn-block-#{$bp}-down {
        @include media-breakpoint-down($bp) {
            display: block;
            width: 100%;
        }
    }
    // Vertically space out multiple block buttons
    .btn-block-#{$bp}-down + .btn-block-#{$bp}-up {
        @include media-breakpoint-down($bp) {
            margin-top: $btn-block-spacing-y;
        }
    }
    .btn-block-#{$bp}-down + .btn-block-#{$bp}-down {
        @include media-breakpoint-down($bp) {
            margin-top: $btn-block-spacing-y;
        }
    }
}

// line height small

.line-height-1 {
  line-height: $line-height-1;
}
.line-height-small {
  line-height: $line-height-small;
}

@each $bp in map-keys($grid-breakpoints) {
    .line-height-small-#{$bp}-up {
        @include media-breakpoint-up($bp) {
            line-height: $line-height-small;
        }
    }
    .line-height-1-#{$bp}-up {
        @include media-breakpoint-up($bp) {
            line-height: $line-height-1;
        }
    }
    .line-height-small-#{$bp}-down {
        @include media-breakpoint-down($bp) {
            line-height: $line-height-small;
        }
    }
    .line-height-1-#{$bp}-down {
        @include media-breakpoint-down($bp) {
            line-height: $line-height-1;
        }
    }
}

// Responsive visibility utilities

@each $bp in map-keys($grid-breakpoints) {
    .hidden-#{$bp} {
        @include media-breakpoint-only($bp) {
            display: none !important;
        }
    }
}

// Responsive positioning

//@for $i from 0 through 100 {
//    .t-#{$i} {
//       top: #{$i}% !important;
//    }
//    .l-#{$i} {
//        left: #{$i}% !important;
//    }
//}

// https://stackoverflow.com/questions/41377501/how-to-vertically-align-bootstrap-v4-modal-dialogs
// centrando verticalmente el cuadro de dialogo modal
.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    @media(max-width: 768px) {
        min-height: calc(100vh - 20px);
    }
}

.mw-100 {
    max-width: 100%;
}

// 25/50/75/100% responsive width

//$size-auto: (
//  auto: auto
//) !default;

//$sizes-with-auto: join($sizes, $size-auto); 

@each $bp in map-keys($grid-breakpoints) {
//  .w-auto-#{$bp}-up {
//    @include media-breakpoint-up($bp) {
//      width: auto !important;
//    }
//  }
//  .w-auto-#{$bp}-down {
//    @include media-breakpoint-down($bp) {
//      width: auto !important;
//    }
//  }
  @each $size, $length in $sizes {
    .w-#{$size}-#{$bp}-up {
      @include media-breakpoint-up($bp) {
        width: #{$length};
      }
    }
    .w-#{$size}-#{$bp}-down {
      @include media-breakpoint-down($bp) {
        width: #{$length};
      }
    }
  }
}
