
.color-aliceblue {color: aliceblue !important;}
.color-antiquewhite {color: antiquewhite !important;}
.color-aqua {color: aqua !important;}
.color-aquamarine {color: aquamarine !important;}
.color-azure {color: azure !important;}
.color-beige {color: beige !important;}
.color-bisque {color: bisque !important;}
.color-black {color: black !important;}
.color-blanchedalmond {color: blanchedalmond !important;}
.color-blue {color: blue !important;}
.color-blueviolet {color: blueviolet !important;}
.color-brown {color: brown !important;}
.color-burlywood {color: burlywood !important;}
.color-cadetblue {color: cadetblue !important;}
.color-chartreuse {color: chartreuse !important;}
.color-chocolate {color: chocolate !important;}
.color-coral {color: coral !important;}
.color-cornflowerblue {color: cornflowerblue !important;}
.color-cornsilk {color: cornsilk !important;}
.color-crimson {color: crimson !important;}
.color-cyan {color: cyan !important;}
.color-darkblue {color: darkblue !important;}
.color-darkcyan {color: darkcyan !important;}
.color-darkgoldenrod {color: darkgoldenrod !important;}
.color-darkgray {color: darkgray !important;}
.color-darkgrey {color: darkgrey !important;}
.color-darkgreen {color: darkgreen !important;}
.color-darkkhaki {color: darkkhaki !important;}
.color-darkmagenta {color: darkmagenta !important;}
.color-darkolivegreen {color: darkolivegreen !important;}
.color-darkorange {color: darkorange !important;}
.color-darkorchid {color: darkorchid !important;}
.color-darkred {color: darkred !important;}
.color-darksalmon {color: darksalmon !important;}
.color-darkseagreen {color: darkseagreen !important;}
.color-darkslateblue {color: darkslateblue !important;}
.color-darkslategray {color: darkslategray !important;}
.color-darkslategrey {color: darkslategrey !important;}
.color-darkturquoise {color: darkturquoise !important;}
.color-darkviolet {color: darkviolet !important;}
.color-deeppink {color: deeppink !important;}
.color-deepskyblue {color: deepskyblue !important;}
.color-dimgray {color: dimgray !important;}
.color-dimgrey {color: dimgrey !important;}
.color-dodgerblue {color: dodgerblue !important;}
.color-firebrick {color: firebrick !important;}
.color-floralwhite {color: floralwhite !important;}
.color-forestgreen {color: forestgreen !important;}
.color-fuchsia {color: fuchsia !important;}
.color-gainsboro {color: gainsboro !important;}
.color-ghostwhite {color: ghostwhite !important;}
.color-gold {color: gold !important;}
.color-goldenrod {color: goldenrod !important;}
.color-gray {color: gray !important;}
.color-grey {color: grey !important;}
.color-green {color: green !important;}
.color-greenyellow {color: greenyellow !important;}
.color-honeydew {color: honeydew !important;}
.color-hotpink {color: hotpink !important;}
.color-indianred  {color: indianred  !important;}
.color-indigo  {color: indigo  !important;}
.color-ivory {color: ivory !important;}
.color-khaki {color: khaki !important;}
.color-lavender {color: lavender !important;}
.color-lavenderblush {color: lavenderblush !important;}
.color-lawngreen {color: lawngreen !important;}
.color-lemonchiffon {color: lemonchiffon !important;}
.color-lightblue {color: lightblue !important;}
.color-lightcoral {color: lightcoral !important;}
.color-lightcyan {color: lightcyan !important;}
.color-lightgoldenrodyellow {color: lightgoldenrodyellow !important;}
.color-lightgray {color: lightgray !important;}
.color-lightgrey {color: lightgrey !important;}
.color-lightgreen {color: lightgreen !important;}
.color-lightpink {color: lightpink !important;}
.color-lightsalmon {color: lightsalmon !important;}
.color-lightseagreen {color: lightseagreen !important;}
.color-lightskyblue {color: lightskyblue !important;}
.color-lightslategray {color: lightslategray !important;}
.color-lightslategrey {color: lightslategrey !important;}
.color-lightsteelblue {color: lightsteelblue !important;}
.color-lightyellow {color: lightyellow !important;}
.color-lime {color: lime !important;}
.color-limegreen {color: limegreen !important;}
.color-linen {color: linen !important;}
.color-magenta {color: magenta !important;}
.color-maroon {color: maroon !important;}
.color-mediumaquamarine {color: mediumaquamarine !important;}
.color-mediumblue {color: mediumblue !important;}
.color-mediumorchid {color: mediumorchid !important;}
.color-mediumpurple {color: mediumpurple !important;}
.color-mediumseagreen {color: mediumseagreen !important;}
.color-mediumslateblue {color: mediumslateblue !important;}
.color-mediumspringgreen {color: mediumspringgreen !important;}
.color-mediumturquoise {color: mediumturquoise !important;}
.color-mediumvioletred {color: mediumvioletred !important;}
.color-midnightblue {color: midnightblue !important;}
.color-mintcream {color: mintcream !important;}
.color-mistyrose {color: mistyrose !important;}
.color-moccasin {color: moccasin !important;}
.color-navajowhite {color: navajowhite !important;}
.color-navy {color: navy !important;}
.color-oldlace {color: oldlace !important;}
.color-olive {color: olive !important;}
.color-olivedrab {color: olivedrab !important;}
.color-orange {color: orange !important;}
.color-orangered {color: orangered !important;}
.color-orchid {color: orchid !important;}
.color-palegoldenrod {color: palegoldenrod !important;}
.color-palegreen {color: palegreen !important;}
.color-paleturquoise {color: paleturquoise !important;}
.color-palevioletred {color: palevioletred !important;}
.color-papayawhip {color: papayawhip !important;}
.color-peachpuff {color: peachpuff !important;}
.color-peru {color: peru !important;}
.color-pink {color: pink !important;}
.color-plum {color: plum !important;}
.color-powderblue {color: powderblue !important;}
.color-purple {color: purple !important;}
.color-rebeccapurple {color: rebeccapurple !important;}
.color-red {color: red !important;}
.color-rosybrown {color: rosybrown !important;}
.color-royalblue {color: royalblue !important;}
.color-saddlebrown {color: saddlebrown !important;}
.color-salmon {color: salmon !important;}
.color-sandybrown {color: sandybrown !important;}
.color-seagreen {color: seagreen !important;}
.color-seashell {color: seashell !important;}
.color-sienna {color: sienna !important;}
.color-silver {color: silver !important;}
.color-skyblue {color: skyblue !important;}
.color-slateblue {color: slateblue !important;}
.color-slategray {color: slategray !important;}
.color-slategrey {color: slategrey !important;}
.color-snow {color: snow !important;}
.color-springgreen {color: springgreen !important;}
.color-steelblue {color: steelblue !important;}
.color-tan {color: tan !important;}
.color-teal {color: teal !important;}
.color-thistle {color: thistle !important;}
.color-tomato {color: tomato !important;}
.color-turquoise {color: turquoise !important;}
.color-violet {color: violet !important;}
.color-wheat {color: wheat !important;}
.color-white {color: white !important;}
.color-whitesmoke {color: whitesmoke !important;}
.color-yellow {color: yellow !important;}
.color-yellowgreen {color: yellowgreen !important;}

.color-grey-cc {
    color: #cccccc !important;
}
.color-grey-6a {
    color: #6a6a6a !important;
}


/**
 * backgrounds
 */
.bg-aliceblue {background-color: aliceblue !important;}
.bg-antiquewhite {background-color: antiquewhite !important;}
.bg-aqua {background-color: aqua !important;}
.bg-aquamarine {background-color: aquamarine !important;}
.bg-azure {background-color: azure !important;}
.bg-beige {background-color: beige !important;}
.bg-bisque {background-color: bisque !important;}
.bg-black {background-color: black !important;}
.bg-blanchedalmond {background-color: blanchedalmond !important;}
.bg-blue {background-color: blue !important;}
.bg-blueviolet {background-color: blueviolet !important;}
.bg-brown {background-color: brown !important;}
.bg-burlywood {background-color: burlywood !important;}
.bg-cadetblue {background-color: cadetblue !important;}
.bg-chartreuse {background-color: chartreuse !important;}
.bg-chocolate {background-color: chocolate !important;}
.bg-coral {background-color: coral !important;}
.bg-cornflowerblue {background-color: cornflowerblue !important;}
.bg-cornsilk {background-color: cornsilk !important;}
.bg-crimson {background-color: crimson !important;}
.bg-cyan {background-color: cyan !important;}
.bg-darkblue {background-color: darkblue !important;}
.bg-darkcyan {background-color: darkcyan !important;}
.bg-darkgoldenrod {background-color: darkgoldenrod !important;}
.bg-darkgray {background-color: darkgray !important;}
.bg-darkgrey {background-color: darkgrey !important;}
.bg-darkgreen {background-color: darkgreen !important;}
.bg-darkkhaki {background-color: darkkhaki !important;}
.bg-darkmagenta {background-color: darkmagenta !important;}
.bg-darkolivegreen {background-color: darkolivegreen !important;}
.bg-darkorange {background-color: darkorange !important;}
.bg-darkorchid {background-color: darkorchid !important;}
.bg-darkred {background-color: darkred !important;}
.bg-darksalmon {background-color: darksalmon !important;}
.bg-darkseagreen {background-color: darkseagreen !important;}
.bg-darkslateblue {background-color: darkslateblue !important;}
.bg-darkslategray {background-color: darkslategray !important;}
.bg-darkslategrey {background-color: darkslategrey !important;}
.bg-darkturquoise {background-color: darkturquoise !important;}
.bg-darkviolet {background-color: darkviolet !important;}
.bg-deeppink {background-color: deeppink !important;}
.bg-deepskyblue {background-color: deepskyblue !important;}
.bg-dimgray {background-color: dimgray !important;}
.bg-dimgrey {background-color: dimgrey !important;}
.bg-dodgerblue {background-color: dodgerblue !important;}
.bg-firebrick {background-color: firebrick !important;}
.bg-floralwhite {background-color: floralwhite !important;}
.bg-forestgreen {background-color: forestgreen !important;}
.bg-fuchsia {background-color: fuchsia !important;}
.bg-gainsboro {background-color: gainsboro !important;}
.bg-ghostwhite {background-color: ghostwhite !important;}
.bg-gold {background-color: gold !important;}
.bg-goldenrod {background-color: goldenrod !important;}
.bg-gray {background-color: gray !important;}
.bg-grey {background-color: grey !important;}
.bg-green {background-color: green !important;}
.bg-greenyellow {background-color: greenyellow !important;}
.bg-honeydew {background-color: honeydew !important;}
.bg-hotpink {background-color: hotpink !important;}
.bg-indianred  {background-color: indianred  !important;}
.bg-indigo  {background-color: indigo  !important;}
.bg-ivory {background-color: ivory !important;}
.bg-khaki {background-color: khaki !important;}
.bg-lavender {background-color: lavender !important;}
.bg-lavenderblush {background-color: lavenderblush !important;}
.bg-lawngreen {background-color: lawngreen !important;}
.bg-lemonchiffon {background-color: lemonchiffon !important;}
.bg-lightblue {background-color: lightblue !important;}
.bg-lightcoral {background-color: lightcoral !important;}
.bg-lightcyan {background-color: lightcyan !important;}
.bg-lightgoldenrodyellow {background-color: lightgoldenrodyellow !important;}
.bg-lightgray {background-color: lightgray !important;}
.bg-lightgrey {background-color: lightgrey !important;}
.bg-lightgreen {background-color: lightgreen !important;}
.bg-lightpink {background-color: lightpink !important;}
.bg-lightsalmon {background-color: lightsalmon !important;}
.bg-lightseagreen {background-color: lightseagreen !important;}
.bg-lightskyblue {background-color: lightskyblue !important;}
.bg-lightslategray {background-color: lightslategray !important;}
.bg-lightslategrey {background-color: lightslategrey !important;}
.bg-lightsteelblue {background-color: lightsteelblue !important;}
.bg-lightyellow {background-color: lightyellow !important;}
.bg-lime {background-color: lime !important;}
.bg-limegreen {background-color: limegreen !important;}
.bg-linen {background-color: linen !important;}
.bg-magenta {background-color: magenta !important;}
.bg-maroon {background-color: maroon !important;}
.bg-mediumaquamarine {background-color: mediumaquamarine !important;}
.bg-mediumblue {background-color: mediumblue !important;}
.bg-mediumorchid {background-color: mediumorchid !important;}
.bg-mediumpurple {background-color: mediumpurple !important;}
.bg-mediumseagreen {background-color: mediumseagreen !important;}
.bg-mediumslateblue {background-color: mediumslateblue !important;}
.bg-mediumspringgreen {background-color: mediumspringgreen !important;}
.bg-mediumturquoise {background-color: mediumturquoise !important;}
.bg-mediumvioletred {background-color: mediumvioletred !important;}
.bg-midnightblue {background-color: midnightblue !important;}
.bg-mintcream {background-color: mintcream !important;}
.bg-mistyrose {background-color: mistyrose !important;}
.bg-moccasin {background-color: moccasin !important;}
.bg-navajowhite {background-color: navajowhite !important;}
.bg-navy {background-color: navy !important;}
.bg-oldlace {background-color: oldlace !important;}
.bg-olive {background-color: olive !important;}
.bg-olivedrab {background-color: olivedrab !important;}
.bg-orange {background-color: orange !important;}
.bg-orangered {background-color: orangered !important;}
.bg-orchid {background-color: orchid !important;}
.bg-palegoldenrod {background-color: palegoldenrod !important;}
.bg-palegreen {background-color: palegreen !important;}
.bg-paleturquoise {background-color: paleturquoise !important;}
.bg-palevioletred {background-color: palevioletred !important;}
.bg-papayawhip {background-color: papayawhip !important;}
.bg-peachpuff {background-color: peachpuff !important;}
.bg-peru {background-color: peru !important;}
.bg-pink {background-color: pink !important;}
.bg-plum {background-color: plum !important;}
.bg-powderblue {background-color: powderblue !important;}
.bg-purple {background-color: purple !important;}
.bg-rebeccapurple {background-color: rebeccapurple !important;}
.bg-red {background-color: red !important;}
.bg-rosybrown {background-color: rosybrown !important;}
.bg-royalblue {background-color: royalblue !important;}
.bg-saddlebrown {background-color: saddlebrown !important;}
.bg-salmon {background-color: salmon !important;}
.bg-sandybrown {background-color: sandybrown !important;}
.bg-seagreen {background-color: seagreen !important;}
.bg-seashell {background-color: seashell !important;}
.bg-sienna {background-color: sienna !important;}
.bg-silver {background-color: silver !important;}
.bg-skyblue {background-color: skyblue !important;}
.bg-slateblue {background-color: slateblue !important;}
.bg-slategray {background-color: slategray !important;}
.bg-slategrey {background-color: slategrey !important;}
.bg-snow {background-color: snow !important;}
.bg-springgreen {background-color: springgreen !important;}
.bg-steelblue {background-color: steelblue !important;}
.bg-tan {background-color: tan !important;}
.bg-teal {background-color: teal !important;}
.bg-thistle {background-color: thistle !important;}
.bg-tomato {background-color: tomato !important;}
.bg-turquoise {background-color: turquoise !important;}
.bg-violet {background-color: violet !important;}
.bg-wheat {background-color: wheat !important;}
.bg-white {background-color: white !important;}
.bg-whitesmoke {background-color: whitesmoke !important;}
.bg-yellow {background-color: yellow !important;}
.bg-yellowgreen {background-color: yellowgreen !important;}

.bg-none {
    background: none !important;
}
.bg-f0f3f8 {
    background-color: #f0f3f8 !important;
}

